import { differenceInDays } from 'date-fns';
import {
  START_DATA_LOADING,
  SET_METRIX_BY_DT,
  SET_CHART_INTERVAL,
  SET_PRIMARY_METRIC,
  SET_SECONDARY_METRIC,
  SET_DATE_RANGE
} from '../actions';
import { SET_SALES, SET_TOTALS, SET_DASHBOARD_ORDER_EVENT_TYPE } from '../saga/dashboard';

const initialState = {
  sales: {},
  metricsByDt: [],
  secondaryMetricsData: null,
  salesLoading: false,
  totals: {},
  totalsLoading: false,
  interval: 'Hourly',
  primaryMetric: 'sales',
  secondaryMetric: null,
  disabledChartInterval: null,
  chartLoading: false,
  orderEventType: ''
};

export const dashboardSelector = state => state.dashboard;

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTALS: {
      const { totals } = action;
      return {
        ...state,
        totals,
        totalsLoading: false
      };
    }
    case SET_SALES: {
      const { sales } = action;
      return {
        ...state,
        sales,
        salesLoading: false
      };
    }
    case SET_METRIX_BY_DT: {
      const { metricsByDt, isPrimary } = action;
      const metricsKey = isPrimary ? 'metricsByDt' : 'secondaryMetricsData';
      const newState = {
        ...state,
        chartLoading: false
      };
      newState[metricsKey] = metricsByDt;
      return newState;
    }
    case START_DATA_LOADING: {
      return {
        ...state,
        sales: {},
        totalsLoading: true,
        salesLoading: true,
        chartLoading: true
      };
    }
    case SET_CHART_INTERVAL: {
      const { interval } = action;

      return {
        ...state,
        chartLoading: true,
        interval
      };
    }
    case SET_PRIMARY_METRIC: {
      const { metric } = action;
      return {
        ...state,
        chartLoading: true,
        primaryMetric: metric
      };
    }
    case SET_SECONDARY_METRIC: {
      const { metric } = action;
      return {
        ...state,
        chartLoading: true,
        secondaryMetric: metric
      };
    }
    case SET_DATE_RANGE: {
      const { dateRange } = action;
      const rangeLength = differenceInDays(dateRange.to, dateRange.from);
      let { interval } = state;
      const isLongRange = rangeLength >= 6;
      if (state.interval === 'Hourly' && isLongRange) interval = 'Daily';
      if (!rangeLength) interval = 'Hourly';
      return { ...state, disabledChartInterval: isLongRange ? 'Hourly' : null, interval };
    }
    case SET_DASHBOARD_ORDER_EVENT_TYPE: {
      return { ...state, orderEventType: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default dashboard;
