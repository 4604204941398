import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconsMenu from '@components/common/IconsMenu';
import CaretDownIcon from './ic_caret_down.svg';

class AppMenu extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuChanged = title => {
    const { history, onSelect, menuItems } = this.props;
    const result = menuItems.find(item => item.title === title);
    if (result && result.link) {
      history.push(result.link);
      onSelect(title);
    } else if (result && result.onClick) {
      result.onClick(history);
      onSelect(title);
    }
  };

  getMenuItem = () => {
    const { history, menuItems } = this.props;
    const path = history.location.pathname;
    const result = menuItems.find(item => {
      return item.link === path || item.link === `${path}/` || path.includes(item.link);
    });

    return result && menuItems.find(item => item.id === result.id);
  };

  getButtonContent = menuItem => {
    return menuItem ? (
      <>
        {<menuItem.img fill="#ffffff" className="xs:mr-2 text-yellow" />}
        <p className="hidden xs:inline-block">{menuItem.title}</p>
      </>
    ) : (
      <p className="pl-2 pr-2 md:pr-8 text-base">Go to...</p>
    );
  };

  render() {
    const { menuItems } = this.props;
    const { anchorEl } = this.state;
    const menuItem = this.getMenuItem();
    const disabled = !menuItems.length || (menuItem && menuItems.length === 1);

    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className="px-0"
          classes={{ root: 'min-w-0 p-0 rounded-2xl' }}
          disabled={disabled}
        >
          <div className="app-menu rounded-2xl flex flex-row items-center justify-center text-white pl-2 pr-2 normal-case">
            <div className="flex flex-row items-center font-normal text-base">
              {this.getButtonContent(menuItem)}
              {disabled ? null : (
                <img
                  src={CaretDownIcon}
                  alt="caret-down"
                  width="6px"
                  height="4px"
                  className="ml-2"
                />
              )}
            </div>
          </div>
        </Button>
        <IconsMenu
          menuList={menuItems}
          className="mt-2"
          onClose={this.handleClose}
          selected={menuItem && menuItem.title}
          anchorEl={anchorEl}
          onOptionClick={this.handleMenuChanged}
          open={Boolean(anchorEl)}
        />
      </div>
    );
  }
}

AppMenu.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuItems: PropTypes.instanceOf(Array).isRequired
};

export default withRouter(AppMenu);
