import API from '@sm/api';
import mock from '../mock';

const messageTemplatesDB = [
  {
    id: 3,
    request_review: false,
    include_order: ['1', '2', '3', '4', '5'],
    exclude_order: [],
    include_countries: '',
    exclude_countries: 'US',
    schedule_order_status: 'Delivered',
    schedule_event: ['5'],
    schedule_after: 5,
    schedule_weekday: ['2'],
    schedule_time: '14:33',
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable',
    previous_email_opened: true,
    active: true,
    messages_sent: 20,
    emails_opened: 10,
    orders: 10,
    messages_pending: 5
  },
  {
    id: 5,
    request_review: true,
    include_order: ['1', '2'],
    exclude_order: ['3'],
    include_countries: 'RU US RU US RU US RU RU US RU US',
    exclude_countries: 'UK',
    schedule_order_status: 'Refunded',
    schedule_event: ['2'],
    schedule_after: 3,
    schedule_weekday: [],
    schedule_time: '11:33',
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable. Complimentary',
    previous_email_opened: false,
    active: false,
    messages_sent: 25,
    emails_opened: 10,
    orders: 10,
    messages_pending: 7
  },
  {
    id: 6,
    request_review: true,
    include_order: ['1', '2'],
    exclude_order: ['1', '2', '3'],
    include_countries: 'RU US',
    exclude_countries: 'UK',
    schedule_order_status: 'Refunded',
    schedule_event: ['2'],
    schedule_after: 0,
    schedule_time: '15:33',
    schedule_weekday: [],
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable. Complimentary',
    previous_email_opened: false,
    active: true,
    messages_sent: 20,
    emails_opened: 10,
    orders: 10,
    messages_pending: 5
  },
  {
    id: 8,
    request_review: true,
    include_order: ['1', '2'],
    exclude_order: ['1', '2', '3'],
    include_countries: 'RU US RU US RU US RU RU US RU US',
    exclude_countries: 'UK',
    schedule_order_status: 'Refunded',
    schedule_event: ['2'],
    schedule_after: 0,
    schedule_time: '15:33',
    schedule_weekday: [],
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable. Complimentary',
    previous_email_opened: false,
    active: true,
    messages_sent: 20,
    emails_opened: 10,
    orders: 10,
    messages_pending: 5
  }
];

const campaignsDB = [
  {
    id: 124,
    name: 'FBA B001QOE5G4',
    active: true,
    target_scope: 'asins',
    target_values: 'B001QOE5G4',
    emails_sent: 11,
    request_reviews_sent: 22,
    reviews: 10000,
    feedback: 20,
    reviews_stars: 4.572,
    feedback_stars: 5,
    products: [
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg'
    ],
    messagetemplates: [
      {
        id: 133,
        request_review: false,
        name: 'Stand Email Ref',
        active: true,
        schedule_event: 5,
        schedule_after: 7,
        orders: 0,
        emails_sent: 11,
        request_reviews_sent: 22
      },
      {
        id: 132,
        request_review: true,
        name: 'Req REV',
        active: true,
        schedule_event: 3,
        schedule_after: 7,
        orders: 0,
        emails_sent: 111,
        request_reviews_sent: 222
      }
    ],
    marketplace: 4,
    orders: 0,
    messages_sent: 0,
    products_count: 0,
    deleted: false
  },
  {
    id: 131,
    name: 'ALL Products',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    reviews: 15,
    feedback: 23,
    feedback_stars: 4.9812,
    products: [
      'https://m.media-amazon.com/images/I/41D0Zxin1RL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/310PjZ8dLUL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31ky6ynRzVL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/31ky6ynRzVL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41FsmXSui0L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41UN1VxEq8L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41FsmXSui0L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41UN1VxEq8L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41UN1VxEq8L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41FsmXSui0L._SL75_.jpg'
    ],
    messagetemplates: [
      {
        id: 135,
        request_review: false,
        name: 'standard email99',
        active: true,
        schedule_event: 2,
        schedule_after: 7,
        orders: 0
      }
    ],
    marketplace: 4,
    orders: 0,
    messages_sent: 0,
    products_count: 147699,
    deleted: false
  },
  {
    id: 2,
    name: 'Protein Shaker Bottle 20 oz. Vortex. Compliment Activene Gel Campaign',
    active: true,
    marketplace: 1,
    targetScope: 'sku_contains',
    targetSkus: 'UF-KPKM-TMSZ',
    messages_sent: 6,
    emails_opened: 2,
    orders: 10,
    messages_pending: 7,
    products: [
      'https://m.media-amazon.com/images/I/51ZIQ0LxfjL._AC_UL480_FMwebp_QL65_.jpg',
      'https://m.media-amazon.com/images/I/51bxNNnA1TL._AC_UL480_FMwebp_QL65_.jpg',
      'https://m.media-amazon.com/images/I/51ZIQ0LxfjL._AC_UL480_FMwebp_QL65_.jpg',
      'https://m.media-amazon.com/images/I/51bxNNnA1TL._AC_UL480_FMwebp_QL65_.jpg'
    ],
    messagetemplates: [
      {
        name: "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with",
        request_review: true,
        active: true,
        id: 1,
        marketplace: 1,
        messages_sent: 18,
        emails_opened: 12,
        orders: 10,
        messages_pending: 12,
        products: [
          'https://m.media-amazon.com/images/I/51ZIQ0LxfjL._AC_UL480_FMwebp_QL65_.jpg',
          'https://m.media-amazon.com/images/I/51bxNNnA1TL._AC_UL480_FMwebp_QL65_.jpg',
          'https://m.media-amazon.com/images/I/51ZIQ0LxfjL._AC_UL480_FMwebp_QL65_.jpg',
          'https://m.media-amazon.com/images/I/51bxNNnA1TL._AC_UL480_FMwebp_QL65_.jpg'
        ]
      }
    ]
  },
  {
    id: 3,
    name: 'Protein Shaker Bottle 20 oz. Vortex. Compliment Activene Gel Campaign',
    active: true,
    marketplace: 2,
    targetScope: 'sku_contains',
    targetSkus: 'UF-KPKM-TMSZ',
    messages_sent: 6,
    emails_opened: 2,
    orders: 10,
    messages_pending: 7,
    products: [
      'https://m.media-amazon.com/images/I/61MO2g-ViLL._AC_UL480_FMwebp_QL65_.jpg',
      'https://m.media-amazon.com/images/I/51HxSeSnMxL._AC_UL480_FMwebp_QL65_.jpg',
      'https://m.media-amazon.com/images/I/51HxSeSnMxL._AC_UL480_FMwebp_QL65_.jpg'
    ],
    messagetemplates: [
      {
        name: "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with",
        request_review: false,
        active: true,
        marketplace: 2,
        messages_sent: 18,
        emails_opened: 12,
        orders: 10,
        messages_pending: 12,
        products: [
          'https://m.media-amazon.com/images/I/61MO2g-ViLL._AC_UL480_FMwebp_QL65_.jpg',
          'https://m.media-amazon.com/images/I/51HxSeSnMxL._AC_UL480_FMwebp_QL65_.jpg'
        ]
      },
      {
        name: "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with",
        request_review: true,
        active: false,
        marketplace: 2,
        messages_sent: 22,
        emails_opened: 8,
        orders: 10,
        messages_pending: 6,
        products: ['https://m.media-amazon.com/images/I/61MO2g-ViLL._AC_UL480_FMwebp_QL65_.jpg']
      }
    ]
  },
  {
    id: 4,
    name: 'Activene Gel Campaign',
    active: true,
    marketplace: 3,
    targetScope: 'sku_contains',
    targetSkus: 'UF-KPKM-TMSZ',
    messages_sent: 6,
    emails_opened: 2,
    orders: 10,
    messages_pending: 7,
    products: [
      'https://m.media-amazon.com/images/I/61MO2g-ViLL._AC_UL480_FMwebp_QL65_.jpg',
      'https://m.media-amazon.com/images/I/51HxSeSnMxL._AC_UL480_FMwebp_QL65_.jpg'
    ],
    messagetemplates: [
      {
        name: "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with",
        request_review: false,
        active: true,
        marketplace: 3,
        messages_sent: 18,
        emails_opened: 12,
        orders: 10,
        messages_pending: 12,
        products: ['https://m.media-amazon.com/images/I/61MO2g-ViLL._AC_UL480_FMwebp_QL65_.jpg']
      },
      {
        name: "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with",
        request_review: true,
        active: false,
        marketplace: 3,
        messages_sent: 22,
        emails_opened: 8,
        orders: 10,
        messages_pending: 6,
        products: ['https://m.media-amazon.com/images/I/61MO2g-ViLL._AC_UL480_FMwebp_QL65_.jpg']
      }
    ]
  },
  {
    id: 5,
    name: "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with",
    active: false,
    marketplace: 4,
    targetScope: 'sku_contains',
    targetSkus: 'UF-KPKM-TMSZ',
    messages_sent: 18,
    emails_opened: 12,
    orders: 10,
    messages_pending: 12,
    products: ['https://m.media-amazon.com/images/I/61MO2g-ViLL._AC_UL480_FMwebp_QL65_.jpg'],
    messagetemplates: []
  }
];

const messagesDB = [
  {
    id: 57,
    campaign: 2,
    sent: {
      schedule_event: 3,
      schedule_after: 9
    },
    message: 1,
    request_review: true,
    product_image: 'https://m.media-amazon.com/images/I/31c7clMQvGL._SL75_.jpg',
    sku: '495063_prime',
    asin: 'B07QBJ99ZR',
    product_name: 'MUSTANG Damen Slim Fit Caro Jeans, 4000-310 Schwarz, Gr.- 27W / 30L',
    amazon_order_id: '306-4341082-2937960',
    buyer: 'm0p7r2znhl8jfl8@marketplace.amazon.de'
  },
  {
    campaign: 108,
    sent: {
      schedule_event: 3,
      schedule_after: 0
    },
    message: 120,
    request_review: false,
    amazon_order_id: '302-6928995-9081900',
    buyer: 'l56k2q0kwt27v3c@marketplace.amazon.de',
    children: [
      {
        product_image: 'https://m.media-amazon.com/images/I/3152EKpaSfL._SL75_.jpg',
        sku: '497222_prime',
        asin: 'B07M59PWRY',
        product_name:
          'MUSTANG Damen Jeans Rebecca Comfort Fit Rot Rosa Grau W27 - W34 Stretchjeans Hose 78% Baumwolle, Größe:W 32 L 30, Farbvariante:Misty Rose (8089)'
      },
      {
        product_image: 'https://m.media-amazon.com/images/I/311MCshSuuL._SL75_.jpg',
        sku: '499427_prime',
        asin: 'B07JGHV7D3',
        product_name:
          'MUSTANG Damen Rebecca Slim Jeans, Grau (Flint Gray 4043), W32/L30 (Herstellergröße: 32/30)'
      },
      {
        product_image: 'https://m.media-amazon.com/images/I/31psKJ+IiZL._SL75_.jpg',
        sku: '494735_prime',
        asin: 'B07M5BP7MR',
        product_name:
          'MUSTANG Damen Jeans Rebecca Comfort Fit Rot Rosa Grau W27 - W34 Stretchjeans Hose 78% Baumwolle, Größe:W 32 L 30, Farbvariante:Tawny Port (7199)'
      }
    ]
  },
  {
    campaign: 118,
    sent: {
      schedule_event: 2,
      schedule_after: 27
    },
    message: 127,
    request_review: false,
    product_image: 'https://m.media-amazon.com/images/I/31c7clMQvGL._SL75_.jpg',
    sku: '495063_prime',
    asin: 'B07QBJ99ZR',
    product_name: 'MUSTANG Damen Slim Fit Caro Jeans, 4000-310 Schwarz, Gr.- 27W / 30L',
    amazon_order_id: '306-4341082-2937960',
    buyer: 'm0p7r2znhl8jfl8@marketplace.amazon.de'
  },
  {
    id: 376,
    campaign: 124,
    sent: {
      schedule_event: 3,
      schedule_after: 7
    },
    message: 132,
    request_review: true,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
    sku: '4050476404891_prime',
    asin: 'B001QOE5G4',
    product_name: "Levi's Herren 501 Original Fit Jeans, Schwarz (Black 801), 33W / 32L",
    amazon_order_id: '305-5800768-6219503',
    buyer: 'h4cqwtfmpwbzz18@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31e9ozsNt9L._SL75_.jpg',
    sku: '433637_mfn',
    asin: 'B07S3WMT5Y',
    product_name: "Levi's Plus Size Damen 314 Pl Shaping Straight Jeans, London Rivers Plus, 18S",
    amazon_order_id: '028-6671930-9952336',
    buyer: '5l78sf4yvg7hnxy@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: '2020-10-26T11:10:00.074Z',
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31zFDuOJy0L._SL75_.jpg',
    sku: '367486_prime',
    asin: 'B088P2MZ1L',
    product_name:
      'JACK & JONES Herren T-Shirt 4er Pack JJEPROLOG Tee SS Crew Neck Kurzarm Rundhals Logo Print 100% Baumwolle Schwarz Blau Grau Navy Wei\u00df S M L XL XXL, Gr\u00f6\u00dfe:S, Farbe:Farbmix 7',
    amazon_order_id: '028-9982131-4154721',
    buyer: '4vhr92848vlk2tk@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: '2020-10-26T11:10:00.074Z',
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'http://ecx.images-amazon.com/images/I/41UyDfAcxCL._SL75_.jpg',
    sku: '404586_prime',
    asin: 'B07YCCJ9CJ',
    product_name:
      'riverso Herren Kapuzenjacke RIVNoah Sweatjacke Sweatshirt Kapuzenpullover Hoodie Kapuze Rei\u00dfverschluss Basic Baumwolle Grau Braun Gr\u00fcn Rot Blau Schwarz S-5XL, Gr\u00f6\u00dfe:L, Farbe:Grey 2',
    amazon_order_id: '306-1399469-7115535',
    buyer: '4x0653zkh5y921c@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: '2020-10-26T11:10:00.074Z',
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/41+2q+v6IdL._SL75_.jpg',
    sku: '4050476981569_prime',
    asin: 'B00D5P9A7G',
    product_name:
      'Wrangler Herren Jeans Normaler Bund GREENSBORO EL CAMINO Denim Performance, Gr. 36/30, Blau (EL CAMINO 43C)',
    amazon_order_id: '028-7870337-7767554',
    buyer: 'rp122gq712b166c@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: '2020-10-26T11:10:00.074Z',
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/41CvmzXer3L._SL75_.jpg',
    sku: '4058824778670_prime',
    asin: 'B07PFXBYM7',
    product_name:
      'MUSTANG Herren Jeans Oregon - Bootcut - Blau - Denim Blue - Medium Blue - Mid Blue, Gr\u00f6\u00dfe:W 36 L 32, Farbe:Medium Blue (1006280-702)',
    amazon_order_id: '303-8516744-1808337',
    buyer: 'b97nsg5wld5v3dp@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: '2020-10-26T11:10:00.074Z',
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31edRUmJnAL._SL75_.jpg',
    sku: '8681521083912_prime',
    asin: 'B07NLJWMX1',
    product_name:
      'LTB Damen Jeans Alicia - Skinny Fit - Schwarz - Melva Wash, Gr\u00f6\u00dfe:W 27 L 30, Farbe:Melva Wash (51632)',
    amazon_order_id: '304-6326789-3776309',
    buyer: 'dfhhnf46dg91rlb@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31Kpl10iCpL._SL75_.jpg',
    sku: '409285_mfn',
    asin: 'B076F86HF8',
    product_name:
      'ONLY Damen ONLJANA L/S COWLNCK Dress Wool KNT NOOS Pullover, Grau (Dark Grey Melange Dark Grey Melange), 38 (Herstellergr\u00f6\u00dfe: M)',
    amazon_order_id: '305-7933067-7708355',
    buyer: '70q4rqjf2cyjyd0@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31KaIeXsWPL._SL75_.jpg',
    sku: '523925_mfn',
    asin: 'B07ZRWHBVK',
    product_name: 'ONLY Damen Cargohose Kn\u00f6chellange Cargohose 4230Black',
    amazon_order_id: '306-9166028-8359506',
    buyer: '6zb1f0f632y1j9p@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    amazon_order_id: '304-8983834-4331533',
    buyer: 'wm2pklzrfcdl8ph@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/316gjIy2o5L._SL75_.jpg',
    sku: '5713738734974_mfn',
    asin: 'B07C61C6ZS',
    product_name: 'JACK & JONES Herren Hoodie Logo XLBlack',
    amazon_order_id: '306-3929332-2938705',
    buyer: 'xrv5ct82ytq376c@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    amazon_order_id: '306-9331296-3080358',
    buyer: 'z024r253jf4fsfq@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/51if85hKoYL._SL75_.jpg',
    sku: '411115_mfn',
    asin: 'B07ZF573G8',
    product_name: 'ONLY 15113356 Geena Pullover Damen Dark Green XS',
    amazon_order_id: '306-3689870-0668301',
    buyer: 'r1s6dpnb72czbl3@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31kfMmz3RJL._SL75_.jpg',
    sku: '5713751977846_mfn',
    asin: 'B07FPXF47C',
    product_name:
      'JACK & JONES Herren Slim/Straight Fit Jeans Tim ORIGINAL AM 782 50SPS 3334Blue Denim',
    amazon_order_id: '304-5135785-0043522',
    buyer: '73nfm62sxsp79nw@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31ImCbZcdhL._SL75_.jpg',
    sku: '502974_mfn',
    asin: 'B08B612C5G',
    product_name: 'ONLY Damen ONLSEDONA Light Coat OTW NOOS Mantel, Rosin/Detail:Melange, XXL',
    amazon_order_id: '303-5712251-5987510',
    buyer: 'zpv2d9w7jnhz30h@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31Ssv4+wdCL._SL75_.jpg',
    sku: '5415147879665_py_sc',
    asin: 'B00EU9N2CA',
    product_name: "Wrangler Herren Texas Contrast' Jeans, Schwarz (Raven), 36W / 30L",
    amazon_order_id: '302-3592928-0164325',
    buyer: 'v2f8j4yfzs7d1mp@marketplace.amazon.de'
  },
  {
    campaign: 124,
    sent: {
      schedule_event: 5,
      schedule_after: 7
    },
    message: 133,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31zzp0pd3mL._SL75_.jpg',
    sku: '4050476404891_prime',
    asin: 'B001QOE5G4',
    product_name: "Levi's Herren 501 Original Fit Jeans, Schwarz (Black 801), 33W / 32L",
    amazon_order_id: '305-5800768-6219503',
    buyer: 'h4cqwtfmpwbzz18@marketplace.amazon.de'
  },
  {
    campaign: 131,
    sent: {
      schedule_event: 2,
      schedule_after: 7
    },
    message: 135,
    request_review: false,
    paused: false,
    product_image: 'https://m.media-amazon.com/images/I/31a8kxVfwvL._SL75_.jpg',
    sku: '411646_mfn',
    asin: 'B07JPX3PM3',
    product_name: 'JACK & JONES Herren Slim Fit Chino Marco Bowie SA Navy Blazer 3232Navy Blazer',
    amazon_order_id: '304-7678724-0917152',
    buyer: 'bckblz1x41xh6x5@marketplace.amazon.de'
  }
];

const dynamicVariablesDB = {
  '{{product-title}}': {
    desc: 'Will dynamically input the product title.',
    preview: 'product 7503682'
  },
  '{{order-id}}': {
    desc: 'Will dynamically input the order ID.',
    preview: '123-4567890-1234567'
  },
  '{{product-review-link: Leave a review by clicking here}}': {
    desc: 'Link it to the product review with editable text.',
    preview:
      'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682'
  },
  '{{product-title-review-link}}': {
    desc: 'This will dynamically input the title of the product and link it to the product review.',
    preview:
      'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682'
  },
  '{{feedback-link: Leave feedback by clicking here}}': {
    desc: 'This will create a link to leave feedback for the seller with a dynamic text.',
    preview: 'https://www.amazon.com/hz/feedback/?_encoding=UTF8&orderID=111-5685561-2010022'
  },
  '{{product-list}}': {
    desc: 'Will insert the product name as a list.',
    preview: ['product 7503682', 'product 7503683']
  },
  '{{product-title-review-link-list}}': {
    desc: 'Will insert a list of\u00a0the product titles with a review.',
    preview: [
      {
        link:
          'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682',
        title: 'product 7503682'
      },
      {
        link:
          'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682',
        title: 'product 7503682'
      }
    ]
  }
};

mock.onGet(API.emailTool.dynamicVariables).reply(() => {
  return [200, dynamicVariablesDB];
});

mock.onGet(API.emailTool.messageTemplates).reply(() => {
  return [200, messageTemplatesDB];
});

mock.onGet(API.emailTool.campaigns).reply(() => {
  return [200, campaignsDB];
});

mock.onGet(API.emailTool.messages).reply(() => {
  return [200, messagesDB];
});
