import API, { API_V2_1 } from '@sm/api';
import mock from '../mock';
import { expensesList } from './expenses-db';

const summaryDetailedBreakdown = {
  product_sales: 39369.7,
  tax: 4627.76,
  gift_wrap: 3.99,
  discounts: 185.07,
  //
  refund_cashflow: 1161.55,
  fba_fees: 8330.36,
  referral_fees: 6550.78,
  tax_paid: 1.09,
  //
  new_gross_profit: 22079.46,
  production_cost: null,
  est_tax_unpaid: 4626.67,
  //
  profit: 17452.79,
  margin: 39.84,
  roi: null,
  sf_every_month: 123.45,
  ltsf_12_months: 123.45,
  ltsf_6_months: 123.45
};

const totals = {
  product_sales: 1000,
  new_tax: -10,
  discounts: -10,
  shipping: -10,
  promo_sales: 100,
  ads_sales: 100,
  cin7_cogs: -12,
  orders: 56,
  units: 64,
  profit: 6185.99,
  ads_products: 182.33,
  ads_display: 50,
  ads_video: 30,
  ads_brands: 20,
  refunds: 0,
  ppcOrders: 11,
  ppcOrdersDisplay: 0,
  tacos: 2.4838332195834987,
  acos: 4.787612548150521,
  promoUnits: 1,
  refundedUnits: 45,
  reimbursement: 0,
  qualifyForReim: 0,
  not_retuned_units: 0,
  monthly_storage_fee: 0,
  ltsf_6_mo: 0,
  ltsf_12_mo: 0,
  product_sales_refunds: 10,
  referral_fee_reimbursement: 20,
  refund_commission: 5,
  production_cost: 10,
  shipping_cost: 10,
  new_gross_profit: 56465,
  roi: 52,
  margin: 50,
  roas: 132,
  clicks: 123,
  sessions: 1233,
  amazon_expenses: {
    fba_sf: 10,
    fba_ltsf: 10,
    FBAInboundConvenienceFee: 10,
    CouponPaymentEvent: 10,
    FBAInboundTransportationFee: 10,
    FBADisposalFee: 10,
    FBARemovalFee: 10,
    Subscription: 10,
    SellerDealPayment: 10,
    SellerReviewEnrollmentPaymentEvent: 10,
    FBAInboundTransportationProgramFee: 10,
    FBAOverageFee: 10,
    FBAInternationalInboundFreightTaxAndDuty: 10,
    FBAInternationalInboundFreightFee: 10
  },
  custom_expenses: expensesList.custom_expenses,
  referral_fees: 10,
  fba_fees: 10
};

const metricsByDt = {
  metrix: [
    [1714521600000, 35126.32],
    [1714608000000, 35442.88],
    [1714694400000, 30759.05],
    [1714780800000, 33923.66],
    [1714867200000, 36065.74],
    [1714953600000, 34820.96],
    [1715040000000, 31336.28]
  ]
};

const getTotals = (sales, profit, orders, units, ads, refunds) => {
  return { sales, profit, orders, units, ads, refunds };
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

mock.onGet(API.metrix.breakdown).reply(async () => {
  await sleep(500);
  return [200, summaryDetailedBreakdown];
});

mock.onGet(API_V2_1.metrix.totals).reply(() => {
  return [200, totals];
});

mock.onGet(API.metrix.chart).reply(() => {
  return [200, metricsByDt];
});

mock
  .onGet(API.metrix.totals, {
    params: {
      startDate: '2019-03-01',
      endDate: '2019-03-01',
      marketplaceID: 2
    }
  })
  .reply(() => {
    return [200, getTotals(1200, 12, 2, 3, 14, 1)];
  });
