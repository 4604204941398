import {
  amazonSalesChannelShort,
  amazonSellerCentralSalesChannelFull,
  amazonVendorCentralSalesChannelFull,
  ebaySalesChannelFull,
  lazadaSalesChannelFull,
  rakutenSalesChannelFull,
  shopifySalesChannelFull,
  shopifySalesChannelShort,
  targetFull,
  targetShort,
  walmartSalesChannelFull,
  walmartSalesChannelShort
} from '@sm/img/sales-channels';

export const SALES_CHANNELS_STATUSES = {
  ACTIVE: 'active',
  NEW: 'new',
  COMING_SOON: 'coming_soon'
};
export const AMAZON_SALES_CHANNEL_ID = 1;
export const SHOPIFY_SALES_CHANNEL_ID = 2;
export const WALMART_SALES_CHANNEL_ID = 3;
export const AMAZON_VENDOR_SALES_CHANNEL_ID = 7;
export const TARGET_SALES_CHANNEL_ID = 8;

export const SALES_CHANNELS = [
  {
    id: AMAZON_SALES_CHANNEL_ID,
    name: 'Seller Central',
    short_icon: amazonSalesChannelShort,
    full_icon: amazonSellerCentralSalesChannelFull,
    status: SALES_CHANNELS_STATUSES.ACTIVE,
    product_id_type: 'ASIN',
    combine_short_icon_and_name: true
  },
  {
    id: SHOPIFY_SALES_CHANNEL_ID,
    name: 'Shopify',
    short_icon: shopifySalesChannelShort,
    full_icon: shopifySalesChannelFull,
    status: SALES_CHANNELS_STATUSES.NEW,
    product_id_type: 'Product ID',
    combine_short_icon_and_name: true
  },
  {
    id: WALMART_SALES_CHANNEL_ID,
    name: 'Walmart',
    short_icon: walmartSalesChannelShort,
    full_icon: walmartSalesChannelFull,
    status: SALES_CHANNELS_STATUSES.NEW,
    combine_short_icon_and_name: true
  },
  {
    id: 4,
    name: 'Ebay',
    short_icon: null,
    full_icon: ebaySalesChannelFull,
    status: null
  },
  {
    id: 5,
    name: 'Lazada',
    short_icon: null,
    full_icon: lazadaSalesChannelFull,
    status: null
  },
  {
    id: 6,
    name: 'Rakuten',
    short_icon: null,
    full_icon: rakutenSalesChannelFull,
    status: null
  },
  {
    id: AMAZON_VENDOR_SALES_CHANNEL_ID,
    name: 'Vendor Central',
    short_icon: amazonSalesChannelShort,
    full_icon: amazonVendorCentralSalesChannelFull,
    status: SALES_CHANNELS_STATUSES.COMING_SOON,
    combine_short_icon_and_name: true
  },
  {
    id: TARGET_SALES_CHANNEL_ID,
    name: 'Target',
    short_icon: targetShort,
    full_icon: targetFull,
    status: SALES_CHANNELS_STATUSES.COMING_SOON,
    combine_short_icon_and_name: true
  }
];

export const SALES_CHANNELS_BY_ID_MAP = SALES_CHANNELS.reduce((acc, channel) => {
  acc[channel.id] = channel;
  return acc;
}, {});
