import React from 'react';
import PropTypes from 'prop-types';
import { startAdvertisingSignup } from '@sm/advertising';
import { getFlagsById } from '@sm/Flags';
import LinkButton from './LinkButton';

function AccountCardLinks(props) {
  const {
    SCEmailAddressApproved,
    sellerId,
    linkedPPC,
    marketplaces,
    mainMarketplace,
    linkedSPA,
    onConnectSPA,
    onApproveEmail,
    setNotificationMsg,
    notConnectedEndpoints
  } = props;
  const mkList = marketplaces.filter(mk => mk !== 0);
  let region = null;
  if (mkList.length > 0) {
    const flagInfo = getFlagsById(mkList[0]);
    if (flagInfo) {
      region = flagInfo.regionName;
    }
  }

  const linkList = [
    {
      btnText: `${notConnectedEndpoints ? 'Relink' : 'Link'} Seller Central Account`,
      disabled: linkedSPA,
      onClick: () => onConnectSPA(mainMarketplace),
      tooltipText:
        'Enables product data and order data to be imported from Amazon to your Seller Metrix dashboard',
      notConnectedEndpoints
    },
    {
      btnText: 'Link PPC Account',
      disabled: linkedPPC,
      onClick: () => {
        startAdvertisingSignup(
          sellerId,
          'handle_login_with_amazon/',
          errMsg => setNotificationMsg(errMsg),
          region
        );
      },
      tooltipText:
        'Enables Advertising data from Amazon to be imported to your Seller Metrix dashboard'
    },
    {
      disabled: SCEmailAddressApproved,
      alwaysAllow: true,
      btnText: 'Set Up Approved Sender',
      onClick: onApproveEmail,
      tooltipText:
        'To send Standard Emails to your customers, you need to add the Approved Sender Email Address to your Seller Central account. Click for more details...'
    }
  ];
  return (
    <div className="flex flex-col">
      {linkList
        .filter(item => !item.hidden)
        .map(link => (
          <LinkButton {...link} />
        ))}
    </div>
  );
}

AccountCardLinks.propTypes = {
  SCEmailAddressApproved: PropTypes.bool,
  linkedPPC: PropTypes.bool,
  linkedSPA: PropTypes.bool,
  sellerId: PropTypes.string.isRequired,
  onConnectSPA: PropTypes.func.isRequired,
  onApproveEmail: PropTypes.func.isRequired,
  setNotificationMsg: PropTypes.func.isRequired,
  marketplaces: PropTypes.instanceOf(Array).isRequired,
  notConnectedEndpoints: PropTypes.instanceOf(Array),
  mainMarketplace: PropTypes.number
};

AccountCardLinks.defaultProps = {
  SCEmailAddressApproved: false,
  linkedPPC: false,
  linkedSPA: false,
  mainMarketplace: null,
  notConnectedEndpoints: null
};

export default AccountCardLinks;
