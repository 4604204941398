export const SET_FONT_READY = 'SET_FONT_READY';
export const SET_VIEWPORT_HEIGHT = 'SET_VIEWPORT_HEIGHT';
export const SET_SUBHEADER_WARNINGS_HEIGHT = 'SET_SUBHEADER_WARNINGS_HEIGHT';
export const SET_SPA_WARNING_SHOW = 'WARNINGS/SET_SPA_WARNING_SHOW';
export const SET_NOT_FILLED_COMPANY_COUNTRY_WARNING_SHOW =
  'WARNINGS/SET_NOT_FILLED_COMPANY_COUNTRY_WARNING_SHOW';
export const SET_BULK_HCOGS_WARNING_SHOW = 'WARNINGS/SET_BULK_HCOGS_WARNING_SHOW';

export const setFontReadyAction = () => ({ type: SET_FONT_READY });

export const setViewportHeight = payload => ({ type: SET_VIEWPORT_HEIGHT, payload });

export const setSubheaderWarningsHeightAction = payload => ({
  type: SET_SUBHEADER_WARNINGS_HEIGHT,
  payload
});

export const setSpaWarningShowAction = payload => ({
  type: SET_SPA_WARNING_SHOW,
  payload
});

export const setNotFilledCompanyCountryWarningShowAction = payload => ({
  type: SET_NOT_FILLED_COMPANY_COUNTRY_WARNING_SHOW,
  payload
});

export const setBulkHcogsWarningShowAction = payload => ({
  type: SET_BULK_HCOGS_WARNING_SHOW,
  payload
});
