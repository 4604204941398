import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Colors from 'styles/colors';

import { HorizontalScrollContainer } from '@components/layouts';
import ProductCard from '../product-card';
import { CrossIcon } from '../../icons';
import { usePopper } from '@utils';

const horizontalProductListCardStyle = {
  height: 61,
  width: 236
};

function IconComponent(props) {
  return (
    <IconButton className="h-6 w-6 p-0 mr-1" {...props}>
      <CrossIcon fill={Colors.gray.default} className="w-3 h-3" />
    </IconButton>
  );
}

const serialNumbers = ['one', 'two', 'tree', 'four', 'five'];
const typePrefix = 'variation_type_';
const valuePrefix = 'variation_value_';

function HorizontalProductList(props) {
  const { data, onCleared } = props;
  const popper = usePopper({
    closeOnBlur: false,
    offset: '36px, 3px',
    placement: 'top-start',
    paperClassName: 'box-shadow-default pt-2 px-2 pb-2 relative',
    disablePortal: true
  });
  const setupPopper = (e, popperData) => {
    popper.anchorRef.current = e.currentTarget;
    popper.changeData(popperData);
    if (Object.keys(popperData).length) popper.show();
  };

  const getProductVariations = productData =>
    serialNumbers.reduce((spec, index) => {
      if (productData[`${typePrefix}${index}`]) {
        return {
          ...spec,
          [productData[`${typePrefix}${index}`]]: productData[`${valuePrefix}${index}`]
        };
      }
      return spec;
    }, {});
  const getContent = () => {
    return data.map((value, index) => {
      const iconComponent = (
        <IconComponent
          data-index={index}
          onClick={event => onCleared(event.currentTarget.dataset.index)}
        />
      );
      return (
        <div
          key={value.type + value.id}
          className="mr-4"
          onMouseOver={e => setupPopper(e, getProductVariations(value))}
          onFocus={e => setupPopper(e, getProductVariations(value))}
          onMouseLeave={() => {
            popper.setData({});
            popper.hide();
          }}
        >
          <ProductCard
            {...value}
            {...props}
            style={horizontalProductListCardStyle}
            lastItem={iconComponent}
            className="inline-flex items-center justify-between bg-white box-shadow-list pl-2 rounded"
            showArrow={false}
          />
        </div>
      );
    });
  };

  const content = getContent();

  const popperArrow = (
    <div
      className="absolute z-0"
      style={{
        top: 'calc(100% - 0.1rem)',
        left: '50%',
        width: '0',
        height: '0',
        borderTop: `0.5rem solid ${Colors.white.default}`,
        borderRight: '0.5rem solid transparent',
        borderLeft: `0.5rem solid transparent`,
        transform: 'translateX(-50%)'
      }}
    />
  );

  return (
    <HorizontalScrollContainer className="sm:pb-1" style={{ height: '71px)' }}>
      <div className="flex w-full visible">{content}</div>
      <popper.wrapper>
        <div style={{ lineHeight: '0.875rem' }}>
          {Object.entries(popper.data).map(([type, value]) => {
            return (
              <div key={type + value}>
                <span className="text-xs font-medium leading-none">{type}: </span>
                <span className="text-xs leading-none">{value}</span>
              </div>
            );
          })}
        </div>
        {popperArrow}
      </popper.wrapper>
    </HorizontalScrollContainer>
  );
}

HorizontalProductList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onCleared: PropTypes.func.isRequired
};

export default HorizontalProductList;
