import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import BlackTooltip from '@components/common/BlackTooltip';
import Colors from 'styles/colors';
import { CheckIcon, ChevronRightIcon, ExclamationIcon } from '../icons';

const LinkButton = ({
  btnText,
  tooltipText,
  onClick,
  disabled,
  alwaysAllow,
  notConnectedEndpoints
}) => {
  const active = !disabled || notConnectedEndpoints;

  const getIcon = () => {
    const iconClassnames = 'mr-4 sm-w-14px sm-h-14px';
    if (!notConnectedEndpoints) {
      return (
        <CheckIcon
          className={iconClassnames}
          fill={disabled ? Colors.green.lighter : Colors.gray.default}
        />
      );
    }
    return <ExclamationIcon className={iconClassnames} fill={Colors.yellow.default} />;
  };

  const getTooltipContent = () => {
    if (!notConnectedEndpoints) return tooltipText;
    return (
      <div>
        Restricted access to the following endpoints:{' '}
        <span className="font-semibold">{notConnectedEndpoints?.join(', ')}</span>
      </div>
    );
  };
  return (
    <BlackTooltip title={getTooltipContent()} placement="bottom" arrow>
      <div className="w-full">
        <Button
          className={classNames(
            'w-full mt-3 px-4 h-12 full-border-gray-lighter text-black rounded normal-case tracking-normal',
            { 'cursor-pointer': !disabled }
          )}
          disabled={!active && !alwaysAllow}
          onClick={onClick}
        >
          <div className="w-full flex items-center">
            {getIcon()}
            <p
              className={classNames('text-sm', { 'text-darkest': !active, 'text-yellow': active })}
            >
              {btnText}
            </p>
            {active && (
              <ChevronRightIcon className="ml-auto sm-h-14px" fill={Colors.yellow.default} />
            )}
          </div>
        </Button>
      </div>
    </BlackTooltip>
  );
};

LinkButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  alwaysAllow: PropTypes.bool,
  notConnectedEndpoints: PropTypes.instanceOf(Array)
};

LinkButton.defaultProps = {
  tooltipText: '',
  onClick: () => {},
  disabled: false,
  alwaysAllow: false,
  notConnectedEndpoints: null
};

export default LinkButton;
