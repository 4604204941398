import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import Colors from 'styles/colors';
import { SVG_GRAD_ONE } from '../gradients';

const TabSwitcher = ({
  options,
  activeTab,
  changeActiveTab,
  absolutePosition,
  className,
  compactView
}) => {
  const activeColor = SVG_GRAD_ONE;
  const passiveColor = Colors.gray.dark;

  return (
    <div
      className={classNames('flex', className, { absolute: absolutePosition })}
      style={
        absolutePosition ? { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } : {}
      }
    >
      {options.map(option => {
        const isOptionActive = activeTab === option.value;
        return (
          <Box
            key={option.text}
            onClick={() => changeActiveTab(option.value)}
            className="flex flex-col items-center rounded pt-2 cursor-pointer hover:bg-white-gray transition duration-200"
          >
            <div className="flex items-center mb-1 px-2 sm:px-3">
              {option.icon && (
                <option.icon.type
                  {...option.icon.props}
                  fill={isOptionActive ? activeColor : passiveColor}
                  className="sm:mr-2"
                />
              )}
              <Typography
                className={classNames('text-sm font-medium', {
                  'sm:mx-6': !option.icon && !compactView,
                  'hidden sm:block': option.icon,
                  'text-yellow': isOptionActive,
                  'text-gray-dark': !isOptionActive
                })}
              >
                {option.text}
              </Typography>
            </div>
            <div className={classNames('underline-xs w-full', { 'bg-grad-8': isOptionActive })} />
          </Box>
        );
      })}
    </div>
  );
};

TabSwitcher.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]).isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  absolutePosition: PropTypes.bool,
  compactView: PropTypes.bool,
  className: PropTypes.string
};

TabSwitcher.defaultProps = {
  absolutePosition: false,
  compactView: false,
  className: ''
};
export default TabSwitcher;
