import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendError } from '@utils';
import {
  setPrimaryNotificationMsgAction,
  setReactBoundaryErrorNotificationAction
} from 'seller/store/actions';
import ROUTES from 'seller/routing';
import { isProductionStage } from '@utils/build';

class ErrorBoundary extends React.Component {
  componentDidMount() {
    const {
      reactBoundary,
      setPrimaryNotificationMsg,
      setReactBoundaryErrorNotification
    } = this.props;
    if (reactBoundary?.errorNotification) {
      setPrimaryNotificationMsg(
        'Sorry, something went wrong. Please try again or contact support@sellermetrix.com to resolve this.'
      );
      setReactBoundaryErrorNotification(false);
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    sendError(error, errorInfo);
    if (!isProductionStage()) return;
    const { setReactBoundaryErrorNotification } = this.props;
    setReactBoundaryErrorNotification(true);
    document.location = ROUTES.home;
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  reactBoundary: PropTypes.instanceOf(Object).isRequired,
  setPrimaryNotificationMsg: PropTypes.func.isRequired,
  setReactBoundaryErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ reactBoundary: state.reactBoundary });

const mapDispatchToProps = dispatch => ({
  setPrimaryNotificationMsg: msg => dispatch(setPrimaryNotificationMsgAction(msg)),
  setReactBoundaryErrorNotification: value =>
    dispatch(setReactBoundaryErrorNotificationAction(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBoundary);
