import { GLOBAL_MK } from '@sm/Flags';
import {
  SET_COMPARE_DATE_RANGE,
  SET_DATE_RANGE,
  SET_MARKETPLACE,
  SET_SELECTED_MARKETPLACES
} from '../actions';
import { createRange } from '@utils';
import { SET_PRODUCT_STATUS } from '../saga/products';

const initialState = {
  marketplaceID: null,
  dateRange: createRange(new Date(), new Date()),
  productStatus: 'active',
  compareDateRange: null,
  marketplaces: [],
  selectedMarketplaces: [GLOBAL_MK]
};

export const selectorSelector = state => state.selector;

const selector = (state = initialState, action) => {
  switch (action.type) {
    case SET_MARKETPLACE: {
      const { marketplaceID } = action;
      return { ...state, marketplaceID };
    }
    case SET_SELECTED_MARKETPLACES: {
      return { ...state, selectedMarketplaces: action.payload };
    }
    case SET_DATE_RANGE: {
      const { dateRange } = action;
      return { ...state, dateRange };
    }
    case SET_COMPARE_DATE_RANGE: {
      const { compareDateRange } = action;
      return { ...state, compareDateRange };
    }
    case SET_PRODUCT_STATUS: {
      const { status } = action;
      return { ...state, productStatus: status };
    }
    default: {
      return state;
    }
  }
};

export default selector;
