import { API_V2_1 } from '@sm/api';
import mock from '../mock';

const sqpProducts = [
  {
    id: 1266012,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.236692Z',
    updated_on: '2023-08-23T01:50:22.236697Z',
    asin: 'B08DM4Z21B',
    date: '2023-06-30',
    qp_asin_one_day_shipping_cart_adds: '9472',
    qp_asin_cart_adds: 35476,
    qp_click_rate: 35.57,
    qp_asin_query_rank: 1,
    qp_asin_clicks: 100,
    qp_asin_query_volume: 795204,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 0.03,
    qp_asin_count_clicks: 111,
    qp_asin_purchases: 10,
    qp_asin_count_impressions: 4699,
    qp_asin_two_day_shipping_clicks: 47795,
    qp_asin_query: 'diaper bag',
    qp_asin_count_purchases: '10',
    qp_asin_impressions: 16347557,
    qp_asin_median_query_price_cart_adds: 36.99,
    qp_asin_share_clicks: 0.04,
    qp_asin_two_day_shipping_cart_adds: 6042,
    qp_asin_share_cart_adds: 0.02,
    qp_asin_one_day_shipping_purchases: '1012',
    qp_asin_median_price_purchases: 41.99,
    qp_asin_same_day_shipping_purchases: '546',
    qp_asin_same_day_shipping_cart_adds: 4037,
    qp_asin_purchase_rate: 0.44,
    qp_asin_median_query_price_clicks: 37.98,
    qp_asin_count_cart_adds: '7',
    qp_asin_one_day_shipping_clicks: 71594,
    qp_asin_cart_add_rate: 4.46,
    qp_asin_same_day_shipping_clicks: 28094,
    qp_asin_median_query_price_purchases: 30.99,
    qp_asin_two_day_shipping_purchases: 488,
    qp_asin_median_price_cart_adds: 41.99,
    qp_asin_share_purchases: 0.03,
    marketplace: 3,
    parent_asin: 'B09B2LZ2Q9',
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  },
  {
    id: 1266012,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.236692Z',
    updated_on: '2023-08-23T01:50:22.236697Z',
    asin: 'B08DM4Z21C',
    date: '2023-07-31',
    qp_asin_one_day_shipping_cart_adds: '9472',
    qp_asin_cart_adds: 35476,
    qp_click_rate: 35.57,
    qp_asin_query_rank: 1,
    qp_asin_clicks: 282817,
    qp_asin_query_volume: 795204,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 0.03,
    qp_asin_count_clicks: 111,
    qp_asin_purchases: 3524,
    qp_asin_count_impressions: 4699,
    qp_asin_two_day_shipping_clicks: 47795,
    qp_asin_query: 'diaper bag',
    qp_asin_count_purchases: '1',
    qp_asin_impressions: 16347557,
    qp_asin_median_query_price_cart_adds: 36.99,
    qp_asin_share_clicks: 0.04,
    qp_asin_two_day_shipping_cart_adds: 6042,
    qp_asin_share_cart_adds: 0.02,
    qp_asin_one_day_shipping_purchases: '1012',
    qp_asin_median_price_purchases: 41.99,
    qp_asin_same_day_shipping_purchases: '546',
    qp_asin_same_day_shipping_cart_adds: 4037,
    qp_asin_purchase_rate: 0.44,
    qp_asin_median_query_price_clicks: 37.98,
    qp_asin_count_cart_adds: '7',
    qp_asin_one_day_shipping_clicks: 71594,
    qp_asin_cart_add_rate: 4.46,
    qp_asin_same_day_shipping_clicks: 28094,
    qp_asin_median_query_price_purchases: 30.99,
    qp_asin_two_day_shipping_purchases: 488,
    qp_asin_median_price_cart_adds: 41.99,
    qp_asin_share_purchases: 0.03,
    marketplace: 3,
    parent_asin: 'B09B2LZ2Q9',
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  },
  {
    id: 1266012,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.236692Z',
    updated_on: '2023-08-23T01:50:22.236697Z',
    asin: 'B08DM4Z21G',
    date: '2023-06-30',
    qp_asin_one_day_shipping_cart_adds: '9472',
    qp_asin_cart_adds: 35476,
    qp_click_rate: 35.57,
    qp_asin_query_rank: 1,
    qp_asin_clicks: 282817,
    qp_asin_query_volume: 795204,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 0.03,
    qp_asin_count_clicks: 111,
    qp_asin_purchases: 3524,
    qp_asin_count_impressions: 4699,
    qp_asin_two_day_shipping_clicks: 47795,
    qp_asin_query: 'diaper bag',
    qp_asin_count_purchases: '1',
    qp_asin_impressions: 16347557,
    qp_asin_median_query_price_cart_adds: 36.99,
    qp_asin_share_clicks: 0.04,
    qp_asin_two_day_shipping_cart_adds: 6042,
    qp_asin_share_cart_adds: 0.02,
    qp_asin_one_day_shipping_purchases: '1012',
    qp_asin_median_price_purchases: 41.99,
    qp_asin_same_day_shipping_purchases: '546',
    qp_asin_same_day_shipping_cart_adds: 4037,
    qp_asin_purchase_rate: 0.44,
    qp_asin_median_query_price_clicks: 37.98,
    qp_asin_count_cart_adds: '7',
    qp_asin_one_day_shipping_clicks: 71594,
    qp_asin_cart_add_rate: 4.46,
    qp_asin_same_day_shipping_clicks: 28094,
    qp_asin_median_query_price_purchases: 30.99,
    qp_asin_two_day_shipping_purchases: 488,
    qp_asin_median_price_cart_adds: 41.99,
    qp_asin_share_purchases: 0.03,
    marketplace: 3,
    parent_asin: 'B09B2LZ2Q9',
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  },
  {
    id: 1266013,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.236806Z',
    updated_on: '2023-08-23T01:50:22.236812Z',
    asin: 'B08DM4Z21B',
    date: '2023-06-30',
    qp_asin_one_day_shipping_cart_adds: '857',
    qp_asin_cart_adds: 3281,
    qp_click_rate: 33.95,
    qp_asin_query_rank: 2,
    qp_asin_clicks: 28804,
    qp_asin_query_volume: 84831,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 0.03,
    qp_asin_count_clicks: 11,
    qp_asin_purchases: 257,
    qp_asin_count_impressions: 557,
    qp_asin_two_day_shipping_clicks: 6376,
    qp_asin_query: 'diaper bags',
    qp_asin_count_purchases: '1',
    qp_asin_impressions: 1847864,
    qp_asin_median_query_price_cart_adds: 36.99,
    qp_asin_share_clicks: 0.04,
    qp_asin_two_day_shipping_cart_adds: 683,
    qp_asin_share_cart_adds: 0.09,
    qp_asin_one_day_shipping_purchases: '84',
    qp_asin_median_price_purchases: 41.99,
    qp_asin_same_day_shipping_purchases: '31',
    qp_asin_same_day_shipping_cart_adds: 297,
    qp_asin_purchase_rate: 0.3,
    qp_asin_median_query_price_clicks: 37.97,
    qp_asin_count_cart_adds: '3',
    qp_asin_one_day_shipping_clicks: 7268,
    qp_asin_cart_add_rate: 3.87,
    qp_asin_same_day_shipping_clicks: 2256,
    qp_asin_median_query_price_purchases: 30.99,
    qp_asin_two_day_shipping_purchases: 40,
    qp_asin_median_price_cart_adds: 41.99,
    qp_asin_share_purchases: 0.39,
    marketplace: 3,
    parent_asin: 'B09B2LZ2Q9',
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  },
  {
    id: 1266014,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.236919Z',
    updated_on: '2023-08-23T01:50:22.236924Z',
    asin: 'B08DM4Z21B',
    date: '2023-06-30',
    qp_asin_one_day_shipping_cart_adds: '1921',
    qp_asin_cart_adds: 7149,
    qp_click_rate: 40.14,
    qp_asin_query_rank: 3,
    qp_asin_clicks: 61259,
    qp_asin_query_volume: 152596,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 0.02,
    qp_asin_count_clicks: 16,
    qp_asin_purchases: 915,
    qp_asin_count_impressions: 604,
    qp_asin_two_day_shipping_clicks: 10728,
    qp_asin_query: 'diaper bag backpack',
    qp_asin_count_purchases: '0',
    qp_asin_impressions: 3147277,
    qp_asin_median_query_price_cart_adds: 34.99,
    qp_asin_share_clicks: 0.03,
    qp_asin_two_day_shipping_cart_adds: 1271,
    qp_asin_share_cart_adds: 0.01,
    qp_asin_one_day_shipping_purchases: '262',
    qp_asin_median_price_purchases: 0.0,
    qp_asin_same_day_shipping_purchases: '154',
    qp_asin_same_day_shipping_cart_adds: 876,
    qp_asin_purchase_rate: 0.6,
    qp_asin_median_query_price_clicks: 36.99,
    qp_asin_count_cart_adds: '1',
    qp_asin_one_day_shipping_clicks: 16352,
    qp_asin_cart_add_rate: 4.68,
    qp_asin_same_day_shipping_clicks: 6506,
    qp_asin_median_query_price_purchases: 29.99,
    qp_asin_two_day_shipping_purchases: 128,
    qp_asin_median_price_cart_adds: 41.99,
    qp_asin_share_purchases: 0.0,
    marketplace: 3,
    parent_asin: 'B09B2LZ2Q9',
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  },
  {
    id: 1266015,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.237034Z',
    updated_on: '2023-08-23T01:50:22.237039Z',
    asin: 'B08DM4Z231',
    date: '2023-06-30',
    qp_asin_one_day_shipping_cart_adds: '27',
    qp_asin_cart_adds: 176,
    qp_click_rate: 44.27,
    qp_asin_query_rank: 4,
    qp_asin_clicks: 1409,
    qp_asin_query_volume: 3183,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 1.61,
    qp_asin_count_clicks: 10,
    qp_asin_purchases: 21,
    qp_asin_count_impressions: 1030,
    qp_asin_two_day_shipping_clicks: 208,
    qp_asin_query: 'large diaper bag for 2 kids',
    qp_asin_count_purchases: '0',
    qp_asin_impressions: 63971,
    qp_asin_median_query_price_cart_adds: 44.44,
    qp_asin_share_clicks: 0.71,
    qp_asin_two_day_shipping_cart_adds: 21,
    qp_asin_share_cart_adds: 0.0,
    qp_asin_one_day_shipping_purchases: '2',
    qp_asin_median_price_purchases: 0.0,
    qp_asin_same_day_shipping_purchases: '1',
    qp_asin_same_day_shipping_cart_adds: 9,
    qp_asin_purchase_rate: 0.66,
    qp_asin_median_query_price_clicks: 43.99,
    qp_asin_count_cart_adds: '0',
    qp_asin_one_day_shipping_clicks: 303,
    qp_asin_cart_add_rate: 5.53,
    qp_asin_same_day_shipping_clicks: 97,
    qp_asin_median_query_price_purchases: 44.99,
    qp_asin_two_day_shipping_purchases: 0,
    qp_asin_median_price_cart_adds: 0.0,
    qp_asin_share_purchases: 0.0,
    marketplace: 3,
    parent_asin: 'B09B2LZ2Q9',
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  },
  {
    id: 1266015,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.237034Z',
    updated_on: '2023-08-23T01:50:22.237039Z',
    asin: 'B08DM4Z111',
    date: '2023-06-30',
    qp_asin_one_day_shipping_cart_adds: '27',
    qp_asin_cart_adds: 176,
    qp_click_rate: 44.27,
    qp_asin_query_rank: 4,
    qp_asin_clicks: 1409,
    qp_asin_query_volume: 3183,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 1.61,
    qp_asin_count_clicks: 10,
    qp_asin_purchases: 21,
    qp_asin_count_impressions: 1030,
    qp_asin_two_day_shipping_clicks: 208,
    qp_asin_query: 'large diaper bag for 2 kids',
    qp_asin_count_purchases: '0',
    qp_asin_impressions: 63971,
    qp_asin_median_query_price_cart_adds: 44.44,
    qp_asin_share_clicks: 0.71,
    qp_asin_two_day_shipping_cart_adds: 21,
    qp_asin_share_cart_adds: 0.0,
    qp_asin_one_day_shipping_purchases: '2',
    qp_asin_median_price_purchases: 0.0,
    qp_asin_same_day_shipping_purchases: '1',
    qp_asin_same_day_shipping_cart_adds: 9,
    qp_asin_purchase_rate: 0.66,
    qp_asin_median_query_price_clicks: 43.99,
    qp_asin_count_cart_adds: '0',
    qp_asin_one_day_shipping_clicks: 303,
    qp_asin_cart_add_rate: 5.53,
    qp_asin_same_day_shipping_clicks: 97,
    qp_asin_median_query_price_purchases: 44.99,
    qp_asin_two_day_shipping_purchases: 0,
    qp_asin_median_price_cart_adds: 0.0,
    qp_asin_share_purchases: 0.0,
    marketplace: 3,
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  },
  {
    id: 1266015,
    user_id: 4672,
    seller_account_id: 3207,
    marketplace_id: 3,
    created_on: '2023-08-23T01:50:22.237034Z',
    updated_on: '2023-08-23T01:50:22.237039Z',
    asin: 'B08DM4Z111',
    date: '2023-07-31',
    qp_asin_one_day_shipping_cart_adds: '27',
    qp_asin_cart_adds: 176,
    qp_click_rate: 44.27,
    qp_asin_query_rank: 4,
    qp_asin_clicks: 100,
    qp_asin_query_volume: 3183,
    qp_asin_median_price_clicks: 41.99,
    qp_asin_share_impressions: 1.61,
    qp_asin_count_clicks: 10,
    qp_asin_purchases: 20,
    qp_asin_count_impressions: 1030,
    qp_asin_two_day_shipping_clicks: 208,
    qp_asin_query: 'large diaper bag for 2 kids',
    qp_asin_count_purchases: '0',
    qp_asin_impressions: 63971,
    qp_asin_median_query_price_cart_adds: 44.44,
    qp_asin_share_clicks: 0.71,
    qp_asin_two_day_shipping_cart_adds: 21,
    qp_asin_share_cart_adds: 0.0,
    qp_asin_one_day_shipping_purchases: '2',
    qp_asin_median_price_purchases: 0.0,
    qp_asin_same_day_shipping_purchases: '1',
    qp_asin_same_day_shipping_cart_adds: 9,
    qp_asin_purchase_rate: 0.66,
    qp_asin_median_query_price_clicks: 43.99,
    qp_asin_count_cart_adds: '0',
    qp_asin_one_day_shipping_clicks: 303,
    qp_asin_cart_add_rate: 5.53,
    qp_asin_same_day_shipping_clicks: 97,
    qp_asin_median_query_price_purchases: 44.99,
    qp_asin_two_day_shipping_purchases: 0,
    qp_asin_median_price_cart_adds: 0.0,
    qp_asin_share_purchases: 0.0,
    marketplace: 3,
    name:
      'BabbleRoo Diaper Bag Backpack, Multifunction Large Bags with Changing Pad & Stroller Straps & Pacifier Case, Unisex Stylish Travel Back Pack Nappy Changing Bag for Moms Dads (gray)',
    smallImage: 'https://m.media-amazon.com/images/I/81WQnohvYTS._SL75_.jpg',
    variation_type_one: 'color',
    variation_value_one: 'Black & Apricot',
    orders_count: 1
  }
];
mock.onGet(API_V2_1.searchQueryPerformance.products).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 200));
  return [200, sqpProducts];
});

const STATUS = {
  onboarding: 'finished', // 'started', 'finished'
  is_pulling_active: true // false
};
mock.onGet(API_V2_1.searchQueryPerformance.status).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200, STATUS];
});

const ACCOUNTS = [
  {
    seller_id: 'A2S4KAOVUFJTUH',
    region: 'NA',
    name: 'easy_baby North America Seller Account',
    marketplaces: [
      {
        total_jobs: 100,
        processed_jobs: 45,
        saved_jobs: 10,
        marketplaceid: 'A2EUQ1WTGCTBG2',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 100,
        saved_jobs: 100,
        marketplaceid: 'ATVPDKIKX0DER',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 0,
        saved_jobs: 25,
        marketplaceid: 'A1AM78C64UM0Y8',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      }
    ]
  },
  {
    seller_id: 'A3Q7PLQT3KXZKN',
    region: 'NA',
    name: 'bbr North America Seller Account',
    marketplaces: [
      {
        total_jobs: 100,
        processed_jobs: 45,
        saved_jobs: 10,
        marketplaceid: 'A2EUQ1WTGCTBG2',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 100,
        saved_jobs: 100,
        marketplaceid: 'ATVPDKIKX0DER',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 0,
        saved_jobs: 25,
        marketplaceid: 'A1AM78C64UM0Y8',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      }
    ]
  }
];
mock.onGet(API_V2_1.searchQueryPerformance.accounts).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200, ACCOUNTS];
});
