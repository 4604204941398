import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, Typography } from '@material-ui/core';
import classNames from 'classnames';
import 'react-day-picker/lib/style.css';
import Colors from 'styles/colors';
import { isObject } from 'util';
import { isSmallSize } from '@sm/WindowSizes';
import { createRange } from '@utils';
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isAfter,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subYears
} from 'date-fns';
import RangeList, { DATE_RANGE_PRESETS } from './range-list/RangeList';
import {
  getLimitedRangeByLifetime,
  getMkToday,
  getNewSelection,
  getRangeBySnippet,
  getSafeMkRange,
  getShiftedRange,
  isAllYearSelected,
  snippetsList
} from './snippets';
import Calendar from './calendar/Calendar';
import IntervalControl from './IntervalControl';
import { CalendarIcon } from '../icons';
import FlatButton from '../buttons/FlatButton';
import CalendarTooltip from './CalendarTooltip';
import RadioIcon from '../radio/RadioIcon';
import DatePickerWidgetContent from './DatePickerWidgetContent';

export const PREVIOUS_PERIOD = 'Previous period';
export const PREVIOUS_YEAR = 'Previous year';
const COMPARE_OFF = 'Off';

const DATE_PICKER_DIALOG_ANCHOR_CLASS = 'date-picker-anchor';

export const DATE_PICKER_GRANULARITY_MODES = {
  day: 'day',
  week: 'week',
  month: 'month',
  quarter: 'quarter',
  year: 'year'
};

const DEFAULT_WEEK_STARTS_ON = 1; // Monday

class DatePicker extends Component {
  state = {
    currentSnippet: '',
    isSecondSelection: false,
    today: new Date(),
    anchorEl: null,
    currentRange: '',
    hideSelected: true,
    compareType: COMPARE_OFF
  };

  componentDidUpdate(prevProps) {
    const {
      marketplaceID,
      marketplaces,
      onRangeChange,
      initRange,
      oneDaySelectionAllowed,
      granularity
    } = this.props;
    const { initRange: initRangePrev, granularity: prevGranularity } = prevProps;
    const granularityChanged = granularity !== prevGranularity;
    const fromChanged = initRange.from.getTime() !== initRangePrev.from.getTime();
    const endChanged = initRange.to.getTime() !== initRangePrev.to.getTime();
    if ((fromChanged || endChanged || granularityChanged) && !oneDaySelectionAllowed) {
      const newRange = this.extendRangeByGranularity(initRange);
      this.updateSelection(newRange, true);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentRange: newRange });
      onRangeChange(newRange);
    }
    const { currentRange, today } = this.state;
    const currentExtendedRange = this.extendRangeByGranularity(currentRange);
    if (prevProps.marketplaceID === marketplaceID) return;
    const mkToday = getMkToday(marketplaceID, marketplaces, today);
    const updatedRange = getSafeMkRange(currentExtendedRange, mkToday);
    if (updatedRange) {
      this.updateSelection(updatedRange, true);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentRange: updatedRange });
      onRangeChange(updatedRange);
    }
  }

  extendRangeByGranularity = range => {
    if (!range) return range;
    const { granularity, weekStartOn, lastAvailable } = this.props;
    let extendedRange = null;
    if (granularity === DATE_PICKER_GRANULARITY_MODES.day) {
      extendedRange = range;
    }
    if (granularity === DATE_PICKER_GRANULARITY_MODES.week) {
      extendedRange = createRange(
        startOfWeek(range.from, { weekStartsOn: weekStartOn }),
        endOfWeek(range.to, { weekStartsOn: weekStartOn })
      );
    }
    if (granularity === DATE_PICKER_GRANULARITY_MODES.month) {
      extendedRange = createRange(startOfMonth(range.from), endOfMonth(range.to));
    }
    if (granularity === DATE_PICKER_GRANULARITY_MODES.quarter) {
      extendedRange = createRange(startOfQuarter(range.from), endOfQuarter(range.to));
    }
    if (granularity === DATE_PICKER_GRANULARITY_MODES.year) {
      extendedRange = createRange(startOfYear(range.from), endOfYear(range.to));
    }
    if (!extendedRange) throw new Error('Unknown granularity');
    if (isAfter(extendedRange.to, lastAvailable)) extendedRange.to = lastAvailable;
    if (isAfter(extendedRange.from, lastAvailable)) extendedRange.from = lastAvailable;
    return extendedRange;
  };

  togglePopUp = event => {
    const anchorEl = event?.currentTarget?.closest(`.${DATE_PICKER_DIALOG_ANCHOR_CLASS}`);
    this.setState({ today: new Date(), anchorEl, isSecondSelection: false });
  };

  componentDidMount = () => {
    const { initRange, oneDaySelectionAllowed, defaultCompareType } = this.props;
    this.changeCompareType(defaultCompareType);
    if (!oneDaySelectionAllowed) this.updateSelection(initRange, true);
  };

  changeCompareType = type => {
    const { initRange, lifeTime, onCompareRangeChange } = this.props;
    switch (type) {
      case PREVIOUS_PERIOD:
        this.setState({ compareType: PREVIOUS_PERIOD });
        onCompareRangeChange(getLimitedRangeByLifetime(getShiftedRange(initRange, true)));
        break;
      case PREVIOUS_YEAR:
        this.setState({ compareType: PREVIOUS_YEAR });
        onCompareRangeChange(
          getLimitedRangeByLifetime(
            createRange(subYears(initRange.from, 1), subYears(initRange.to, 1)),
            lifeTime
          )
        );
        break;
      case COMPARE_OFF:
        this.setState({ compareType: COMPARE_OFF });
        onCompareRangeChange(null);
        break;
      default:
        break;
    }
  };

  setSnippet = (key, update = true) => {
    const {
      lifeTime,
      onRangeChange,
      onCompareRangeChange,
      compareDateRange,
      lastAvailable
    } = this.props;
    const { today, compareType } = this.state;
    this.togglePopUp(false);

    const newRange = getRangeBySnippet(key, today, lifeTime, lastAvailable);
    this.setState({
      currentRange: newRange,
      currentSnippet: key,
      hideSelected: false
    });

    if (update && isObject(newRange)) {
      onRangeChange(newRange);
      if (compareDateRange) {
        onCompareRangeChange(
          getLimitedRangeByLifetime(
            compareType === PREVIOUS_PERIOD
              ? getShiftedRange(newRange, true)
              : createRange(subYears(newRange.from, 1), subYears(newRange.to, 1)),
            lifeTime
          )
        );
      }
    }
  };

  updateSelection = (data, isOnComponentMount = false, forceOnRangeChange = false) => {
    const { isSecondSelection, currentSnippet, currentRange, compareType, today } = this.state;
    const {
      lifeTime,
      onRangeChange,
      oneDaySelectionAllowed,
      onCompareRangeChange,
      compareDateRange,
      marketplaces,
      marketplaceID,
      singleClickMode,
      granularity,
      lastAvailable
    } = this.props;

    if (oneDaySelectionAllowed) {
      const newRange = createRange(data, data);
      this.setState({ currentRange: newRange });
      if (isAllYearSelected(newRange)) this.setState({ currentSnippet: snippetsList.thisYear });
      onRangeChange(newRange);
      if (compareDateRange) {
        onCompareRangeChange(
          getLimitedRangeByLifetime(
            compareType === PREVIOUS_PERIOD
              ? getShiftedRange(newRange, true)
              : createRange(subYears(newRange.from, 1), subYears(newRange.to, 1)),
            lifeTime
          )
        );
      }
      this.togglePopUp(false);
      return;
    }
    if (granularity !== DATE_PICKER_GRANULARITY_MODES.day) {
      if (!('from' in data && 'to' in data)) {
        if (isSecondSelection) {
          if (currentRange.from > data) {
            // eslint-disable-next-line no-param-reassign
            data = this.extendRangeByGranularity(createRange(data, currentRange.to));
          } else {
            // eslint-disable-next-line no-param-reassign
            data = this.extendRangeByGranularity(createRange(currentRange.from, data));
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          data = this.extendRangeByGranularity(createRange(data, data));
          this.setState({ currentRange: data });
        }
      }
      if (singleClickMode) {
        const newRange = this.extendRangeByGranularity(data);
        this.setState({ currentRange: newRange });
        if (!isOnComponentMount) onRangeChange(newRange);
        this.togglePopUp(false);
        return;
      }
    }
    const result = getNewSelection(
      data,
      currentRange,
      currentSnippet,
      lastAvailable || getMkToday(marketplaceID, marketplaces, today),
      lifeTime,
      isSecondSelection
    );
    this.setState({
      ...result,
      isSecondSelection: !isOnComponentMount ? !isSecondSelection : isSecondSelection,
      currentRange: result.range,
      hideSelected: true
    });
    if (isAllYearSelected(result.range)) this.setState({ currentSnippet: snippetsList.thisYear });
    if (!isOnComponentMount && (isSecondSelection || forceOnRangeChange)) {
      this.togglePopUp(false);
      onRangeChange(result.range);
      if (compareDateRange) {
        onCompareRangeChange(
          getLimitedRangeByLifetime(
            compareType === PREVIOUS_PERIOD
              ? getShiftedRange(result.range, true)
              : createRange(subYears(result.range?.from, 1), subYears(result.range?.to, 1)),
            lifeTime
          )
        );
      }
    }
  };

  getPresets = () => {
    const { granularity, presets } = this.props;
    switch (granularity) {
      case DATE_PICKER_GRANULARITY_MODES.day:
        return presets;
      case DATE_PICKER_GRANULARITY_MODES.week:
        return {
          last_4_weeks: 'Last 4 weeks',
          last_3_months: 'Last 3 months',
          last_6_months: 'Last 6 months',
          this_year: 'This Year',
          life_time: 'Lifetime'
        };
      case DATE_PICKER_GRANULARITY_MODES.month:
        return {
          last_6_months: 'Last 6 months',
          last_12_months: 'Last 12 months',
          last_24_months: 'Last 24 months',
          this_year: 'This Year',
          life_time: 'Lifetime'
        };
      case DATE_PICKER_GRANULARITY_MODES.quarter:
        return {
          last_4_quarters: 'Last 4 quarters',
          last_8_quarters: 'Last 8 quarters',
          last_16_quarters: 'Last 16 quarters',
          life_time: 'Lifetime'
        };
      case DATE_PICKER_GRANULARITY_MODES.year:
        return {
          last_2_years: 'Last 2 years',
          last_4_years: 'Last 4 years',
          life_time: 'Lifetime'
        };
      default:
        return presets;
    }
  };

  render() {
    const {
      lifeTime,
      initRange,
      compareDateRange,
      oneDaySelectionAllowed,
      className,
      isCompareEnable,
      isSelectWholeMonths,
      lastAvailable,
      marketplaceID,
      marketplaces,
      hideIntervalControl,
      widgetClassName,
      granularity
    } = this.props;

    if (!initRange) {
      return null;
    }

    const { currentSnippet, today, currentRange, anchorEl, hideSelected, compareType } = this.state;
    const lastAvailableDate = lastAvailable || getMkToday(marketplaceID, marketplaces, today);
    const rangeInput = <DatePickerWidgetContent range={initRange} today={today} />;
    const compareRangeInput = compareDateRange ? (
      <DatePickerWidgetContent range={compareDateRange} today={today} />
    ) : null;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const ComparePeriodButton = ({ onClick, checked, text, classes }) => (
      <FlatButton className={classNames('flex items-center', classes)} onClick={onClick}>
        <RadioIcon checked={checked} />
        <Typography className="text-sm ml-2 text-black">{text}</Typography>
      </FlatButton>
    );
    const compareOptions = [PREVIOUS_PERIOD, PREVIOUS_YEAR, COMPARE_OFF];
    const compareSwitcher = (
      <div className="px-4 flex flex-col justify-start items-start">
        <Typography className="text-center font-normal text-black">Compare to:</Typography>
        <div className="flex flex-wrap mt-2 mb-4">
          {compareOptions.map(option => (
            <ComparePeriodButton
              key={option}
              onClick={() => this.changeCompareType(option)}
              text={option}
              classes="mr-4 flex-shrink-0"
              checked={compareType === option}
            />
          ))}
        </div>
      </div>
    );
    return (
      <div
        className={classNames(
          'relative h-full flex items-center',
          DATE_PICKER_DIALOG_ANCHOR_CLASS,
          className
        )}
      >
        <div className="flex items-center w-full h-full" aria-describedby={id}>
          {compareDateRange ? (
            <div className="flex items-center w-full h-full">
              <FlatButton onClick={this.togglePopUp}>
                <CalendarTooltip {...this.props}>
                  <CalendarIcon
                    className="h-4 w-4 hidden xs:inline-block"
                    fill={Colors.gray.default}
                  />
                </CalendarTooltip>
              </FlatButton>
              <div className="xs:ml-4 flex items-center">
                <FlatButton onClick={this.togglePopUp}>
                  <p className="text-right text-black text-sm">{rangeInput}</p>
                  <p className="text-right text-black text-sm">{compareRangeInput}</p>
                </FlatButton>
                <p className="ml-2  text-black text-sm">vs</p>
              </div>
            </div>
          ) : (
            <FlatButton
              className="flex cursor-pointer h-8 items-center w-full h-full"
              onClick={this.togglePopUp}
            >
              <CalendarTooltip {...this.props}>
                <CalendarIcon
                  className="h-4 w-4 hidden xs:inline-block"
                  fill={Colors.gray.default}
                />
              </CalendarTooltip>

              <p className={widgetClassName}>{rangeInput}</p>
            </FlatButton>
          )}
          {!oneDaySelectionAllowed && !hideIntervalControl && (
            <IntervalControl
              range={initRange}
              lifeTime={lifeTime}
              today={today}
              mkToday={lastAvailableDate}
              updateSelection={data => {
                this.updateSelection(data, false, true);
              }}
            />
          )}
        </div>
        <Popover
          id={id}
          open={open}
          onClose={() => this.setState({ anchorEl: false })}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          PaperProps={{ className: isSmallSize() && 'left-0' }}
        >
          <div className="flex rounded justify-start bg-white items-stretch z-20 box-shadow-default">
            <div>
              <Calendar
                lastAvailableDate={lastAvailableDate}
                updateSelection={this.updateSelection}
                range={currentRange || initRange}
                lifeTime={lifeTime}
                oneDaySelectionAllowed={oneDaySelectionAllowed}
                isMonthsView={[
                  DATE_PICKER_GRANULARITY_MODES.month,
                  DATE_PICKER_GRANULARITY_MODES.quarter,
                  DATE_PICKER_GRANULARITY_MODES.year
                ].includes(granularity)}
              />
              {isCompareEnable && compareSwitcher}
              {isSelectWholeMonths && (
                <div className="ml-6 mb-4 text-xs, text-gray-dark">
                  Only whole months are available for selection
                </div>
              )}
            </div>
            {!oneDaySelectionAllowed && !isSelectWholeMonths && (
              <RangeList
                presets={this.getPresets()}
                currentSnippet={currentSnippet}
                setSnippet={this.setSnippet}
                hideSelected={hideSelected}
              />
            )}
          </div>
        </Popover>
      </div>
    );
  }
}

DatePicker.propTypes = {
  lifeTime: PropTypes.instanceOf(Date).isRequired,
  onRangeChange: PropTypes.func.isRequired,
  initRange: PropTypes.instanceOf(Object),
  lastAvailable: PropTypes.instanceOf(Date),
  oneDaySelectionAllowed: PropTypes.bool,
  className: PropTypes.string,
  defaultCompareType: PropTypes.string,
  onCompareRangeChange: PropTypes.func,
  singleClickMode: PropTypes.bool,
  presets: PropTypes.shape({
    [PropTypes.string]: PropTypes.string
  }),
  hideIntervalControl: PropTypes.bool,
  widgetClassName: PropTypes.string,
  granularity: PropTypes.string,
  weekStartOn: PropTypes.number
};

DatePicker.defaultProps = {
  initRange: {},
  oneDaySelectionAllowed: false,
  className: '',
  widgetClassName: 'xs:ml-4 text-black text-sm',
  lastAvailable: null,
  defaultCompareType: COMPARE_OFF,
  onCompareRangeChange: () => {},
  singleClickMode: false,
  presets: DATE_RANGE_PRESETS,
  hideIntervalControl: false,
  granularity: DATE_PICKER_GRANULARITY_MODES.day,
  weekStartOn: DEFAULT_WEEK_STARTS_ON
};

export default DatePicker;
