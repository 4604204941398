import { DECIMAL_SCALE, getNumber } from '@utils';
import { normalizeCasedString } from '@utils/format';
import { getCommonExpensesList, getExpensesDataWithSummary } from '../expenses/utils';
import { API_EXPENSES_TYPES } from '../expenses/consts';

export const sumOfSafeValues = list => list.reduce((acc, item) => acc + getNumber(item), 0);
export const getPercent = (value, sum, allowNegative) => {
  if (!sum) return 0;
  const percentage = (getNumber(value) / getNumber(sum)) * 100;
  if (allowNegative) return percentage;
  return Math.abs(percentage);
};

export const getTotalRefunds = totals =>
  sumOfSafeValues([
    totals.refunds,
    totals.referral_fee_reimbursement,
    totals.refund_commission,
    totals.reimbursement,
    totals.reimbursement_return,
    totals.reimbursed_inventory,
    totals.refunded_cogs,
    totals.other_refunds
  ]);

const getTotalCogs = totals => {
  const values = totals.cin7_cogs
    ? [totals.cin7_cogs, totals.fbm_shipping]
    : [totals.production_cost, totals.shipping_cost, totals.fbm_shipping];
  return sumOfSafeValues(values);
};

const getCalculatedMetrics = totals => {
  const sales = sumOfSafeValues([
    totals.product_sales,
    totals.discounts,
    totals.new_tax,
    totals.shipping
  ]);
  const totalRefunds = getTotalRefunds(totals);
  const totalAmazonExpenses = sumOfSafeValues([
    ...Object.values(totals.amazon_expenses || {}),
    totals.fba_fees,
    totals.referral_fees
  ]);
  const totalCustomExpenses = totals.custom_expenses?.reduce(
    (sum, item) => sum + item.range_amount,
    0
  );
  const totalAmazonAdvertising = sumOfSafeValues([
    totals.ads_products,
    totals.ads_brands,
    totals.ads_video,
    totals.ads_display
  ]);
  const totalAdvertising = sumOfSafeValues([totalAmazonAdvertising, totals.custom_advertising]);
  const acos = getPercent(totalAmazonAdvertising, totals.ads_sales);
  const tacos = getPercent(totalAdvertising, sales);
  const roas = totalAmazonAdvertising
    ? Math.abs(getNumber(totals.ads_sales) / getNumber(totalAmazonAdvertising)).toFixed(
        DECIMAL_SCALE
      )
    : 'n/a';
  const cogs = getTotalCogs(totals);
  const grossProfit = sumOfSafeValues([sales, totalRefunds, totalAdvertising, totalAmazonExpenses]);
  const netProfit = sumOfSafeValues([grossProfit, totalCustomExpenses, cogs, totals.new_tax * -1]);
  const roi = getPercent(netProfit, Math.abs(cogs), true);
  return {
    roi,
    netProfit,
    cogs,
    grossProfit,
    totalAdvertising,
    totalCustomExpenses,
    totalAmazonExpenses,
    totalRefunds,
    sales,
    acos,
    tacos,
    roas
  };
};

const getExpenseGroup = (title, data, hidden, getMetricView) => {
  const getExpandedRow = rowData => {
    const result = {
      ...rowData,
      title: normalizeCasedString(rowData.name) || rowData.category,
      ...getMetricView(rowData.range_amount)
    };
    if (result.children) result.children = result.children.map(getExpandedRow);
    return result;
  };

  const expensesData = getExpensesDataWithSummary(getCommonExpensesList(data));
  return {
    title,
    hidden,
    ...getMetricView(expensesData[expensesData.length - 1]?.range_amount),
    children: expensesData.slice(0, -1).map(getExpandedRow)
  };
};

export const ORDER_LIST_BREAKDOWN = 'ORDER_LIST_BREAKDOWN';

export const getPrepareNetProfitBreakdownDataFunc = breakdownType => (totals, isPercentage) => {
  const {
    netProfit,
    cogs,
    grossProfit,
    totalAdvertising,
    totalAmazonExpenses,
    totalRefunds,
    sales,
    roi
  } = getCalculatedMetrics(totals);

  const getMetricView = (value, allowNegativePercentage = false) => ({
    value: isPercentage ? getPercent(value, sales, allowNegativePercentage) : value,
    isPercentage
  });

  const isOrderBreakdown = breakdownType === ORDER_LIST_BREAKDOWN;

  const customExpenses = getExpenseGroup(
    'Other Expenses',
    totals.custom_expenses,
    breakdownType === ORDER_LIST_BREAKDOWN,
    getMetricView
  );

  const margin = sales ? (netProfit * 100) / sales : 0;

  return [
    {
      title: 'Sales',
      ...getMetricView(sales),
      children: [
        { title: 'Product Sales', ...getMetricView(totals.product_sales) },
        { title: 'Shipping', ...getMetricView(totals.shipping), optional: true },
        { title: 'Discounts', ...getMetricView(totals.discounts) },
        { title: 'Taxes', ...getMetricView(totals.new_tax), optional: true }
      ]
    },
    {
      title: 'Refunds',
      ...getMetricView(totalRefunds),
      children: [
        { title: 'Refunds', ...getMetricView(totals.refunds) },
        {
          title: 'Referral Fee Reimb.',
          ...getMetricView(totals.referral_fee_reimbursement)
        },
        { title: 'Refund Commission', ...getMetricView(totals.refund_commission) },
        { title: 'Reimbursement', ...getMetricView(totals.reimbursement) },
        {
          title: 'Reimbursement Rev.',
          ...getMetricView(totals.reimbursement_return),
          optional: true
        },
        {
          title: 'Reimbursement Inv.',
          ...getMetricView(totals.reimbursed_inventory),
          optional: true
        },
        { title: 'FBA Prod. Returns', ...getMetricView(totals.refunded_cogs), optional: true },
        { title: 'Other', ...getMetricView(totals.other_refunds), optional: true }
      ]
    },
    {
      title: 'Advertising',
      hidden: !!isOrderBreakdown,
      ...getMetricView(totalAdvertising),
      children: [
        {
          title: 'Custom Ad Spend',
          hidden: !!isOrderBreakdown,
          ...getMetricView(totals.custom_advertising),
          optional: true
        },
        { title: 'Ad Spend (Products)', ...getMetricView(totals.ads_products) },
        { title: 'Ad Spend (Brands)', ...getMetricView(totals.ads_brands) },
        { title: 'Ad Spend (Video)', ...getMetricView(totals.ads_video) },
        { title: 'Ad Spend (Display)', ...getMetricView(totals.ads_display) }
      ]
    },
    {
      title: 'Amazon Fees',
      ...getMetricView(totalAmazonExpenses),
      children: [
        { rootMetric: true, title: 'FBA Fee', ...getMetricView(totals.fba_fees) },
        { rootMetric: true, title: 'Referral Fee', ...getMetricView(totals.referral_fees) },
        {
          rootMetric: true,
          title: 'Coupon Payment Events',
          ...getMetricView(totals.amazon_expenses?.CouponPaymentEvent),
          optional: true
        },
        {
          rootMetric: true,
          title: 'Storage Fee',
          ...getMetricView(totals.amazon_expenses?.fba_sf),
          optional: true
        },
        {
          rootMetric: true,
          title: 'Long Term Storage Fee',
          ...getMetricView(totals.amazon_expenses?.fba_ltsf),
          optional: true
        },
        {
          title: 'Inbound Fees',
          ...getMetricView(
            sumOfSafeValues([
              totals.amazon_expenses?.FBAInboundTransportationFee,
              totals.amazon_expenses?.FBAInternationalInboundFreightTaxAndDuty,
              totals.amazon_expenses?.FBAInternationalInboundFreightFee,
              totals.amazon_expenses?.FBAInboundConvenienceFee
            ])
          ),
          children: [
            {
              title: 'FBA Inbound Transportation Fee',
              ...getMetricView(totals.amazon_expenses?.FBAInboundTransportationFee),
              optional: true
            },
            {
              title: 'FBA International Inbound Freight Tax & Duty',
              ...getMetricView(totals.amazon_expenses?.FBAInternationalInboundFreightTaxAndDuty),
              optional: true
            },
            {
              title: 'FBA International Inbound Freight Fee',
              ...getMetricView(totals.amazon_expenses?.FBAInternationalInboundFreightFee),
              optional: true
            },
            {
              title: 'FBA Inbound Convenience Fee',
              ...getMetricView(totals.amazon_expenses?.FBAInboundConvenienceFee),
              rootMetric: true,
              optional: true
            }
          ],
          optional: true
        },
        {
          title: 'FBA Inbound Transportation Program Fee',
          ...getMetricView(totals.amazon_expenses?.FBAInboundTransportationProgramFee),
          optional: true
        },
        {
          title: 'Other',
          ...getMetricView(
            sumOfSafeValues([
              totals.amazon_expenses?.FBADisposalFee,
              totals.amazon_expenses?.FBARemovalFee,
              totals.amazon_expenses?.Subscription,
              totals.amazon_expenses?.SellerDealPayment,
              totals.amazon_expenses?.SellerReviewEnrollmentPaymentEvent,
              totals.amazon_expenses?.FBAOverageFee
            ])
          ),
          children: [
            {
              title: 'FBA Disposal Fee',
              ...getMetricView(totals.amazon_expenses?.FBADisposalFee),
              optional: true
            },
            {
              title: 'FBA Removal Fee',
              ...getMetricView(totals.amazon_expenses?.FBARemovalFee),
              optional: true
            },
            {
              title: 'Subscription',
              ...getMetricView(totals.amazon_expenses?.Subscription),
              optional: true
            },
            {
              title: 'Seller Deal Payment',
              ...getMetricView(totals.amazon_expenses?.SellerDealPayment),
              optional: true
            },
            {
              title: 'Vine Enrollment',
              ...getMetricView(totals.amazon_expenses?.SellerReviewEnrollmentPaymentEvent),
              optional: true
            },
            {
              title: 'FBA Overage Fee',
              ...getMetricView(totals.amazon_expenses?.FBAOverageFee),
              optional: true
            }
          ],
          optional: true
        }
      ]
    },
    {
      title: 'CoGS',
      ...getMetricView(cogs),
      children: [
        { title: 'CIN7', ...getMetricView(totals.cin7_cogs), optional: true },
        { title: 'Production', ...getMetricView(totals.production_cost), hidden: totals.cin7_cogs },
        {
          title: 'Inbound shipping',
          ...getMetricView(totals.shipping_cost),
          hidden: totals.cin7_cogs
        },
        { title: 'FBM shipping', ...getMetricView(totals.fbm_shipping), optional: true }
      ]
    },
    customExpenses,
    {
      separator: true
    },
    { title: 'Net Profit', ...getMetricView(netProfit, true), profitColor: true },
    { title: 'Estimated Payout', ...getMetricView(grossProfit) },
    { title: 'Average Order Value', value: sales / totals.orders, hidden: !!isOrderBreakdown },
    {
      title: 'Margin',
      value: margin,
      isPercentage: true
    },
    {
      title: 'ROI',
      value: roi,
      isPercentage: true
    }
  ];
};

export const prepareSalesBreakdownData = (totals, isPercentage) => {
  let organicSales = getNumber(totals.sales) - getNumber(totals.ads_sales);
  if (organicSales < 0) organicSales = 0;
  const fullPriceSales = getNumber(totals.sales) - getNumber(totals.promo_sales);
  const getMetricView = value => ({
    value: isPercentage ? getPercent(value, totals.sales) : value,
    isPercentage
  });
  return [
    { title: 'Organic', ...getMetricView(organicSales) },
    { title: 'PPC', ...getMetricView(totals.ads_sales) },
    { separator: true },
    { title: 'Promo', ...getMetricView(totals.promo_sales) },
    { title: 'Full Price', ...getMetricView(fullPriceSales) },
    { separator: true },
    { title: 'Total Sales', value: totals.sales }
  ];
};

const CUSTOM_ADVERTISING_TITLE = 'Custom Ad Spend';

export const prepareAdvertisingBreakdownData = totals => {
  const getCustomAdvertising = () => {
    const groupedCustomAdvertising = getExpenseGroup(
      '',
      totals.customAdvertisingExpenses,
      false,
      value => ({ value })
    );
    if (!groupedCustomAdvertising?.children?.length) {
      return { title: CUSTOM_ADVERTISING_TITLE, rootMetric: true };
    }
    const [customAdvertising] = groupedCustomAdvertising.children;
    customAdvertising.title = CUSTOM_ADVERTISING_TITLE;
    return customAdvertising;
  };
  const amazonAdSpend = sumOfSafeValues([
    totals.ads_products,
    totals.ads_brands,
    totals.ads_video,
    totals.ads_display
  ]);
  return [
    {
      title: 'Amazon Ad Spend',
      value: amazonAdSpend,
      children: [
        {
          title: 'All Ad types',
          value: amazonAdSpend,
          children: [
            { title: 'Products', value: totals.ads_products },
            { title: 'Brands', value: totals.ads_brands },
            { title: 'Video', value: totals.ads_video },
            { title: 'Display', value: totals.ads_display }
          ]
        },
        {
          title: 'Impressions',
          value: totals.impressions,
          integer: true,
          alwaysExpanded: true,
          children: [
            {
              title: 'CPM',
              value:
                getNumber(totals.impressions) &&
                amazonAdSpend / (getNumber(totals.impressions) / 1000),
              integer: true
            }
          ]
        },
        {
          title: 'Clicks',
          value: totals.clicks,
          integer: true,
          alwaysExpanded: true,
          children: [
            {
              title: 'CTR',
              value:
                getNumber(totals.impressions) &&
                getNumber(totals.clicks) / getNumber(totals.impressions),
              isPercentage: true
            },
            { title: 'CPC', value: amazonAdSpend / totals.clicks }
          ]
        },
        {
          title: 'Orders',
          value: totals.ppc_orders,
          integer: true,
          alwaysExpanded: true,
          children: [
            {
              title: 'CVR',
              value: getPercent(totals.ppc_orders, totals.clicks),
              isPercentage: true
            },
            {
              title: 'CPA',
              value:
                getNumber(totals.ppc_orders) &&
                getNumber(amazonAdSpend) / getNumber(totals.ppc_orders)
            }
          ]
        },
        { title: 'Ad Sales', value: totals.ads_sales, rootMetric: true }
      ]
    },
    getCustomAdvertising(),
    { separator: true },
    { title: 'ROAS', value: totals.roas, tooltip: 'Excluding Custom Ad Spend' },
    {
      title: 'ACoS',
      value: totals.acos,
      isPercentage: true,
      tooltip: 'Excluding Custom Ad Spend'
    },
    {
      title: 'TACoS',
      value: totals.tacos,
      isPercentage: true,
      tooltip: 'For Amazon + Custom Ad Spend'
    }
  ];
};

const extractCustomAdvertising = customExpenses => {
  if (!customExpenses?.length) return { customExpenses: [], customAdvertisingTotal: 0 };
  return customExpenses.reduce(
    (result, item) => {
      if (item.type === API_EXPENSES_TYPES.advertising) {
        // eslint-disable-next-line no-param-reassign
        result.totalCustomAdvertising += item.range_amount;
        result.customAdvertisingExpenses.push(item);
      } else {
        result.customExpenses.push(item);
      }
      return result;
    },
    { customExpenses: [], customAdvertisingExpenses: [], totalCustomAdvertising: 0 }
  );
};

export const getTotalsForBreakdown = (totals, forTotalsView) => {
  const {
    customExpenses,
    customAdvertisingExpenses,
    totalCustomAdvertising
  } = extractCustomAdvertising(totals.custom_expenses);
  const totalsWithFullAmazonExpenses = {
    ...totals,
    custom_expenses: customExpenses,
    custom_advertising: totalCustomAdvertising,
    customAdvertisingExpenses
  };
  const {
    roi,
    netProfit,
    totalRefunds,
    sales,
    totalAdvertising,
    acos,
    tacos,
    roas
  } = getCalculatedMetrics(totalsWithFullAmazonExpenses);
  const result = {
    ...totalsWithFullAmazonExpenses,
    sales,
    profit: netProfit,
    total_ads: totalAdvertising,
    total_refunds: totalRefunds,
    acos,
    tacos,
    roas,
    roi
  };
  if (forTotalsView) result.refunds = totalRefunds;
  return result;
};
