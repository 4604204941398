import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { dateFromStr, getNumber, TABLE_CELL_FORMAT } from '@utils';
import { getFormattedValue } from '@utils/format';
import ModernBreakdown from '@components/modern-breakdown';
import {
  getTotalRefunds,
  getPrepareNetProfitBreakdownDataFunc,
  prepareSalesBreakdownData,
  prepareAdvertisingBreakdownData
} from '../prepareModernBreakdownData';
import { DashedUnderlineWrapper } from '@components';

const noneView = <div className="text-gray-dark text-sm">none</div>;

export const detailedView = (
  value,
  secondRowData,
  secondRowTitle,
  thirdRowTitle,
  data,
  columnIndex,
  key,
  rowIndex
) => {
  const firstRowValue = data[rowIndex][value];
  let secondRowValue = data[rowIndex][secondRowData];
  secondRowValue = secondRowValue || 0;
  const thirdRowValue = firstRowValue - secondRowValue;

  const formattedValue = firstRowValue ? getFormattedValue(firstRowValue) : 'none';
  const thirdRowFormattedValue =
    thirdRowValue && thirdRowValue > 0 ? getFormattedValue(thirdRowValue) : 'none';
  const secondRowFormattedValue = secondRowValue ? getFormattedValue(secondRowValue) : 'none';

  return (
    <div className="flex flex-col items-end">
      {formattedValue}
      <div className="text-gray-dark text-xs float-right mt-px">
        {thirdRowTitle}: {thirdRowFormattedValue}
      </div>
      <div className="text-gray-dark text-xs float-right mt-px">
        {secondRowTitle}: {secondRowFormattedValue}
      </div>
    </div>
  );
};

const detailedView2 = (
  firstRowField,
  secondRowField,
  firstSubRowTitle,
  secondSubRowTitle,
  data,
  columnIndex,
  key,
  rowIndex
) => {
  const firstRowValue = data[rowIndex][firstRowField] || 0;
  const secondRowValue = data[rowIndex][secondRowField] || 0;
  let summary = firstRowValue + secondRowValue;

  summary = summary ? getFormattedValue(summary) : 'none';
  const firstSubRowFormattedValue = firstRowValue ? getFormattedValue(firstRowValue) : 'none';
  const secondSubRowFormattedValue = secondRowValue ? getFormattedValue(secondRowValue) : 'none';

  return (
    <div className="flex flex-col items-end">
      {summary}
      <div className="text-gray-dark text-xs float-right mt-px">
        {firstSubRowTitle}: {firstSubRowFormattedValue}
      </div>
      <div className="text-gray-dark text-xs float-right mt-px">
        {secondSubRowTitle}: {secondSubRowFormattedValue}
      </div>
    </div>
  );
};

const detailedView3 = (
  firstRowField,
  secondRowField,
  thirdRowField,
  firstSubRowTitle,
  secondSubRowTitle,
  thirdSubRowTitle,
  data,
  columnIndex,
  key,
  rowIndex
) => {
  const firstRowValue = data[rowIndex][firstRowField] || 0;
  const secondRowValue = data[rowIndex][secondRowField] || 0;
  const thirdRowValue = data[rowIndex][thirdRowField] || 0;

  const firstSubRowFormattedValue = firstRowValue ? getFormattedValue(firstRowValue) : 'none';
  const secondSubRowFormattedValue = secondRowValue ? getFormattedValue(secondRowValue) : 'none';
  const thirdSubRowFormattedValue = thirdRowValue ? getFormattedValue(thirdRowValue) : 'none';

  return (
    <div className="flex flex-col items-end">
      <div className="float-right ">{firstSubRowFormattedValue}</div>
      <div className="text-gray-dark text-xs float-right ">
        {secondSubRowTitle}: {secondSubRowFormattedValue}
      </div>
      <div className="text-gray-dark text-xs float-right ">
        {thirdSubRowTitle}: {thirdSubRowFormattedValue}
      </div>
    </div>
  );
};

export const ordersView = (data, columnIndex, key, rowIndex) => {
  return detailedView('orders', 'ppc_orders', 'PPC', 'Organic', data, columnIndex, key, rowIndex);
};

export const productSaleView = (data, columnIndex, key, rowIndex) => {
  return detailedView(
    'product_sales',
    'ad_sales',
    'Ads',
    'Organic',
    data,
    columnIndex,
    key,
    rowIndex
  );
};

export const adSpendView = (data, columnIndex, key, rowIndex) => {
  return detailedView3(
    'ad_spend',
    'ads_products',
    'ads_display',
    'Total',
    'Products',
    'Display',
    data,
    columnIndex,
    key,
    rowIndex
  );
};

export const feesView = (data, columnIndex, key, rowIndex) => {
  return detailedView3(
    'total_fees',
    'fba_fees',
    'referral_fees',
    'Total',
    'FBA',
    'Referral',
    data,
    columnIndex,
    key,
    rowIndex
  );
};

export const acosView = (data, columnIndex, key, rowIndex) => {
  const { acos, tacos } = data[rowIndex];
  if (!acos && !tacos) return <div className="text-gray-dark">none</div>;
  return (
    <div className="flex flex-col items-end">
      {getFormattedValue(data[rowIndex].acos)}
      <div className="text-gray-dark text-xs float-right ">
        TACoS: {getFormattedValue(data[rowIndex].tacos)}
      </div>
    </div>
  );
};

export const unitsView = (data, columnIndex, key, rowIndex) => {
  return detailedView('units', 'promo_units', 'Promo', 'Full', data, columnIndex, key, rowIndex);
};

export const inventoryView = (data, columnIndex, key, rowIndex) => {
  return detailedView2('fba', 'fbm', 'FBA', 'FBM', data, columnIndex, key, rowIndex);
};

export const getRefundsView = (quantityKey = 'qty_refunded', isUnitEconomicsView) => (
  data,
  columnIndex,
  key,
  rowIndex
) => {
  const refunds = getTotalRefunds(data[rowIndex]);
  const { units } = data[rowIndex];
  const refundsValue = getFormattedValue(refunds, true);
  const qtyRefunded = data[rowIndex]?.[quantityKey];
  if (isUnitEconomicsView) {
    if (!refunds || !units) return noneView;
    return <div>{getFormattedValue(units && refunds / units)}</div>;
  }
  const content = qtyRefunded ? (
    <>
      <div>{refundsValue}</div>
      <div className="text-gray-dark text-xs ">
        {qtyRefunded} unit{qtyRefunded > 1 ? 's' : ''}
      </div>
      {!!units && (
        <div className="text-gray-dark text-xs ">
          {getFormattedValue((qtyRefunded / units) * 100)}%
        </div>
      )}
    </>
  ) : (
    <div className="text-gray-dark text-sm ">none</div>
  );

  return <div className="flex flex-col items-end">{content}</div>;
};

export const conversionRateView = (data, columnIndex, key, rowIndex) => {
  const { orders } = data[rowIndex];
  const { sessions } = data[rowIndex];
  const content = sessions && orders ? (orders * 100) / sessions : 0;
  const formattedValue = getFormattedValue(content, true);
  return <div className="flex">{formattedValue}</div>;
};

const breakdownCommonProps = {
  offset: '0px,8px',
  placement: 'bottom-end'
};

export const getNewProfitView = isUnitEconomicsView => (
  data,
  columnIndex,
  key,
  rowIndex,
  newStyle,
  rowClicked,
  selectedParents,
  extraCellProps,
  setKeyword,
  columns,
  currency
) => {
  const product = data[rowIndex];
  const netProfit = product.profit;
  const { units } = product;
  if (netProfit === undefined) return noneView;
  const rootClass = classNames('flex flex-row items-start', {
    'text-green-dark': netProfit > 0,
    'text-red': netProfit < 0,
    'text-gray-dark': netProfit === 0
  });
  if (isUnitEconomicsView) {
    if (!units) return noneView;
    return <div className={rootClass}>{getFormattedValue(units && netProfit / units)}</div>;
  }
  const buttonContent = (
    <DashedUnderlineWrapper tooltipContent="Click to view breakdown">
      {getFormattedValue(netProfit, true)}
    </DashedUnderlineWrapper>
  );
  return (
    <div className={rootClass}>
      <div className="flex items-center">
        <ModernBreakdown
          {...breakdownCommonProps}
          currency={currency}
          getData={callback => callback(product)}
          buildViewData={getPrepareNetProfitBreakdownDataFunc()}
          headerTitle="Net Profit Breakdown"
          buttonClassName={rootClass}
          buttonContent={buttonContent}
        />
      </div>
    </div>
  );
};

export const DateCell = (data, columnIndex, key, rowIndex) => {
  const { date } = data[rowIndex];
  return (
    <div className="flex flex-col text-sm text-gray-darkest">
      <div>{format(dateFromStr(date), TABLE_CELL_FORMAT.date)}</div>
    </div>
  );
};

const getFormattedView = (value, useDecimalScale = true) =>
  value ? getFormattedValue(value, useDecimalScale) : 'none';

export const getSalesCell = isUnitEconomicsView => (
  data,
  columnIndex,
  key,
  rowIndex,
  newStyle,
  rowClicked,
  selectedParents,
  extraCellProps,
  setKeyword,
  columns,
  currency
) => {
  const product = { ...data[rowIndex] };
  const { units } = product;
  if (isUnitEconomicsView && units) {
    product.sales /= units;
    product.ads_sales /= units;
    product.promo_sales /= units;
  }
  const { sales, ads_sales: ppcSales } = product;
  const organicSales = getNumber(sales) - getNumber(ppcSales);
  const firstRow = (
    <DashedUnderlineWrapper tooltipContent="Click to view breakdown">
      <div className="text-gray-dark-darkest">{getFormattedView(sales)}</div>
    </DashedUnderlineWrapper>
  );
  const subRowsClasses =
    'text-gray-dark text-xs flex items-center justify-start flex-no-wrap mt-px';
  return (
    <div className="w-full h-full flex flex-col items-end">
      <ModernBreakdown
        {...breakdownCommonProps}
        getData={callback => callback(product)}
        buildViewData={prepareSalesBreakdownData}
        currency={currency}
        headerTitle="Sales Breakdown"
        buttonClassName="rounded"
        buttonContent={firstRow}
      />
      <div className={subRowsClasses}>
        <p className="mr-1">Organic:</p>
        {getFormattedView(organicSales < 0 ? null : organicSales)}
      </div>
      <div className={subRowsClasses}>
        <p className="mr-1">PPC:</p>
        {getFormattedView(ppcSales)}
      </div>
    </div>
  );
};

export const getTotalAdvertisingView = isUnitEconomicsView => (
  data,
  columnIndex,
  key,
  rowIndex
) => {
  const product = data[rowIndex];
  const { units } = product;
  const totalAdvertising = product.total_ads;
  if (isUnitEconomicsView) {
    if (!totalAdvertising || !units) return noneView;
    return <div>{getFormattedValue(units && totalAdvertising / units, true)}</div>;
  }
  const content = totalAdvertising ? getFormattedValue(totalAdvertising, true) : noneView;
  const buttonContent = (
    <DashedUnderlineWrapper tooltipContent="Click to view breakdown">
      {content}
    </DashedUnderlineWrapper>
  );
  return (
    <div className="flex justify-end items-start">
      <ModernBreakdown
        {...breakdownCommonProps}
        buildViewData={prepareAdvertisingBreakdownData}
        headerTitle="Advertising Breakdown"
        disablePercentageView
        buttonContent={buttonContent}
        getData={callback => callback(product)}
        buttonClassName="ml-0"
      />
    </div>
  );
};

export const roiView = (data, columnIndex, key, rowIndex) => {
  const product = data[rowIndex];
  const { roi } = product;
  if (!roi) return noneView;
  return <div>{getFormattedValue(roi, true)}</div>;
};

export const getSessionsView = isUnitEconomicsView => (data, columnIndex, key, rowIndex) => {
  const product = { ...data[rowIndex] };
  let { sessions, mobile_sessions: mobileSessions, browser_sessions: browserSessions } = product;

  const { units } = product;
  if (isUnitEconomicsView && units) {
    sessions /= units;
    mobileSessions /= units;
    browserSessions /= units;
  }

  const subRowsClasses =
    'text-gray-dark text-xs flex items-center justify-start flex-no-wrap mt-px';
  return (
    <div className="w-full h-full flex flex-col items-end">
      <div className="text-gray-dark-darkest">{getFormattedView(sessions, false)}</div>
      <div className={subRowsClasses}>
        <p className="mr-1">Browser:</p>
        {getFormattedView(browserSessions, false)}
      </div>
      <div className={subRowsClasses}>
        <p className="mr-1">Mobile:</p>
        {getFormattedView(mobileSessions, false)}
      </div>
    </div>
  );
};

export const getSessionsViewWrapper = (data, columnIndex, key, rowIndex) => {
  return getSessionsView(false)(data, columnIndex, key, rowIndex);
};
