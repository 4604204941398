import React from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';
import { WebImg } from '@components';
import featureWebImgs from './img/main-features';
import { FEATURES_ANCHOR } from './HomepageHeader';

const featuresList = [
  {
    img: featureWebImgs.il_feature3,
    title: 'Track Your Amazon Sales & Profit - In Real Time',
    description:
      'Instantly see your Amazon sales and profit in your personal dashboard. We will display your financial metrics like Sales, Profit, PPC Spend, Amazon fees, ROI, Refunds and other key numbers.'
  },
  {
    img: featureWebImgs.il_feature10,
    title: 'See what keywords drive your ORGANIC Amazon traffic and sales',
    description:
      'Ever wondered where your sales on Amazon come from? Now you can find out! Search Query Performance Data is available in Seller Metrix, in the most accessible, easy to use dashboard yet.'
  },
  {
    img: featureWebImgs.il_feature8,
    title: 'Email Automation - Get More Amazon Product Reviews',
    description: `Follow-up with your customers by sending automated emails. Or use the "Request Review" feature - leveraging Amazon's internal communication system. This is fully automated and ToS compliant. You can send unlimited emails with any Seller Metrix plan.`,
    reduceHeightMobile: true
  },
  {
    img: featureWebImgs.il_feature2,
    title: 'Amazon Seller App - Your Analytics On The Go',
    description:
      'Seller Metrix comes with a free mobile app - so you can check your Amazon sales wherever you are! Your sales data is updated every 3 minutes so you’ll never miss a sale. The app is available for both, Android and iOS.'
  },
  {
    img: featureWebImgs.il_feature1,
    title: 'Global Dashboard - All Data in One Place',
    description:
      'Connect multiple marketplaces and see your worldwide sales and profit in one global dashboard - in your preferred currency. You can add unlimited Seller Central accounts at no additional cost.'
  },
  {
    img: featureWebImgs.il_feature4,
    title: 'Track your Amazon Products Individually',
    description:
      'In Seller Metrix you can also view your sales per ASIN - so you can easily identify your best (and worst) performing products and take appropriate action.'
  },
  {
    img: featureWebImgs.il_feature9,
    title: 'Product Listing Traffic & Conversion Rate',
    description:
      'See if potential customers are visiting your Amazon product listings and if they convert into buyers. These are some of the most important metrics for Amazon sellers as it impacts your product ranking on Amazon.',
    reduceHeightMobile: true
  },
  {
    img: featureWebImgs.il_feature6,
    title: 'Cha-ching! Get notified on new Amazon orders',
    description:
      'Get notified via the iOS or Android application when you receive new orders on Amazon. This feature is optional in case you have too many orders.'
  },
  {
    img: featureWebImgs.il_feature7,
    title: 'Stay on top of your Amazon Refunds',
    description:
      'Detailed insights into each FBA refund like the reason, customer comment, return status, and much more. You can also find orders that are not returned to Amazon and request for them to be reimbursed. Amazon may owe you money!'
  },
  {
    img: featureWebImgs.il_feature5,
    title: 'Tailormade Solution for Agencies',
    description:
      'All the features and tools you need to manage and grow your clients. Explore the Agency Dashboard where you will find all your clients separated with the ability to analyze each individual client and their performance.'
  }
];

function MainFeatures() {
  return (
    <Element name={FEATURES_ANCHOR}>
      <div className="flex justify-center mt-4 md:mt-0">
        <div className="w-11/12">
          {featuresList.map((value, index) => {
            const isNumberEven = index % 2 === 0;

            return (
              <div
                key={value.title}
                className={classNames('flex justify-between flex-col-reverse items-center', {
                  'mt-16 md:mt-32': index !== 0,
                  'md:flex-row': !isNumberEven,
                  'md:flex-row-reverse': isNumberEven
                })}
              >
                <div
                  className={classNames('inline-flex flex-col justify-start', {
                    'md:pl-12': isNumberEven,
                    'md:pr-12': !isNumberEven
                  })}
                >
                  <h2 className="text-black text-2xl md:text-3xl lg:text-4xl font-bold tracking-tight">
                    {value.title}
                  </h2>
                  <p className="text-gray-dark text-lg md:text-lg lg:text-2xl mt-4 md:mt-6 tracking-tight">
                    {value.description}
                  </p>
                </div>
                <WebImg
                  className={classNames(
                    'md:w-1/2 md:sm-h-480px mb-6 md:mb-0 flex justify-center items-center flex-shrink-0',
                    { 'w-1/2': value.reduceHeightMobile, 'w-3/4': !value.reduceHeightMobile }
                  )}
                  imgClassName="max-h-full"
                  {...value.img}
                  alt="feature"
                />
              </div>
            );
          })}
        </div>
      </div>
    </Element>
  );
}

export default MainFeatures;
