import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { compareFlatArray } from '@utils';
import { BookmarksSidebar } from 'seller/containers/bookmark-search';
import { setInput, setSelectedProducts as setSelectedProductsAction } from '../../store/actions';
import SearchWidget from './SearchWidget';

const SearchWidgetContainer = props => {
  const {
    productSearch,
    setProductSearchInput,
    setSelectedProducts,
    nonSidebarMode,
    hideBookmarksSidebar
  } = props;

  const searchWidget = productSearch.active ? (
    <SearchWidget
      nonSidebarMode={nonSidebarMode}
      data={productSearch.products}
      selectedData={productSearch.selectedProducts}
      open={productSearch.active}
      loading={productSearch.loading}
      keyword={productSearch.keyword}
      onCommitSelection={newSelectedProducts => {
        setProductSearchInput('');
        const { selectedProducts } = productSearch;
        const newIds = newSelectedProducts.map(i => i.id);
        const oldIds = selectedProducts.map(i => i.id);
        if (compareFlatArray(newIds, oldIds)) return;
        setSelectedProducts(newSelectedProducts);
      }}
      onCancel={() => setProductSearchInput('')}
    />
  ) : null;

  return (
    <>
      {searchWidget}
      {!hideBookmarksSidebar && <BookmarksSidebar />}
    </>
  );
};

SearchWidgetContainer.propTypes = {
  productSearch: PropTypes.instanceOf(Object).isRequired,
  setProductSearchInput: PropTypes.func.isRequired,
  setSelectedProducts: PropTypes.func.isRequired
};

SearchWidgetContainer.defaultProps = {
  secondaryMetric: null,
  secondaryMetricsData: null,
  disabledChartInterval: null
};

function mapStateToProps(state) {
  return {
    productSearch: state.productSearch
  };
}

export default connect(
  mapStateToProps,
  {
    setProductSearchInput: setInput,
    setSelectedProducts: setSelectedProductsAction
  }
)(SearchWidgetContainer);
