import API from '@sm/api';
import mock from '../mock';

const products = [
  {
    asin: 'B01M9C38BS',
    marketplace: 3,
    listing_price: 5.95,
    shipping_price: 0,
    unit_shipping_cost: 1,
    unit_cost: 1,
    smallImage: 'https://m.media-amazon.com/images/I/31lQHB51apL._SL75_.jpg',
    sku: 'IK-IZJF-1APP'
  },
  {
    asin: 'B01LVWG3WW',
    marketplace: 4,
    name:
      'InstaShaker (New Model) Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable. Complimentary Powder Storage Compartment',
    listing_price: 59.95,
    shipping_price: 10,
    unit_shipping_cost: 2,
    unit_cost: 8,
    smallImage: 'http://ecx.images-amazon.com/images/I/51j9zN-WyFL._SL75_.jpg',
    sku: 'LP-PDOY-8FHZ'
  },
  {
    asin: 'B01MFBKZLD',
    marketplace: 1,
    name:
      'Activene Plantar Fasciitis Socks - Foot Sleeves. Extra Compression for Heels and Arch Support. Made for Heel Spur, Achilles Tendon, Pain and Swelling Relief. 1 Pair (2 Socks) with Gift Box (Small)',
    listing_price: 29.95,
    shipping_price: 0,
    unit_shipping_cost: 1.2,
    unit_cost: 2.95,
    smallImage: 'http://ecx.images-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg',
    sku: 'OQ-AXJY-88OQ'
  },
  {
    asin: 'B01MQ0HQJT',
    marketplace: 3,
    name:
      'Activene Plantar Fasciitis Socks - Foot Sleeves. Extra Compression for Heels and Arch Support. Made for Heel Spur, Achilles Tendon, Pain and Swelling Relief. 1 Pair (2 Socks) with Gift Box (Large)',
    listing_price: 29.95,
    shipping_price: 0,
    unit_shipping_cost: 1.2,
    unit_cost: 2.95,
    smallImage: 'http://ecx.images-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg',
    sku: 'XG-4C08-H7NS'
  },
  {
    asin: 'B01DIPDEVU',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: null,
    unit_shipping_cost: 1,
    unit_cost: 2,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: 'DS-JER9-RXFW'
  },
  {
    asin: 'B012A5Z89K',
    marketplace: 3,
    name:
      'Activene Arnica Gel Cream - with Menthol and MSM. Pain Relief for Joint, Tendon, Muscle Ache. Chosen by Sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Pain 3 Ounce',
    listing_price: 29.95,
    shipping_price: 0,
    unit_shipping_cost: 0.08,
    unit_cost: 3.9,
    smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
    sku: 'UF-KPKM-TMSZ'
  },
  {
    asin: 'B06XKB2P5D',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: null,
    unit_shipping_cost: null,
    unit_cost: null,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: '7U-1FX7-AXZF'
  },
  {
    asin: 'B01A56KO40',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: null,
    unit_shipping_cost: 1,
    unit_cost: 2,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: 'GU-M35D-NQ55'
  },
  {
    asin: 'B06XJXY88T',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: null,
    unit_shipping_cost: null,
    unit_cost: null,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: 'V2-7PLS-3EL0'
  },
  {
    asin: 'B07JH2FNKR',
    marketplace: 3,
    name:
      "ZEGARO Digital Alarm Clock for Bedroom - 2.6'' LCD Display with Blue LED Backlight Light & Small, Big Numbers, Battery Operated | Easy to Use for Kids Girls Boys Teens & Elderly (Cloud White)",
    listing_price: 19.95,
    shipping_price: 0,
    unit_shipping_cost: 0.45,
    unit_cost: 2.35,
    smallImage: 'http://ecx.images-amazon.com/images/I/41iALlWDxnL._SL75_.jpg',
    sku: 'EBC01-CW'
  },
  {
    asin: 'B07JJKYZK3',
    marketplace: 3,
    name:
      "ZEGARO Digital Alarm Clock for Bedroom - 2.6'' LCD Display with Blue LED Backlight Light & Small, Big Numbers, Battery Operated | Easy to Use for Kids Girls Boys Teens & Elderly (Midnight Black)",
    listing_price: 19.95,
    shipping_price: 0,
    unit_shipping_cost: 0.45,
    unit_cost: 2.35,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kw60htgsL._SL75_.jpg',
    sku: 'EBC01-MB'
  },
  {
    asin: 'B07JHYT5VH',
    marketplace: 3,
    name:
      "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | Black",
    listing_price: 29.95,
    shipping_price: 0,
    unit_shipping_cost: 0.55,
    unit_cost: 4.1,
    smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
    sku: 'EBC02-MB'
  },
  {
    asin: 'B07JJGXVN5',
    marketplace: 3,
    name:
      "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
    listing_price: 29.95,
    shipping_price: 0,
    unit_shipping_cost: 0.55,
    unit_cost: 4.1,
    smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
    sku: 'EBC02-CW'
  },
  {
    asin: 'B01N95SOK8',
    marketplace: 3,
    name: 'InstaShaker Motor - Spare Part',
    listing_price: 9.95,
    shipping_price: null,
    unit_shipping_cost: null,
    unit_cost: null,
    smallImage: 'http://ecx.images-amazon.com/images/I/41BRNqG96zL._SL75_.jpg',
    sku: 'N8-D7N8-TU9J'
  },
  {
    asin: 'B06WGRZ4PP',
    marketplace: 3,
    name: 'InstaShaker Charging Cable - Spare Part',
    listing_price: 6.95,
    shipping_price: 0,
    unit_shipping_cost: 1,
    unit_cost: 1,
    smallImage: 'http://ecx.images-amazon.com/images/I/31yha9dRJ7L._SL75_.jpg',
    sku: '3G-3YDC-X6WX'
  },
  {
    asin: 'B01DIPDEF6',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: 0,
    unit_shipping_cost: 1,
    unit_cost: 2,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: '92-RSE4-MOHH'
  },
  {
    asin: 'B01A56KO3G',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: 0,
    unit_shipping_cost: 1,
    unit_cost: 2,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: 'N1-HU9D-Z45B'
  },
  {
    asin: 'B01A56KO5O',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: 0,
    unit_shipping_cost: 1,
    unit_cost: 2,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: 'N1-HU9E-Z45B'
  },
  {
    asin: 'B01MDQ5QEK',
    marketplace: 3,
    name:
      'Activene Plantar Fasciitis Socks - Foot Sleeves. Extra Compression for Heels and Arch Support. Made for Heel Spur, Achilles Tendon, Pain and Swelling Relief. 1 Pair (2 Socks) with Gift Box (Medium)',
    listing_price: 29.95,
    shipping_price: 0,
    unit_shipping_cost: 1.2,
    unit_cost: 2.95,
    smallImage: 'http://ecx.images-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg',
    sku: 'P1-ZQQA-PYUK'
  },
  {
    asin: 'B06XKGHB3G',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: null,
    unit_shipping_cost: null,
    unit_cost: null,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: 'EN-PMVB-7UCL'
  },
  {
    asin: 'B06XKHY2B2',
    marketplace: 3,
    name:
      'Knee Support Sleeve for CrossFit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: null,
    unit_shipping_cost: null,
    unit_cost: null,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: 'XU-PQ93-VZ96'
  },
  {
    asin: 'B014K05KAA',
    marketplace: 3,
    name:
      'Rechargeable Book Light for Glasses. Led Reading Light. Clip on Design Made in Germany. Complimentary Protective Case Included',
    listing_price: 39.95,
    shipping_price: 0,
    unit_shipping_cost: 0,
    unit_cost: 9.68,
    smallImage: 'http://ecx.images-amazon.com/images/I/41UQJTpiOoL._SL75_.jpg',
    sku: 'PI-6HD2-PV2T'
  },
  {
    asin: 'B078RNS6BJ',
    marketplace: 3,
    name:
      'Activene Dry Skin Repair Cream - All-Purpose Moisturizing Formula with 12 Powerful Natural Ingredients to Soothe and Hydrate Very Dry, Chapped Skin (3 oz). Paraben Free (Naturally Scented)',
    listing_price: 29.95,
    shipping_price: 0,
    unit_shipping_cost: 0.08,
    unit_cost: 4.54,
    smallImage: 'http://ecx.images-amazon.com/images/I/41ut7k7rRAL._SL75_.jpg',
    sku: 'UA-91K9-KICJ'
  },
  {
    asin: 'B01A56KO86',
    marketplace: 3,
    name:
      'Knee Support Sleeve for Crossfit - Squats, Weight Lifting Powerlifting. 7 mm Compression Knee Brace for Men and Women. 2 Sleeves (Pair) or Single Sleeve. Complimentary Leg Exercises eBook',
    listing_price: 29.9,
    shipping_price: 0,
    unit_shipping_cost: 1,
    unit_cost: 2,
    smallImage: 'http://ecx.images-amazon.com/images/I/41kV2dsnKPL._SL75_.jpg',
    sku: '6I-T5YP-GQSV'
  },
  {
    asin: 'B07F87FDG7',
    marketplace: 3,
    name:
      'Cedergren Hot Towel Warmer Cabinet - Stainless Steel Wet Towel Steamer (7 Liters). Mini Cabbie for Barber Shop, Massage, SPA, Nails and Beauty Parlor, Hair Salon. UL Certified Cabi',
    listing_price: 329,
    shipping_price: 0,
    unit_shipping_cost: 10,
    unit_cost: 50,
    smallImage: 'http://ecx.images-amazon.com/images/I/41jsXv3xlsL._SL75_.jpg',
    sku: 'ML-4SAW-DNYH'
  },
  {
    asin: 'B01LYFX7BT',
    marketplace: 3,
    name: 'InstaShaker Leak Proof Lid - Spare Part',
    listing_price: 5.95,
    shipping_price: 0,
    unit_shipping_cost: 1,
    unit_cost: 1,
    smallImage: 'http://ecx.images-amazon.com/images/I/41WlXYInNvL._SL75_.jpg',
    sku: 'RF-BWHF-P0RB'
  }
];

const productHcog = [
  {
    id: 123,
    range: { from: '2020-02-13', to: '2020-10-22' },
    unit_cost: 1,
    unit_shipping_cost: 1,
    fbm_shipping_cost: 1,
    fbm_return_cost: 1
  },
  {
    id: 125,
    range: { from: null, to: '2020-01-20' },
    unit_cost: 3,
    unit_shipping_cost: 3,
    fbm_shipping_cost: 3,
    fbm_return_cost: 3
  },
  {
    id: 126,
    range: { from: '2020-01-21', to: '2020-02-12' },
    unit_cost: 2,
    unit_shipping_cost: 2,
    fbm_shipping_cost: 2,
    fbm_return_cost: 2
  }
];

mock.onGet(API.products.list).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  return [200, products];
});

let replyIndex = 0;

mock.onPost(API.products.list).reply(async () => {
  replyIndex += 1;
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  if (replyIndex % 2)
    return [
      400,
      {
        error: {
          message: '112 products had their product costs updated. We have detected 5 problems.',
          details: [
            '112 products had their product costs updated. We have detected 5 problems.',
            '112 products had their product costs updated. We have detected 5 problems.'
          ]
        }
      }
    ];
  return [
    200,
    { details: '112 products had their product costs updated. We have detected 5 problems.' }
  ];
});

mock.onPost(API.products.hCog).reply(async request => {
  console.log(`onPost: ${request.url}`, JSON.parse(request.data));
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  return [200, { id: 111 }];
});

mock.onPut(new RegExp(`${API.products.hCog}/.+`)).reply(async request => {
  console.log(`onPut: ${request.url}`, JSON.parse(request.data));
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  replyIndex += 1;
  return [replyIndex % 2 ? 200 : 409];
});

mock.onDelete(new RegExp(`${API.products.hCog}/.+`)).reply(async request => {
  console.log(`onDelete: ${request.url}`);
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  replyIndex += 1;
  return [replyIndex % 2 ? 200 : 409];
});

mock.onGet(API.products.hCog).reply(async request => {
  console.log('onGet:', request.params);
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  replyIndex += 1;
  return [200, replyIndex % 2 ? productHcog : null];
});
