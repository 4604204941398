import { put, select, takeLatest } from '@redux-saga/core/effects';
import { isFullAccessForFree } from '@sm/api';
import {
  SET_USER_PROFILE,
  setNotFilledCompanyCountryWarningShowAction,
  setSpaWarningShowAction
} from 'seller/store/actions';
import { userPreferencesSelector, userSelector } from 'seller/store/reducers';
import {
  setAccessDeniedWarningShowAction,
  setSkippedDisconnectedAccountsAction,
  SKIP_ACCESS_DENIED_WARNING,
  SKIP_SPA_WARNING
} from './action';

export function* userPreferencesSaga(action) {
  const { type } = action;
  const { accessDeniedSkipped } = yield select(userPreferencesSelector);
  const { profile } = yield select(userSelector);
  const {
    accessDenied,
    notConnectedSPA,
    company_residence_country: companyResidenceCountry,
    billing
  } = profile;

  if (action.type === SET_USER_PROFILE) {
    yield put(setSpaWarningShowAction(notConnectedSPA));
    if (billing && !isFullAccessForFree(profile)) {
      yield put(setNotFilledCompanyCountryWarningShowAction(!companyResidenceCountry));
    }
    yield put(setAccessDeniedWarningShowAction(accessDenied && !accessDeniedSkipped));
    if (!accessDenied && accessDeniedSkipped) {
      yield put(setSkippedDisconnectedAccountsAction(false));
    }
  }

  if (type === SKIP_ACCESS_DENIED_WARNING) {
    yield put(setSkippedDisconnectedAccountsAction(true));
    yield put(setAccessDeniedWarningShowAction(false));
  }

  if (action.type === SKIP_SPA_WARNING) {
    yield put(setSpaWarningShowAction(false));
  }
}

export function* userPreferencesSagaWatcher() {
  yield takeLatest(
    [SET_USER_PROFILE, SKIP_ACCESS_DENIED_WARNING, SKIP_SPA_WARNING],
    userPreferencesSaga
  );
}
