import API from '@sm/api';
import mock from '../mock';

const ordersDB = {
  orders: [
    {
      sales: 16.02,
      asin: 'B012A5Z89K',
      marketplace: 3,
      orderId: '114-3739200-4092251',
      orderItemId: '40909742197130',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: null,
      buyer: 's4k6b7q57lff508@marketplace.amazon.com',
      sku: 'UF-KPKM-TMSZ',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'Activene Arnica Gel Cream - with Menthol and MSM. Pain Relief for Joint, Tendon, Muscle Ache. Chosen by Sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Pain 3 Ounce',
      smallImage: 'https://m.media-amazon.com/images/I/51rAED0i9cL._SL75_.jpg'
    },
    {
      marketplace: 3,
      product: 141123,
      asin: 'B012A5Z89K',
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      orderId: '304-1207941-0996308',
      sku: 'UF-KPKM-TMSZ',
      sales: 250,
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2019-07-10 02:50:47',
      buyer: '052rphd5nthw5d0@marketplace.amazon.com',
      buyerOrders: 1012,
      subscribed: true,
      discounted: true
    },
    {
      marketplace: 4,
      product: 141123,
      asin: 'B012A5Z89K',
      name:
        'ACTIVENE Arnica Gel Cream - with Menthol and MSM | Pain Relief for Joint, Tendon & Muscle Pains | Chosen by sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Ache 3oz',
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      orderId: '304-1207941-0996308',
      sku: 'UF-KPKM-TMSZ',
      sales: 250,
      status: 'Canceled',
      quantity: 1,
      purchaseDateTime: '2019-07-10 02:50:47',
      buyer: 'd5nthw5d0@marketplace.amazon.com',
      buyerOrders: 1,
      subscribed: true
    },
    {
      marketplace: 5,
      product: 141123,
      asin: 'B012A5Z89K',
      name:
        'ACTIVENE Arnica Gel Cream - with Menthol and MSM | Pain Relief for Joint, Tendon & Muscle Pains | Chosen by sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Ache 3oz',
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      orderId: '304-1207941-0996308',
      sku: 'UF-KPKM-TMSZ',
      sales: 250,
      status: 'Refunded',
      quantity: 1,
      purchaseDateTime: '2019-07-10 02:50:47',
      buyer: 'buyer@test.amazon',
      buyerOrders: 2222,
      discounted: true
    },
    {
      marketplace: 6,
      product: 141123,
      asin: 'B012A5Z89K',
      name:
        'ACTIVENE Arnica Gel Cream - with Menthol and MSM | Pain Relief for Joint, Tendon & Muscle Pains | Chosen by sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Ache 3oz',
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      orderId: '304-1207941-0996308',
      sku: 'UF-KPKM-TMSZ',
      sales: 250,
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2019-07-10 02:50:47',
      buyer: 'buyer@test.amazon'
    }
  ]
};

mock.onGet(API.orders.list).reply(() => {
  return [200, ordersDB];
});
