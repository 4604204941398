import API from '@sm/api';
import mock from '../mock';

const sellerProfileDB = {
  userName: 'Paula Herrmann',
  id: 777,
  historical_cogs_bulk_upload: true,
  amazon_traffic_page: true,
  subscription: {
    dueDate: '2019-09-30',
    plan: 1,
    previousMonth: 287,
    cc: true
  },
  getStartedChecklist: {
    set_production_cost: false,
    download_mobile_app: true,
    create_your_first_note: false,
    download_chrome_extension: false
  },
  defaultSettings: {
    marketplace: 4,
    dateRange: 'Today',
    currency: 'USD'
  },
  marketplaces: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23],
  email: 'paulaherrmanntest@test.test',
  address: 'London, 221B Baker Street',
  taxId: 'NNN-NN-NNNN',
  companyName: 'Tech Test Inc',
  start: '2016-02-10',
  accessDenied: true,
  notConnectedSPA: false,
  billing: true,
  sqp_onboarded: true,
  mbr_start: '2023-02',
  monthly_brand_review: true,
  not_approved_sender_email: true
};

const agencyProfileDB = {
  status: 'Active',
  subscription: {
    cc: false
  },
  defaultSettings: {
    marketplace: 7,
    dateRange: 'Today',
    currency: 'EUR'
  },
  email: 'agency@test.it',
  userName: 'iAgency',
  companyName: null,
  address: null,
  country: null,
  agency: true,
  getStartedChecklist: {
    download_mobile_app: false,
    set_production_cost: false,
    taken_tour: false,
    download_chrome_extension: false
  },
  pnl_dashboard: true
};

const accountsDB = {
  A3NW2RP3PUPP94: {
    products: 28,
    marketplaces: [1, 2, 3, 4, 22],
    mainMarketplace: 2,
    title: 'Paula Herrmann North America Seller Account',
    linkedSPA: false,
    linkedPPC: true,
    SCEmailAddress: 'd41Dxx9@metrixmail.com',
    SCEmailAddressApproved: true,
    sp_api_endpoints_access_denied: null
  },
  AOIQ3TRAAQ8H: {
    products: 12351,
    marketplaces: [0, 4, 5, 6],
    title: 'Paula Herrmann',
    linkedSPA: true,
    SCEmailAddress: 'd41Dxx9@metrixmail.com',
    sp_api_endpoints_access_denied: ['sessions']
  },
  FANI8COWQC1T: {
    marketplaces: [4, 5, 6, 7, 8],
    mainMarketplace: null,
    title: 'Paula Herrmann Europe Seller Account #2',
    state: 0,
    linkedPPC: true,
    linkedSPA: false
  },
  A3I0LU6W8CG4O4: {
    marketplaces: [9, 10],
    title: 'Paula Herrmann India Seller Account',
    state: 0
  },
  A1G08I38URAWZJ: {
    products: 12,
    marketplaces: [10],
    title: 'Paula Herrmann Japan Seller Account',
    state: 1
  }
};

mock.onGet(API.user.profile, { params: { userId: 3 } }).reply(() => {
  return [200, { ...sellerProfileDB, ba: true }];
});

mock.onPost(API.shopify.shop).reply(config => {
  console.log(config);
  sellerProfileDB.status = 'shopify-onboarding';
  return [200];
});

mock.onGet(API.user.profile, { params: { userId: 2 } }).reply(data => {
  console.log(data);
  return [200, agencyProfileDB];
});

mock.onGet(API.user.profile, { params: { userId: 1 } }).reply(() => {
  return [200, sellerProfileDB];
});

mock.onGet(API.user.profile).reply(() => {
  return [200, sellerProfileDB];
});

mock.onGet(API.user.accounts).reply(() => {
  return [200, accountsDB];
});

mock.onPut(API.user.mwsToken).reply(() => {
  sellerProfileDB.accessDenied = false;
  return [200];
});

mock.onPut(API.user.profile).reply(() => {
  sellerProfileDB.avatar = 'http://i.stack.imgur.com/Dj7eP.jpg';
  return [200];
});
