import axios from 'axios/index';
import { cancelled, takeLatest, put, takeEvery, select } from '@redux-saga/core/effects';

import { getCancelToken } from '@utils';
import API, { QUERY_STRING_LENGTH_LIMIT, selectedProductsToAPI } from '@sm/api';
import { getSelectionDiff } from 'seller/containers/bookmark-search/utils';
import { setBookmarksSidebarShowAction } from '../saga/bookmarks';
import { SET_MARKETPLACE } from './selector.actions';
import { productSearchSelector, selectorSelector } from '../reducers';

export const SET_KEYWORD = 'SET_KEYWORD';
export const START_SEARCH = 'START_SEARCH';
export const ACTIVATE_SEARCH = 'ACTIVATE_SEARCH';
export const SET_INPUT_FAILURE = 'SET_INPUT_FAILURE';
export const SET_RESULT = 'SET_RESULT';
export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const SET_SELECTED_PRODUCTS_TO_REDUCER = 'SET_SELECTED_PRODUCTS_TO_REDUCER';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';

export const searchProductsAction = (params, successCallback, errorCallback) => ({
  type: SEARCH_PRODUCTS,
  payload: { params, successCallback, errorCallback }
});

export const setProductSearchKeyword = keyword => ({
  type: SET_KEYWORD,
  keyword
});

export const setSelectedProducts = selectedProducts => ({
  type: SET_SELECTED_PRODUCTS,
  selectedProducts
});

function startSearch() {
  return {
    type: START_SEARCH
  };
}

export function activateSearch(active) {
  return {
    type: ACTIVATE_SEARCH,
    active
  };
}

function setProductSearchFailure(error) {
  return {
    type: SET_INPUT_FAILURE,
    error
  };
}

export const setResult = products => {
  return {
    type: SET_RESULT,
    products
  };
};

export const setSelectedProductsToReducerAction = payload => ({
  type: SET_SELECTED_PRODUCTS_TO_REDUCER,
  payload
});

const { CancelToken } = axios;

export function* searchProductsSaga({ payload }) {
  const { params, successCallback, errorCallback } = payload;
  const source = CancelToken.source();
  try {
    const response = yield axios.get(API.products.search, {
      params,
      cancelToken: source.token
    });
    successCallback(response.data);
  } catch (e) {
    console.log('e', e);
    errorCallback(e.message);
  } finally {
    if (yield cancelled()) source.cancel();
  }
}

export const setInput = (keyword, marketplace = null) => {
  return (dispatch, getState) => {
    dispatch(setProductSearchKeyword(keyword));
    const { userId } = getState().user;
    const { marketplaceID } = getState().selector;

    if (keyword.length <= 2) {
      dispatch(setResult({}));
      return;
    }

    dispatch(setBookmarksSidebarShowAction(false));
    dispatch(startSearch());
    dispatch(
      searchProductsAction(
        { keyword, userId, marketplaceID: marketplace !== null ? marketplace : marketplaceID },
        val => dispatch(setResult(val)),
        val => dispatch(setProductSearchFailure(val))
      )
    );
  };
};

export const getProductIdsLength = list => list && list.map(item => item.id).join().length;

function* setSelectedProductsSaga({ selectedProducts }) {
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    const queryLength = getProductIdsLength(selectedProducts);
    const isLongProductsList = queryLength >= QUERY_STRING_LENGTH_LIMIT;
    const { selectedBookmark } = yield select(productSearchSelector);
    const { marketplaceID } = yield select(selectorSelector);

    const isPersistBookmark =
      selectedBookmark &&
      !getSelectionDiff(selectedBookmark?.preparedBookmarkProducts, selectedProducts).changed;

    let tempBookmark = null;

    if (isLongProductsList && !isPersistBookmark) {
      const { data } = yield axios.post(
        API.products.bookmarks,
        { temp: true, marketplaceID, name: 'temp', ...selectedProductsToAPI(selectedProducts) },
        { cancelToken }
      );
      tempBookmark = { id: data.id, expiry_date: data.expiry_date };
    }
    yield put(
      setSelectedProductsToReducerAction({ tempBookmark, selectedProducts, isPersistBookmark })
    );
  } catch (e) {
    console.log('setSelectedProductsSaga', e);
  } finally {
    if (yield cancelled()) cancelRequest();
  }
}

function* clearSelectedProductsOnMarketplaceChangedSaga({ skipClearSelectedProducts }) {
  if (skipClearSelectedProducts) return;
  yield put(setSelectedProducts([]));
}

export function* searchProductsSagaWatcher() {
  yield takeLatest(SEARCH_PRODUCTS, searchProductsSaga);
  yield takeLatest(SET_SELECTED_PRODUCTS, setSelectedProductsSaga);
  yield takeEvery(SET_MARKETPLACE, clearSelectedProductsOnMarketplaceChangedSaga);
}
