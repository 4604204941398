import { put, select } from '@redux-saga/core/effects';
import { setNotificationMsgAction, setSelectedProducts } from '../actions';
import { HTTP_410_GONE } from '@utils';
import { productSearchSelector } from '../reducers';

export function* failHandler([errorData, errorText, errorEvent]) {
  console.log(`${errorText}\n`, errorEvent || '', '\nerrorData:', errorData);
  yield put(setNotificationMsgAction(errorText));
}

export function* missedBookmarkHandler(error) {
  const errorStatusCode = error?.response?.status;
  if (errorStatusCode !== HTTP_410_GONE) return false;
  const { selectedProducts } = yield select(productSearchSelector);
  yield put(setSelectedProducts([...selectedProducts]));
  return true;
}
