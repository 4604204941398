import API from '@sm/api';
import mock from '../mock';

const dataResponse = {
  config: {
    levels: ['metric', 'brand', 'sales_channel'],
    time_grain: 'month',
    dates: ['2024-04-01', '2024-03-01', '2024-02-01']
  },
  data: [
    {
      type: 'metric',
      metadata: {
        name: 'gross_sales',
        type: 'metric'
      },
      name: 'gross_sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'refunds',
            type: 'metric'
          },
          name: 'refunds',
          children: [
            {
              type: 'brand',
              name: 4657,
              metadata: {
                id: 4657,
                name: 4657,
                type: 'brand'
              },
              children: [
                {
                  type: 'sales_channel',
                  name: 'Walmart',
                  metadata: {
                    id: 3,
                    type: 'sales_channel'
                  },
                  values: [-9.99, -66.82000000000001, -59.92],
                  targets: [-17.17, -30.6, -30.48],
                  values_percentages: [0.02, 0.08, 0.07],
                  targets_percentages: [0.02, 0.03, 0.04]
                },
                {
                  type: 'sales_channel',
                  name: 'Amazon SC',
                  metadata: {
                    id: 1,
                    type: 'sales_channel'
                  },
                  values: [-464.26, -778.5800000000003, -958.61],
                  targets: [-1718.999999999999, -1731.0399999999995, -1650.9700000000003],
                  values_percentages: [1.07, 0.88, 1.18],
                  targets_percentages: [1.94, 1.93, 1.92]
                },
                {
                  type: 'sales_channel',
                  name: 'Shopify',
                  metadata: {
                    id: 2,
                    type: 'sales_channel'
                  },
                  values: [-108.30999999999999, -81.03999999999999, -99.65],
                  targets: [-143.09999999999997, -142.91000000000005, -142.9700000000001],
                  values_percentages: [0.25, 0.09, 0.12],
                  targets_percentages: [0.16, 0.16, 0.17]
                }
              ],
              values: [-582.56, -926.4400000000003, -1118.18],
              targets: [-1879.269999999999, -1904.5499999999995, -1824.4200000000003],
              values_percentages: [0.42, 0.36, 0.44],
              targets_percentages: [0.58, 0.58, 0.64]
            },
            {
              type: 'brand',
              name: 4658,
              metadata: {
                id: 4658,
                name: 4658,
                type: 'brand'
              },
              children: [
                {
                  type: 'sales_channel',
                  name: 'Walmart',
                  metadata: {
                    id: 3,
                    type: 'sales_channel'
                  },
                  values: [-51.95, -227.75, -227.34000000000003],
                  targets: [-78.77, -139.27000000000004, -139.17],
                  values_percentages: [0.06, 0.13, 0.13],
                  targets_percentages: [0.03, 0.06, 0.07]
                },
                {
                  type: 'sales_channel',
                  name: 'Amazon SC',
                  metadata: {
                    id: 1,
                    type: 'sales_channel'
                  },
                  values: [-6347.629999999999, -11370.370000000003, -12101.099999999999],
                  targets: [-15056.999999999993, -14712.910000000005, -13560.110000000002],
                  values_percentages: [6.74, 6.67, 6.99],
                  targets_percentages: [6.45, 6.19, 6.83]
                },
                {
                  type: 'sales_channel',
                  name: 'Shopify',
                  metadata: {
                    id: 2,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [-6399.579999999999, -11598.120000000003, -12328.439999999999],
              targets: [-15135.769999999993, -14852.180000000006, -13699.280000000002],
              values_percentages: [4.65, 4.48, 4.84],
              targets_percentages: [4.7, 4.54, 4.81]
            }
          ],
          values: [-6982.139999999999, -12524.560000000003, -13446.619999999999],
          targets: [-17015.039999999994, -16756.730000000003, -15523.700000000003],
          values_percentages: [5.08, 4.84, 5.28],
          targets_percentages: [5.29, 5.12, 5.45]
        },
        {
          type: 'metric',
          metadata: {
            name: 'discounts',
            type: 'metric'
          },
          name: 'discounts',
          children: [
            {
              type: 'brand',
              name: 4657,
              metadata: {
                id: 4657,
                name: 4657,
                type: 'brand'
              },
              children: [
                {
                  type: 'sales_channel',
                  name: 'Walmart',
                  metadata: {
                    id: 3,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'sales_channel',
                  name: 'Amazon SC',
                  metadata: {
                    id: 1,
                    type: 'sales_channel'
                  },
                  values: [-172.77999999999997, -1071.19, -1432.8400000000001],
                  targets: [-1562.9999999999993, -1595.8800000000003, -1510.03],
                  values_percentages: [0.4, 1.21, 1.76],
                  targets_percentages: [1.77, 1.78, 1.75]
                },
                {
                  type: 'sales_channel',
                  name: 'Shopify',
                  metadata: {
                    id: 2,
                    type: 'sales_channel'
                  },
                  values: [-175.47999999999996, -302.03, -290.86],
                  targets: [-215.09999999999985, -571.9499999999999, -214.88999999999993],
                  values_percentages: [0.4, 0.34, 0.36],
                  targets_percentages: [0.24, 0.64, 0.25]
                }
              ],
              values: [-348.25999999999993, -1373.22, -1723.7000000000003],
              targets: [-1778.0999999999992, -2167.8300000000004, -1724.9199999999998],
              values_percentages: [0.25, 0.53, 0.68],
              targets_percentages: [0.55, 0.66, 0.61]
            },
            {
              type: 'brand',
              name: 4658,
              metadata: {
                id: 4658,
                name: 4658,
                type: 'brand'
              },
              children: [
                {
                  type: 'sales_channel',
                  name: 'Walmart',
                  metadata: {
                    id: 3,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'sales_channel',
                  name: 'Amazon SC',
                  metadata: {
                    id: 1,
                    type: 'sales_channel'
                  },
                  values: [-182.57999999999998, -364.15, -517.67],
                  targets: [-303, -574.1199999999998, -858.98],
                  values_percentages: [0.19, 0.21, 0.3],
                  targets_percentages: [0.13, 0.24, 0.43]
                },
                {
                  type: 'sales_channel',
                  name: 'Shopify',
                  metadata: {
                    id: 2,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [-182.57999999999998, -364.15, -517.67],
              targets: [-303, -574.1199999999998, -858.98],
              values_percentages: [0.13, 0.14, 0.2],
              targets_percentages: [0.09, 0.18, 0.3]
            }
          ],
          values: [-530.8399999999999, -1737.37, -2241.3700000000003],
          targets: [-2081.0999999999995, -2741.9500000000003, -2583.8999999999996],
          values_percentages: [0.39, 0.67, 0.88],
          targets_percentages: [0.65, 0.84, 0.91]
        },
        {
          type: 'metric',
          metadata: {
            name: 'other_credits_and_refunds',
            type: 'metric'
          },
          name: 'other_credits_and_refunds',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'fba_inventory_credit',
                type: 'metric'
              },
              name: 'fba_inventory_credit',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [408.6000000000001, 799.3400000000001, 1885.7600000000004],
                      targets: [],
                      values_percentages: [0.94, 0.9, 2.31],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [408.6000000000001, 799.3400000000001, 1885.7600000000004],
                  targets: [],
                  values_percentages: [0.3, 0.31, 0.74],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [588.3000000000003, 2304.6399999999994, 12573.34],
                      targets: [],
                      values_percentages: [0.62, 1.35, 7.26],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [588.3000000000003, 2304.6399999999994, 12573.34],
                  targets: [],
                  values_percentages: [0.43, 0.89, 4.94],
                  targets_percentages: []
                }
              ],
              values: [996.9000000000003, 3103.9799999999996, 14459.1],
              targets: [],
              values_percentages: [0.72, 1.2, 5.68],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'shipping_charges',
                type: 'metric'
              },
              name: 'shipping_charges',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [185.93, 222.94, 296.98],
                      targets: [],
                      values_percentages: [0.43, 0.25, 0.36],
                      targets_percentages: []
                    }
                  ],
                  values: [185.93, 222.94, 296.98],
                  targets: [],
                  values_percentages: [0.14, 0.09, 0.12],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-24.360000000000003, -64.64999999999999, -53.239999999999995],
                      targets: [],
                      values_percentages: [0.03, 0.04, 0.03],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [-24.360000000000003, -64.64999999999999, -53.239999999999995],
                  targets: [],
                  values_percentages: [0.02, 0.02, 0.02],
                  targets_percentages: []
                }
              ],
              values: [161.57, 158.29000000000002, 243.74],
              targets: [],
              values_percentages: [0.12, 0.06, 0.1],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'other_refunds',
                type: 'metric'
              },
              name: 'other_refunds',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [5.6, 12.5, 22.18],
                      targets: [],
                      values_percentages: [0.01, 0.01, 0.03],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [5.6, 12.5, 22.18],
                  targets: [],
                  values_percentages: [0, 0, 0.01],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-9.499999999999998, -64.58, -34.879999999999995],
                      targets: [],
                      values_percentages: [0.01, 0.04, 0.02],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [-9.499999999999998, -64.58, -34.879999999999995],
                  targets: [],
                  values_percentages: [0.01, 0.02, 0.01],
                  targets_percentages: []
                }
              ],
              values: [-3.8999999999999986, -52.08, -12.699999999999996],
              targets: [],
              values_percentages: [0, 0.02, 0],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'wholesale_liquidation_revenue',
                type: 'metric'
              },
              name: 'wholesale_liquidation_revenue',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                }
              ],
              values: [0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'wfs_lost_inventory',
                type: 'metric'
              },
              name: 'wfs_lost_inventory',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                }
              ],
              values: [0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'wfs_refund_adjustment',
                type: 'metric'
              },
              name: 'wfs_refund_adjustment',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                }
              ],
              values: [0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'wfs_damage_in_warehouse',
                type: 'metric'
              },
              name: 'wfs_damage_in_warehouse',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [],
                  values_percentages: [0, 0, 0],
                  targets_percentages: []
                }
              ],
              values: [0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0],
              targets_percentages: []
            }
          ],
          values: [1154.5700000000002, 3210.1899999999996, 14690.14],
          targets: [4217.999999999998, 3596.0000000000005, 4582.870000000001],
          values_percentages: [0.84, 1.24, 5.77],
          targets_percentages: [1.31, 1.1, 1.61]
        }
      ],
      values: [137567.03, 258931.74999999997, 254722.44999999995],
      targets: [321765.9100000001, 327487.9999999999, 284798.43999999994],
      values_percentages: [100, 100, 100],
      targets_percentages: [100, 100, 100]
    },
    {
      type: 'metric',
      metadata: {
        name: 'net_sales',
        type: 'metric'
      },
      name: 'net_sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'net_cogs',
            type: 'metric'
          },
          name: 'net_cogs',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'cogs',
                type: 'metric'
              },
              name: 'cogs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [-64.68, -1898.5599999999997, -1087.5299999999997],
                      targets: [-244.80000000000007, -432.3099999999999, -432.2699999999999],
                      values_percentages: [0.15, 2.18, 1.34],
                      targets_percentages: [0.28, 0.49, 0.51]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-12844.86, -26130.04, -24536.780000000002],
                      targets: [-26517.900000000005, -26801.98000000002, -25647.89],
                      values_percentages: [29.87, 29.94, 30.31],
                      targets_percentages: [30.61, 30.64, 30.37]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [-11547.980000000001, -26351.049999999992, -7321.84],
                      targets: [-2357.9999999999986, -2357.8599999999988, -2357.989999999999],
                      values_percentages: [26.85, 30.19, 9.05],
                      targets_percentages: [2.72, 2.7, 2.79]
                    }
                  ],
                  values: [-24457.520000000004, -54379.649999999994, -32946.15],
                  targets: [-29120.700000000004, -29592.150000000023, -28438.149999999998],
                  values_percentages: [18.64, 21.94, 12.99],
                  targets_percentages: [9.49, 9.5, 10.48]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [-577.8599999999999, -910.2000000000002, -1176.4899999999998],
                      targets: [-1159.4500000000003, -2046.02, -2046.179999999999],
                      values_percentages: [0.66, 0.57, 0.68],
                      targets_percentages: [0.53, 0.91, 1.1]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-19568.32, -35102.05, -35870.119999999995],
                      targets: [-52023.89999999999, -53325.890000000014, -44860.97],
                      values_percentages: [22.19, 21.86, 20.76],
                      targets_percentages: [23.62, 23.79, 24.01]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [-0.91, -13.12, -9.84],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0.01, 0.01],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-20147.09, -36025.37, -37056.45],
                  targets: [-53183.349999999984, -55371.91000000001, -46907.15],
                  values_percentages: [15.36, 14.53, 14.6],
                  targets_percentages: [17.33, 17.77, 17.29]
                }
              ],
              values: [-44604.61, -90405.01999999999, -70002.59999999999],
              targets: [-82304.04999999999, -84964.06000000003, -75345.29999999999],
              values_percentages: [34, 36.47, 27.59],
              targets_percentages: [26.82, 27.27, 27.77]
            },
            {
              type: 'metric',
              metadata: {
                name: 'returned_cogs',
                type: 'metric'
              },
              name: 'returned_cogs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [5.32, 29.26, 24.8],
                      targets: [],
                      values_percentages: [0.01, 0.03, 0.03],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 38.68, 29.09],
                      targets: [],
                      values_percentages: [0, 0.04, 0.04],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [8.07, 33.32, 27.25],
                      targets: [],
                      values_percentages: [0.02, 0.04, 0.03],
                      targets_percentages: []
                    }
                  ],
                  values: [13.39, 101.25999999999999, 81.14],
                  targets: [],
                  values_percentages: [0.01, 0.04, 0.03],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [7.1899999999999995, 74.97999999999999, 60.53],
                      targets: [],
                      values_percentages: [0.01, 0.05, 0.04],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [256.73, 987.3, 1051.87],
                      targets: [],
                      values_percentages: [0.29, 0.61, 0.61],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [263.92, 1062.28, 1112.3999999999999],
                  targets: [],
                  values_percentages: [0.2, 0.43, 0.44],
                  targets_percentages: []
                }
              ],
              values: [277.31, 1163.54, 1193.54],
              targets: [],
              values_percentages: [0.21, 0.47, 0.47],
              targets_percentages: []
            }
          ],
          values: [-44327.29000000001, -89241.5, -68809.06],
          targets: [-82304.04999999999, -84964.06000000003, -75345.29999999999],
          values_percentages: [33.78, 36, 27.12],
          targets_percentages: [26.82, 27.27, 27.77]
        }
      ],
      values: [131208.62000000002, 247880.01000000007, 253724.59999999998],
      targets: [306887.7999999999, 311585.47, 271273.76999999996],
      values_percentages: [95.38, 95.73, 99.61],
      targets_percentages: [95.38, 95.14, 95.25]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_1',
        type: 'metric'
      },
      name: 'cm_1',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'marketplace_costs',
            type: 'metric'
          },
          name: 'marketplace_costs',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'net_direct_fees',
                type: 'metric'
              },
              name: 'net_direct_fees',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [-61.95, -2215.1700000000005, -1432.8300000000004],
                      targets: [-381.9900000000001, -674.1100000000001, -674.0400000000001],
                      values_percentages: [0.14, 2.54, 1.77],
                      targets_percentages: [0.44, 0.77, 0.8]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-15858.989999999998, -30914.61, -28883.58],
                      targets: [-33662.1, -33828.12999999999, -32337.899999999983],
                      values_percentages: [36.87, 35.42, 35.68],
                      targets_percentages: [38.86, 38.67, 38.29]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [-1121.9999999999998, -1121.8900000000008, -977.0100000000007],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [1.3, 1.28, 1.16]
                    }
                  ],
                  values: [-15920.939999999999, -33129.78, -30316.410000000003],
                  targets: [-35166.09, -35624.12999999999, -33988.94999999998],
                  values_percentages: [12.13, 13.37, 11.95],
                  targets_percentages: [11.46, 11.43, 12.53]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [-736.4100000000001, -2059.34, -2159.61],
                      targets: [-1750.4900000000002, -3089.5999999999995, -3089.73],
                      values_percentages: [0.83, 1.28, 1.25],
                      targets_percentages: [0.79, 1.38, 1.65]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-32405.03, -59980.909999999996, -59440.5],
                      targets: [-82689.00000000004, -84309.14999999998, -69107],
                      values_percentages: [36.74, 37.35, 34.4],
                      targets_percentages: [37.54, 37.62, 36.99]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-33141.44, -62040.25, -61600.11],
                  targets: [-84439.49000000005, -87398.74999999999, -72196.73],
                  values_percentages: [25.26, 25.03, 24.28],
                  targets_percentages: [27.51, 28.05, 26.61]
                }
              ],
              values: [-49062.38, -95170.03, -91916.52],
              targets: [-119605.58000000005, -123022.87999999998, -106185.68],
              values_percentages: [37.39, 38.39, 36.23],
              targets_percentages: [38.97, 39.48, 39.14]
            },
            {
              type: 'metric',
              metadata: {
                name: 'account_level_amz_fees_and_adjs',
                type: 'metric'
              },
              name: 'account_level_amz_fees_and_adjs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-1997.3999999999992, -2808.5999999999985, -2545.920000000001],
                      targets: [-2048.999999999999, -2064.9099999999985, -1963.8800000000006],
                      values_percentages: [4.64, 3.22, 3.15],
                      targets_percentages: [2.37, 2.36, 2.33]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-1997.3999999999992, -2808.5999999999985, -2545.920000000001],
                  targets: [-2048.999999999999, -2064.9099999999985, -1963.8800000000006],
                  values_percentages: [1.52, 1.13, 1],
                  targets_percentages: [0.67, 0.66, 0.72]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-6791.400000000002, -4823.6, -4864.75],
                      targets: [-2597.1, -1554.9600000000005, -4532.99],
                      values_percentages: [7.7, 3, 2.82],
                      targets_percentages: [1.18, 0.69, 2.43]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-6791.400000000002, -4823.6, -4864.75],
                  targets: [-2597.1, -1554.9600000000005, -4532.99],
                  values_percentages: [5.18, 1.95, 1.92],
                  targets_percentages: [0.85, 0.5, 1.67]
                }
              ],
              values: [-8788.800000000001, -7632.199999999999, -7410.670000000001],
              targets: [-4646.0999999999985, -3619.869999999999, -6496.870000000001],
              values_percentages: [6.7, 3.08, 2.92],
              targets_percentages: [1.51, 1.16, 2.39]
            },
            {
              type: 'metric',
              metadata: {
                name: 'account_level_walmart_fees_and_adjs',
                type: 'metric'
              },
              name: 'account_level_walmart_fees_and_adjs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [0, 0, 0],
              targets: [0, 0, 0],
              values_percentages: [0, 0, 0],
              targets_percentages: [0, 0, 0]
            },
            {
              type: 'metric',
              metadata: {
                name: 'account_level_shop_fees_and_adjs',
                type: 'metric'
              },
              name: 'account_level_shop_fees_and_adjs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [-44.7, -80.29000000000006, -76.56],
                      targets: [0, 0, 0],
                      values_percentages: [0.1, 0.09, 0.09],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-44.7, -80.29000000000006, -76.56],
                  targets: [0, 0, 0],
                  values_percentages: [0.03, 0.03, 0.03],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [-15.59999999999999, -29.14000000000001, -29],
                      targets: [0, 0, 0],
                      values_percentages: [0.02, 0.02, 0.02],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-15.59999999999999, -29.14000000000001, -29],
                  targets: [0, 0, 0],
                  values_percentages: [0.01, 0.01, 0.01],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [-60.3, -109.43000000000008, -105.56],
              targets: [0, 0, 0],
              values_percentages: [0.05, 0.04, 0.04],
              targets_percentages: [0, 0, 0]
            }
          ],
          values: [-57911.48, -102911.66, -99432.75],
          targets: [-124251.67999999998, -126642.74999999997, -112682.84],
          values_percentages: [44.14, 41.52, 39.19],
          targets_percentages: [40.49, 40.64, 41.54]
        },
        {
          type: 'metric',
          metadata: {
            name: 'non_marketplace_costs',
            type: 'metric'
          },
          name: 'non_marketplace_costs',
          children: [
            {
              type: 'metric',
              metadata: {
                name: '_3pl_costs',
                type: 'metric'
              },
              name: '_3pl_costs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-15650.1, -6365.850000000003, -7298.720000000003],
                      targets: [],
                      values_percentages: [36.39, 7.29, 9.02],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [-15650.1, -6365.850000000003, -7298.720000000003],
                  targets: [],
                  values_percentages: [11.93, 2.57, 2.88],
                  targets_percentages: []
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-6384.899999999999, -6385.070000000001, -3633.989999999999],
                      targets: [],
                      values_percentages: [7.24, 3.98, 2.1],
                      targets_percentages: []
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [],
                      values_percentages: [0, 0, 0],
                      targets_percentages: []
                    }
                  ],
                  values: [-6384.899999999999, -6385.070000000001, -3633.989999999999],
                  targets: [],
                  values_percentages: [4.87, 2.58, 1.43],
                  targets_percentages: []
                }
              ],
              values: [-22035, -12750.920000000004, -10932.710000000003],
              targets: [],
              values_percentages: [16.79, 5.14, 4.31],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'other_direct_costs',
                type: 'metric'
              },
              name: 'other_direct_costs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, -205.8999999999999],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0.25],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, -205.8999999999999],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0.08],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [0, 0, -205.8999999999999],
              targets: [0, 0, 0],
              values_percentages: [0, 0, 0.08],
              targets_percentages: [0, 0, 0]
            },
            {
              type: 'metric',
              metadata: {
                name: 'inventory_write_off',
                type: 'metric'
              },
              name: 'inventory_write_off',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-833.0999999999997, -832.97, -20443.260000000002],
                      targets: [-833.0999999999997, -832.97, -832.8800000000006],
                      values_percentages: [1.94, 0.95, 25.26],
                      targets_percentages: [0.96, 0.95, 0.99]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-833.0999999999997, -832.97, -20443.260000000002],
                  targets: [-833.0999999999997, -832.97, -832.8800000000006],
                  values_percentages: [0.63, 0.34, 8.06],
                  targets_percentages: [0.27, 0.27, 0.31]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-1916.0999999999985, -1916.1099999999988, -24.65000000000001],
                      targets: [-1916.0999999999985, -1916.1099999999988, -1916.0299999999986],
                      values_percentages: [2.17, 1.19, 0.01],
                      targets_percentages: [0.87, 0.85, 1.03]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-1916.0999999999985, -1916.1099999999988, -24.65000000000001],
                  targets: [-1916.0999999999985, -1916.1099999999988, -1916.0299999999986],
                  values_percentages: [1.46, 0.77, 0.01],
                  targets_percentages: [0.62, 0.61, 0.71]
                }
              ],
              values: [-2749.199999999998, -2749.079999999999, -20467.910000000003],
              targets: [-2749.199999999998, -2749.079999999999, -2748.909999999999],
              values_percentages: [2.1, 1.11, 8.07],
              targets_percentages: [0.9, 0.88, 1.01]
            },
            {
              type: 'metric',
              metadata: {
                name: 'inventory_write_down',
                type: 'metric'
              },
              name: 'inventory_write_down',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-12.000000000000005, -8.989999999999997, 0],
                      targets: [-12.000000000000005, -8.989999999999997, -6.09],
                      values_percentages: [0.03, 0.01, 0],
                      targets_percentages: [0.01, 0.01, 0.01]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-12.000000000000005, -8.989999999999997, 0],
                  targets: [-12.000000000000005, -8.989999999999997, -6.09],
                  values_percentages: [0.01, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-128.99999999999994, -65.10000000000002, 0],
                      targets: [-128.99999999999994, -65.10000000000002, 0],
                      values_percentages: [0.15, 0.04, 0],
                      targets_percentages: [0.06, 0.03, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-128.99999999999994, -65.10000000000002, 0],
                  targets: [-128.99999999999994, -65.10000000000002, 0],
                  values_percentages: [0.1, 0.03, 0],
                  targets_percentages: [0.04, 0.02, 0]
                }
              ],
              values: [-140.99999999999994, -74.09000000000002, 0],
              targets: [-140.99999999999994, -74.09000000000002, -6.09],
              values_percentages: [0.11, 0.03, 0],
              targets_percentages: [0.05, 0.02, 0]
            }
          ],
          values: [-24925.2, -15574.090000000007, -31796.47000000001],
          targets: [-24924.9, -15574.090000000004, -15506.009999999991],
          values_percentages: [19, 6.28, 12.53],
          targets_percentages: [8.12, 5, 5.72]
        }
      ],
      values: [86881.32, 158638.39, 184915.48999999996],
      targets: [226206.88, 228092.40000000002, 197193.63],
      values_percentages: [66.22, 64, 72.88],
      targets_percentages: [73.71, 73.2, 72.69]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_2',
        type: 'metric'
      },
      name: 'cm_2',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'marketing_and_advertising',
            type: 'metric'
          },
          name: 'marketing_and_advertising',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'marketing_and_advertising_amz',
                type: 'metric'
              },
              name: 'marketing_and_advertising_amz',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-1853.1599999999999, -3692.359999999999, -4103.87],
                      targets: [-5115.900000000001, -5317.1200000000035, -5094.1399999999985],
                      values_percentages: [4.31, 4.23, 5.07],
                      targets_percentages: [5.91, 6.08, 6.03]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-1853.1599999999999, -3692.359999999999, -4103.87],
                  targets: [-5115.900000000001, -5317.1200000000035, -5094.1399999999985],
                  values_percentages: [1.41, 1.49, 1.62],
                  targets_percentages: [1.67, 1.71, 1.88]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-18681.14, -35046.88, -30088.47],
                      targets: [-39339.90000000003, -38669.08999999999, -29256.07000000002],
                      values_percentages: [21.18, 21.82, 17.41],
                      targets_percentages: [17.86, 17.25, 15.66]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-18681.14, -35046.88, -30088.47],
                  targets: [-39339.90000000003, -38669.08999999999, -29256.07000000002],
                  values_percentages: [14.24, 14.14, 11.86],
                  targets_percentages: [12.82, 12.41, 10.78]
                }
              ],
              values: [-20534.3, -38739.24, -34192.340000000004],
              targets: [-44455.80000000003, -43986.20999999999, -34350.21000000002],
              values_percentages: [15.65, 15.63, 13.48],
              targets_percentages: [14.49, 14.12, 12.66]
            },
            {
              type: 'metric',
              metadata: {
                name: 'marketing_and_advertising_non_amz',
                type: 'metric'
              },
              name: 'marketing_and_advertising_non_amz',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-9750, -850.0199999999998, -3362.260000000001],
                      targets: [-9750, -850.0199999999998, -1349.9499999999994],
                      values_percentages: [22.67, 0.97, 4.15],
                      targets_percentages: [11.25, 0.97, 1.6]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-9750, -850.0199999999998, -3362.260000000001],
                  targets: [-9750, -850.0199999999998, -1349.9499999999994],
                  values_percentages: [7.43, 0.34, 1.33],
                  targets_percentages: [3.18, 0.27, 0.5]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-3999.8999999999987, -4999.99, -2127.1499999999987],
                      targets: [-3999.8999999999987, -4999.99, -2000.1300000000006],
                      values_percentages: [4.54, 3.11, 1.23],
                      targets_percentages: [1.82, 2.23, 1.07]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-3999.8999999999987, -4999.99, -2127.1499999999987],
                  targets: [-3999.8999999999987, -4999.99, -2000.1300000000006],
                  values_percentages: [3.05, 2.02, 0.84],
                  targets_percentages: [1.3, 1.6, 0.74]
                }
              ],
              values: [-13749.899999999998, -5850.009999999999, -5489.41],
              targets: [-13749.899999999998, -5850.009999999999, -3350.08],
              values_percentages: [10.48, 2.36, 2.16],
              targets_percentages: [4.48, 1.88, 1.23]
            }
          ],
          values: [-34284.2, -44589.25000000001, -39681.74999999999],
          targets: [-58205.99999999997, -49836.22, -37700.87000000002],
          values_percentages: [26.13, 17.99, 15.64],
          targets_percentages: [18.97, 15.99, 13.9]
        }
      ],
      values: [4044.6099999999988, 40152.64000000001, 53686.21000000002],
      targets: [77030.13000000002, 85877.30000000003, 69005.17000000001],
      values_percentages: [3.08, 16.2, 21.16],
      targets_percentages: [25.1, 27.56, 25.44]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_3',
        type: 'metric'
      },
      name: 'cm_3',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'operating_expenses',
            type: 'metric'
          },
          name: 'operating_expenses',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'employees_compensation_and_benefits',
                type: 'metric'
              },
              name: 'employees_compensation_and_benefits',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [0, 0, 0],
              targets: [0, 0, 0],
              values_percentages: [0, 0, 0],
              targets_percentages: [0, 0, 0]
            },
            {
              type: 'metric',
              metadata: {
                name: 'research_and_development',
                type: 'metric'
              },
              name: 'research_and_development',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-150, -150.04000000000008, 0],
                      targets: [-150, -150.04000000000008, -149.92999999999998],
                      values_percentages: [0.35, 0.17, 0],
                      targets_percentages: [0.17, 0.17, 0.18]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-150, -150.04000000000008, 0],
                  targets: [-150, -150.04000000000008, -149.92999999999998],
                  values_percentages: [0.11, 0.06, 0],
                  targets_percentages: [0.05, 0.05, 0.06]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [-150, -150.04000000000008, 0],
              targets: [-150, -150.04000000000008, -149.92999999999998],
              values_percentages: [0.11, 0.06, 0],
              targets_percentages: [0.05, 0.05, 0.06]
            },
            {
              type: 'metric',
              metadata: {
                name: 'professional_costs',
                type: 'metric'
              },
              name: 'professional_costs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [0, 0, -569.27],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0.7],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [0, 0, -569.27],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0.22],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-1182.8999999999996, -3172.8499999999985, -994.6999999999995],
                      targets: [-1182.8999999999996, -3172.8499999999985, -1182.9099999999996],
                      values_percentages: [1.34, 1.98, 0.58],
                      targets_percentages: [0.54, 1.42, 0.63]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-1182.8999999999996, -3172.8499999999985, -994.6999999999995],
                  targets: [-1182.8999999999996, -3172.8499999999985, -1182.9099999999996],
                  values_percentages: [0.9, 1.28, 0.39],
                  targets_percentages: [0.39, 1.02, 0.44]
                }
              ],
              values: [-1182.8999999999996, -3172.8499999999985, -1563.9699999999993],
              targets: [-1182.8999999999996, -3172.8499999999985, -1182.9099999999996],
              values_percentages: [0.9, 1.28, 0.62],
              targets_percentages: [0.39, 1.02, 0.44]
            },
            {
              type: 'metric',
              metadata: {
                name: 'system_costs',
                type: 'metric'
              },
              name: 'system_costs',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-824.1000000000005, -775, -500.8299999999998],
                      targets: [-824.1000000000005, -775, -653.9499999999999],
                      values_percentages: [1.92, 0.89, 0.62],
                      targets_percentages: [0.95, 0.89, 0.77]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-824.1000000000005, -775, -500.8299999999998],
                  targets: [-824.1000000000005, -775, -653.9499999999999],
                  values_percentages: [0.63, 0.31, 0.2],
                  targets_percentages: [0.27, 0.25, 0.24]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-452.09999999999985, -548.08, -1437.8199999999997],
                      targets: [-452.09999999999985, -548.08, -426.88000000000034],
                      values_percentages: [0.51, 0.34, 0.83],
                      targets_percentages: [0.21, 0.24, 0.23]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-452.09999999999985, -548.08, -1437.8199999999997],
                  targets: [-452.09999999999985, -548.08, -426.88000000000034],
                  values_percentages: [0.34, 0.22, 0.57],
                  targets_percentages: [0.15, 0.18, 0.16]
                }
              ],
              values: [-1276.2000000000003, -1323.08, -1938.6499999999996],
              targets: [-1276.2000000000003, -1323.08, -1080.8300000000004],
              values_percentages: [0.97, 0.53, 0.76],
              targets_percentages: [0.42, 0.42, 0.4]
            },
            {
              type: 'metric',
              metadata: {
                name: 'other_operating_expenses',
                type: 'metric'
              },
              name: 'other_operating_expenses',
              children: [
                {
                  type: 'brand',
                  name: 4657,
                  metadata: {
                    id: 4657,
                    name: 4657,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-594, -593.9600000000002, -1422.1599999999994],
                      targets: [-594, -593.9600000000002, -593.9200000000002],
                      values_percentages: [1.38, 0.68, 1.76],
                      targets_percentages: [0.69, 0.68, 0.7]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-594, -593.9600000000002, -1422.1599999999994],
                  targets: [-594, -593.9600000000002, -593.9200000000002],
                  values_percentages: [0.45, 0.24, 0.56],
                  targets_percentages: [0.19, 0.19, 0.22]
                },
                {
                  type: 'brand',
                  name: 4658,
                  metadata: {
                    id: 4658,
                    name: 4658,
                    type: 'brand'
                  },
                  children: [
                    {
                      type: 'sales_channel',
                      name: 'Walmart',
                      metadata: {
                        id: 3,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Amazon SC',
                      metadata: {
                        id: 1,
                        type: 'sales_channel'
                      },
                      values: [-977.1000000000007, -977.1199999999997, -421.66000000000014],
                      targets: [-977.1000000000007, -977.1199999999997, -977.0100000000007],
                      values_percentages: [1.11, 0.61, 0.24],
                      targets_percentages: [0.44, 0.44, 0.52]
                    },
                    {
                      type: 'sales_channel',
                      name: 'Shopify',
                      metadata: {
                        id: 2,
                        type: 'sales_channel'
                      },
                      values: [0, 0, 0],
                      targets: [0, 0, 0],
                      values_percentages: [0, 0, 0],
                      targets_percentages: [0, 0, 0]
                    }
                  ],
                  values: [-977.1000000000007, -977.1199999999997, -421.66000000000014],
                  targets: [-977.1000000000007, -977.1199999999997, -977.0100000000007],
                  values_percentages: [0.74, 0.39, 0.17],
                  targets_percentages: [0.32, 0.31, 0.36]
                }
              ],
              values: [-1571.1000000000008, -1571.08, -1843.8199999999995],
              targets: [-1571.1000000000008, -1571.08, -1570.9300000000007],
              values_percentages: [1.2, 0.63, 0.73],
              targets_percentages: [0.51, 0.5, 0.58]
            }
          ],
          values: [-4180.2, -6217.050000000003, -5346.440000000002],
          targets: [-4180.2, -6217.050000000003, -3985.180000000001],
          values_percentages: [3.19, 2.51, 2.11],
          targets_percentages: [1.36, 2, 1.47]
        },
        {
          type: 'metric',
          metadata: {
            name: 'other_income',
            type: 'metric'
          },
          name: 'other_income',
          children: [
            {
              type: 'brand',
              name: 4657,
              metadata: {
                id: 4657,
                name: 4657,
                type: 'brand'
              },
              children: [
                {
                  type: 'sales_channel',
                  name: 'Walmart',
                  metadata: {
                    id: 3,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'sales_channel',
                  name: 'Amazon SC',
                  metadata: {
                    id: 1,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'sales_channel',
                  name: 'Shopify',
                  metadata: {
                    id: 2,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [0, 0, 0],
              targets: [0, 0, 0],
              values_percentages: [0, 0, 0],
              targets_percentages: [0, 0, 0]
            },
            {
              type: 'brand',
              name: 4658,
              metadata: {
                id: 4658,
                name: 4658,
                type: 'brand'
              },
              children: [
                {
                  type: 'sales_channel',
                  name: 'Walmart',
                  metadata: {
                    id: 3,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'sales_channel',
                  name: 'Amazon SC',
                  metadata: {
                    id: 1,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                },
                {
                  type: 'sales_channel',
                  name: 'Shopify',
                  metadata: {
                    id: 2,
                    type: 'sales_channel'
                  },
                  values: [0, 0, 0],
                  targets: [0, 0, 0],
                  values_percentages: [0, 0, 0],
                  targets_percentages: [0, 0, 0]
                }
              ],
              values: [0, 0, 0],
              targets: [0, 0, 0],
              values_percentages: [0, 0, 0],
              targets_percentages: [0, 0, 0]
            }
          ],
          values: [0, 0, 0],
          targets: [0, 0, 0],
          values_percentages: [0, 0, 0],
          targets_percentages: [0, 0, 0]
        }
      ],
      values: [-30239.589999999997, -4436.6900000000005, 14004.52],
      targets: [17023.830000000027, 34240.90999999999, 29505.140000000007],
      values_percentages: [23.05, 1.79, 5.52],
      targets_percentages: [5.55, 10.99, 10.88]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_4',
        type: 'metric'
      },
      name: 'cm_4',
      children: [
        {
          type: 'brand',
          name: 4657,
          metadata: {
            id: 4657,
            name: 4657,
            type: 'brand'
          },
          children: [
            {
              type: 'sales_channel',
              name: 'Walmart',
              metadata: {
                id: 3,
                type: 'sales_channel'
              },
              values: [38.49, 220.51, 605.8200000000002],
              targets: [172.04000000000002, 303.6300000000001, 303.71000000000004],
              values_percentages: [0.09, 0.25, 0.75],
              targets_percentages: [0.2, 0.35, 0.36]
            },
            {
              type: 'sales_channel',
              name: 'Amazon SC',
              metadata: {
                id: 1,
                type: 'sales_channel'
              },
              values: [-22008.089999999997, 26.769999999999857, -23909.439999999995],
              targets: [-16236.89999999999, 1923.8599999999988, 1126.0700000000002],
              values_percentages: [51.17, 0.03, 29.54],
              targets_percentages: [18.74, 2.2, 1.33]
            },
            {
              type: 'sales_channel',
              name: 'Shopify',
              metadata: {
                id: 2,
                type: 'sales_channel'
              },
              values: [-7095.229999999999, -16526.53, 537.1300000000002],
              targets: [1629.8999999999994, 1272.859999999999, 1775.96],
              values_percentages: [16.5, 18.93, 0.66],
              targets_percentages: [1.88, 1.46, 2.1]
            }
          ],
          values: [-29064.829999999994, -16279.249999999998, -22766.489999999994],
          targets: [-14434.95999999999, 3500.3499999999976, 3205.7400000000002],
          values_percentages: [22.15, 6.57, 8.97],
          targets_percentages: [4.7, 1.12, 1.18]
        },
        {
          type: 'brand',
          name: 4658,
          metadata: {
            id: 4658,
            name: 4658,
            type: 'brand'
          },
          children: [
            {
              type: 'sales_channel',
              name: 'Walmart',
              metadata: {
                id: 3,
                type: 'sales_channel'
              },
              values: [1008.1, 751.7900000000001, 754.0100000000003],
              targets: [751.0899999999998, 1325.59, 1325.4700000000003],
              values_percentages: [1.14, 0.47, 0.44],
              targets_percentages: [0.34, 0.59, 0.71]
            },
            {
              type: 'sales_channel',
              name: 'Amazon SC',
              metadata: {
                id: 1,
                type: 'sales_channel'
              },
              values: [-6356.500000000001, 4846.06, 30659.46],
              targets: [26528.10000000001, 23197.919999999995, 20989.039999999994],
              values_percentages: [7.21, 3.02, 17.74],
              targets_percentages: [12.04, 10.35, 11.23]
            },
            {
              type: 'sales_channel',
              name: 'Shopify',
              metadata: {
                id: 2,
                type: 'sales_channel'
              },
              values: [-6.559999999999992, 27.65999999999999, 11.100000000000001],
              targets: [0, 0, 0],
              values_percentages: [0.01, 0.02, 0.01],
              targets_percentages: [0, 0, 0]
            }
          ],
          values: [-5354.960000000001, 5625.51, 31424.57],
          targets: [27279.19000000001, 24523.509999999995, 22314.509999999995],
          values_percentages: [4.08, 2.27, 12.39],
          targets_percentages: [8.89, 7.87, 8.23]
        }
      ],
      values: [-34419.79, -10653.739999999998, 8658.080000000005],
      targets: [12844.230000000018, 28023.859999999993, 25520.249999999993],
      values_percentages: [26.23, 4.3, 3.41],
      targets_percentages: [4.19, 8.99, 9.41]
    }
  ]
};

mock.onGet(API.pnl_dashboard.metrics).reply(async config => {
  console.log('config', config);
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200, dataResponse];
});

const brandsResponse = [
  {
    client_id: 4672,
    name: 'BABR'
  },
  {
    client_id: 4685,
    name: '#4685'
  },
  {
    client_id: 5807,
    name: '#5807'
  },
  {
    client_id: 5820,
    name: '#5820'
  }
];
mock.onGet(API.pnl_dashboard.brands).reply(() => {
  return [200, brandsResponse];
});
