import { format, subSeconds } from 'date-fns';
import colors from 'styles/colors';
import { formatValue } from './params';
import { getUTCDate } from '@utils';

export const tooltipFormat = 'dddd, MMM d, yyyy';

export const getTooltipParams = (interval, end, isBarView) => {
  return {
    intersect: false,
    shadowOffsetX: 0,
    shadowOffsetY: 3,
    shadowBlur: 10,
    shadowColor: '#d6daeb',
    backgroundColor: '#FFFFFF',
    bodyFontColor: colors.black.default,
    titleFontColor: colors.black.default,
    cornerRadius: isBarView ? 4 : 0,
    borderWidth: isBarView ? 1 : 0,
    borderColor: isBarView ? colors.gray.default : null,
    titleFontStyle: 'normal',
    caretPadding: 12,
    callbacks: {
      label: (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        const prefix = data.datasets[tooltipItem.datasetIndex].prefix || '';
        const suffix = data.datasets[tooltipItem.datasetIndex].suffix || '';

        if (label) {
          label += ' ';
        }
        label += prefix + formatValue(tooltipItem.yLabel) + suffix;
        if (!prefix && !suffix) {
          label = label.substring(0, label.length - 3);
        }

        return label;
      },
      title: (tooltipItem, data) => {
        const dataSet = data.datasets[tooltipItem[0].datasetIndex];
        const srcDataIndex = tooltipItem[0].index;
        const item = dataSet.srcValues[srcDataIndex];
        if (!item) {
          return null;
        }
        let dateFormat = tooltipFormat;

        const dateForDisplay = getUTCDate(item[0]);
        if (interval === 'Hourly') {
          dateFormat = 'haaaa, MMM d';
        } else if (interval === 'Daily') {
          dateFormat = 'MMM d';
        } else {
          dateFormat = 'MMM d';
          let formattedItem = format(dateForDisplay, dateFormat);
          if (dateForDisplay.getFullYear() !== getUTCDate().getFullYear()) {
            dateFormat += ', yyyy';
          }

          let endForDisplay = '';
          if (srcDataIndex + 1 < dataSet.srcValues.length) {
            const itemTo = dataSet.srcValues[srcDataIndex + 1];
            endForDisplay = subSeconds(getUTCDate(itemTo[0]), 1);
          } else {
            endForDisplay = getUTCDate(end);
          }
          formattedItem += ` - ${format(endForDisplay, dateFormat)}`;
          return formattedItem;
        }

        if (dateForDisplay.getFullYear() !== getUTCDate().getFullYear()) {
          dateFormat += ', yyyy';
        }
        const formattedItem = format(dateForDisplay, dateFormat);
        return formattedItem;
      }
    }
  };
};
