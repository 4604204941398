import React from 'react';
import PropTypes from 'prop-types';

import { DayPicker } from 'react-day-picker';
import { subMonths, endOfDay, startOfDay } from 'date-fns';
import { isMediumSize } from '@sm/WindowSizes';
import MonthPicker from '@components/date-picker/calendar/month-picker/MonthPicker';

import './Calendar.css';
import CalendarNavbar from './CalendarNavbar';
import CalendarHeader from './CalendarHeader';

const bigScreenMonthes = 2;
const mobileScreenMonthes = 1;

function Calendar({
  range,
  lastAvailableDate,
  updateSelection,
  lifeTime,
  oneDaySelectionAllowed,
  showSelectedRangeEnd,
  isMonthsView
}) {
  if (!range || !lastAvailableDate || !updateSelection || !lifeTime) {
    return null;
  }
  const { from, to } = range;
  const modifiers = { start: from, end: to };

  const numberOfMonthsToShow =
    isMediumSize() || oneDaySelectionAllowed ? mobileScreenMonthes : bigScreenMonthes;
  const twoCalendarsView = numberOfMonthsToShow === bigScreenMonthes;
  const getInitialMonth = () => {
    if (showSelectedRangeEnd)
      return numberOfMonthsToShow === bigScreenMonthes ? subMonths(to, 1) : to;
    return numberOfMonthsToShow === bigScreenMonthes ? subMonths(from, 1) : from;
  };

  if (isMonthsView) {
    return (
      <div className="inline-block">
        <MonthPicker
          isBigScreen={numberOfMonthsToShow === bigScreenMonthes}
          selectedDateRange={modifiers}
          onDayClick={updateSelection}
          firstAvailableDate={lifeTime}
          lastAvailableDate={lastAvailableDate}
        />
      </div>
    );
  }
  return (
    <div className="inline-block">
      <DayPicker
        className="Selectable"
        numberOfMonths={numberOfMonthsToShow}
        modifiers={modifiers}
        selectedDays={[from, { from, to }]}
        onDayClick={day => {
          if (day <= endOfDay(lastAvailableDate) && day >= lifeTime) {
            updateSelection(day);
          }
        }}
        navbarElement={
          <CalendarNavbar
            today={lastAvailableDate}
            lifeTime={lifeTime}
            twoCalendarsView={twoCalendarsView}
          />
        }
        captionElement={CalendarHeader}
        initialMonth={getInitialMonth()}
        disabledDays={day => day > endOfDay(lastAvailableDate) || day < startOfDay(lifeTime)}
      />
    </div>
  );
}

Calendar.propTypes = {
  range: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date)
  }).isRequired,
  lastAvailableDate: PropTypes.instanceOf(Date).isRequired,
  updateSelection: PropTypes.func.isRequired,
  lifeTime: PropTypes.instanceOf(Date).isRequired,
  oneDaySelectionAllowed: PropTypes.bool,
  showSelectedRangeEnd: PropTypes.bool,
  isMonthsView: PropTypes.bool
};

Calendar.defaultProps = {
  oneDaySelectionAllowed: false,
  showSelectedRangeEnd: false,
  isMonthsView: false
};

export default Calendar;
