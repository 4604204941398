export const PULL_BOOKMARKS = 'PULL_BOOKMARKS';
export const SET_BOOKMARKS = 'SET_BOOKMARKS';
export const SET_BOOKMARKS_SIDEBAR_SHOW = 'SET_BOOKMARKS_SIDEBAR_SHOW';
export const SET_BOOKMARKS_LOADING = 'SET_BOOKMARKS_LOADING';
export const SET_SELECTED_BOOKMARK = 'SET_SELECTED_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const CREATE_BOOKMARK = 'CREATE_BOOKMARK';
export const UPDATE_BOOKMARK = 'UPDATE_BOOKMARK';
export const APPLY_BOOKMARK = 'APPLY_BOOKMARK';
export const CLEANUP_BOOKMARK = 'CLEANUP_BOOKMARK';

export const applyBookmarkAction = payload => ({
  type: APPLY_BOOKMARK,
  payload
});

export const updateBookmarkAction = payload => ({
  type: UPDATE_BOOKMARK,
  payload
});

export const createBookmarkAction = payload => ({
  type: CREATE_BOOKMARK,
  payload
});

export const deleteBookmarkAction = payload => ({
  type: DELETE_BOOKMARK,
  payload
});

export const setSelectedBookmarkAction = payload => ({
  type: SET_SELECTED_BOOKMARK,
  payload
});

export const setBookmarksLoadingAction = payload => ({
  type: SET_BOOKMARKS_LOADING,
  payload
});

export const setBookmarksSidebarShowAction = payload => ({
  type: SET_BOOKMARKS_SIDEBAR_SHOW,
  payload
});

export const setBookmarksAction = payload => ({
  type: SET_BOOKMARKS,
  payload
});

export const pullBookmarksAction = () => ({ type: PULL_BOOKMARKS });

export const cleanupBookmarkAction = payload => ({
  type: CLEANUP_BOOKMARK,
  payload
});
