import { buildParams } from 'seller/utils/buildParams';
import { pullPrimaryChartDataAction, pullSecondaryChartDataAction } from '../saga/dashboard';

export const SET_PRIMARY_METRIC = 'SET_PRIMARY_METRIC';
export const SET_SECONDARY_METRIC = 'SET_SECONDARY_METRIC';
export const SET_CHART_INTERVAL = 'SET_CHART_INTERVAL';
export const SET_METRIX_BY_DT = 'SET_METRIX_BY_DT';
export const START_CHART_METRICS_LOADING = 'START_CHART_METRICS_LOADING';

export const setMetrixByDtAction = ({ metricsByDt, isPrimary }) => ({
  type: SET_METRIX_BY_DT,
  metricsByDt,
  isPrimary
});

export const pullIfSecondaryMetric = (dispatch, params, interval, secondaryMetric) => {
  if (secondaryMetric) {
    dispatch(
      pullSecondaryChartDataAction({ params, interval, metric: secondaryMetric, isPrimary: false })
    );
  }
};

export const setIntervalChanged = (interval = 'Day') => {
  return (dispatch, getState) => {
    const { primaryMetric, secondaryMetric } = getState().dashboard;
    dispatch({ type: SET_CHART_INTERVAL, interval });
    dispatch({ type: START_CHART_METRICS_LOADING });

    const params = buildParams({ state: getState(), dispatch });
    if (params.temp_bookmark_expired) return;
    dispatch(
      pullPrimaryChartDataAction({ params, interval, metric: primaryMetric, isPrimary: true })
    );
    pullIfSecondaryMetric(dispatch, params, interval, secondaryMetric);
  };
};

export const setMetricChanged = (metric, isPrimary) => {
  return (dispatch, getState) => {
    const { interval } = getState().dashboard;
    dispatch({ type: isPrimary ? SET_PRIMARY_METRIC : SET_SECONDARY_METRIC, metric });
    const params = buildParams({ state: getState(), dispatch });
    if (params.temp_bookmark_expired) return;
    if (isPrimary) {
      dispatch(pullPrimaryChartDataAction({ params, interval, metric, isPrimary }));
    } else dispatch(pullSecondaryChartDataAction({ params, interval, metric, isPrimary }));
  };
};
