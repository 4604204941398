import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import MenuComponent from '@components/common/Menu';
import { CaretDownIcon } from '@components/index';

const SimpleSelect = ({ values, label, value, setValue }) => {
  const [anchor, setAnchor] = useState(null);
  const handleClick = event => {
    setAnchor(event.currentTarget);
  };

  const handleClose = newValue => {
    setAnchor(null);
    if (typeof newValue === 'string') {
      setValue(newValue);
    }
  };

  return (
    <>
      <Button
        aria-haspopup="true"
        className="flex flex-row items-center text-sm text-gray-darkest font-medium capitalize p-2"
        onClick={handleClick}
      >
        <div className="mr-1">{label}:</div>
        <div>{value}</div>
        <CaretDownIcon fill="#c3c6d6" width="16px" height="14px" className="ml-2 hidden xs:block" />
      </Button>
      <MenuComponent
        id={label}
        data={values}
        handleClose={handleClose}
        anchor={anchor}
        className="capitalize"
      />
    </>
  );
};

SimpleSelect.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired
};

export default SimpleSelect;
