import API, { API_V2_1, API_V2_2, BETA_API, DASHBOARD_PRODUCT_LIST_PULLING_TIMEOUT } from '@sm/api';
import { put, cancelled, takeLatest, call, select } from 'redux-saga/effects';
import { dashboardSelector, userSelector } from 'seller/store/reducers';
import { prepareSales } from 'seller/utils/convertRawApiData';
import { axiosCall, getCancelToken } from '@utils';
import { setMetrixByDtAction, setNotificationMsgAction } from '../../actions';
import {
  setRefundsTotalsAction,
  setTotalsAction,
  setNotesAction,
  setSalesAction,
  PULL_PRIMARY_CHART_DATA,
  PULL_SECONDARY_CHART_DATA,
  PULL_SALES,
  PULL_TOTALS,
  PULL_NOTES
} from './action';
import { missedBookmarkHandler } from '../utils';

const replaceAdsProductsWithSpread = products => {
  products.forEach(product => {
    product.ads_products = product.ads_products_spread;
    if (product.children) replaceAdsProductsWithSpread(product.children);
  });
};

export function* pullSalesSaga(action) {
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    const { params } = action;
    const { profile } = yield select(userSelector);
    const { orderEventType } = yield select(dashboardSelector);
    if (orderEventType) params.order_event_type = orderEventType;
    const { data } = yield axiosCall.get(
      profile?.beta ? BETA_API.products.sales : API_V2_1.products.sales,
      { params, cancelToken, timeout: DASHBOARD_PRODUCT_LIST_PULLING_TIMEOUT }
    );
    if (profile.ads_products_spread && !sessionStorage.getItem('ads_products_spread_disabled')) {
      replaceAdsProductsWithSpread(data.products);
    }
    yield put(setSalesAction(prepareSales(data)));
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    console.log('pullSalesSaga error:', error);
    yield put(setSalesAction({}));
    yield put(setNotificationMsgAction('Pull products failed'));
  } finally {
    if (yield cancelled()) cancelRequest();
  }
}

export function* pullChartDataSaga({ payload }) {
  const { cancelToken, cancelRequest } = getCancelToken();
  const { params, interval, metric, isPrimary } = payload;
  try {
    const response = yield axiosCall.get(API.metrix.chart, {
      params: { ...params, metric, interval },
      cancelToken
    });
    const metricsByDt = response.data && response.data.metrix ? response.data.metrix : [];
    yield put(setMetrixByDtAction({ metricsByDt, isPrimary }));
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    console.log('pullChartDataSaga error:', error);
    yield put(setNotificationMsgAction('Pull chart data failed'));
    yield put(setMetrixByDtAction({ metricsByDt: [], isPrimary }));
  } finally {
    if (yield cancelled()) cancelRequest();
  }
}

export function* pullTotalsSaga({ payload }) {
  const { params, metrics, refunds } = payload;
  const { cancelToken, cancelRequest } = getCancelToken();
  const requestParams = params;
  if (metrics && metrics.length) {
    params.metrics = metrics.join();
  }
  const { profile } = yield select(userSelector);
  const { orderEventType } = yield select(dashboardSelector);
  if (orderEventType) requestParams.order_event_type = orderEventType;
  const useBetaApi = profile?.beta;
  try {
    const regularApiUrl = sessionStorage.getItem('metrix/totals/2.2')
      ? API_V2_2.metrix.totals
      : API_V2_1.metrix.totals;
    const url = useBetaApi ? BETA_API.metrix.totals : regularApiUrl;
    const { data } = yield axiosCall.get(url, { params: requestParams, cancelToken });
    if (!refunds) {
      yield put(setTotalsAction(data));
    } else {
      yield put(setRefundsTotalsAction(data));
    }
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    console.log('pullTotalsSaga error:', error);
    yield put(setNotificationMsgAction('Pull totals failed'));
  } finally {
    if (yield cancelled()) cancelRequest();
  }
}

export function* pullNotesSaga({ payload }) {
  const { params, callback } = payload;
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    const { data } = yield axiosCall.get(API.notes, { params, cancelToken });
    yield put(setNotesAction(data));
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    console.log('pullNotesSaga error:', error);
    yield put(setNotificationMsgAction('Pull notes failed'));
  } finally {
    if (yield cancelled()) cancelRequest();
    if (callback) callback();
  }
}

export function* dashboardSagaWatcher() {
  yield takeLatest(PULL_PRIMARY_CHART_DATA, pullChartDataSaga);
  yield takeLatest(PULL_SECONDARY_CHART_DATA, pullChartDataSaga);
  yield takeLatest(PULL_SALES, pullSalesSaga);
  yield takeLatest(PULL_TOTALS, pullTotalsSaga);
  yield takeLatest(PULL_NOTES, pullNotesSaga);
}
