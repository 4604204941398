import API from '@sm/api';
import mock from '../mock';
import { getTempId } from '../../@utils';

export const expensesList = {
  custom_expenses: [
    {
      id: 1,
      type: 'warehouse',
      name: 'Warehouse USA',
      start: '2021-08-01',
      end: '2021-10-10',
      allocation: 'dashboard',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12,
      product_count: 10,
      marketplace: 3
    },
    {
      id: 2,
      type: 'warehouse',
      name: 'Warehouse China',
      start: '2021-08-01',
      end: '2021-10-05',
      allocation: 'dashboard',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12,
      product_count: 10
    },
    {
      id: 3,
      type: 'employees',
      name: 'John Doe',
      start: '2021-09-01',
      end: '2021-09-30',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12
    },
    {
      id: 4,
      type: 'employees',
      name: 'Jane Doe',
      start: '2021-09-01',
      end: '2021-09-30',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12
    },
    {
      id: 5,
      type: 'accounting_software',
      name: 'Software Inventory Track',
      start: '2021-09-01',
      end: '2021-09-30',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12
    },
    {
      id: 6,
      type: 'order_samples',
      name: 'Order Samples',
      start: '2021-09-01',
      end: '2021-09-30',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12
    },
    {
      id: 7,
      type: 'tax_consultant',
      name: 'Tax Consultant',
      start: '2021-09-01',
      end: '2021-09-30',
      allocation: 'dashboard',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12
    },
    {
      id: 8,
      type: 'other',
      name: 'Other expense',
      start: '2021-09-01',
      end: '2021-09-30',
      allocation: 'dashboard',
      total_amount: 99,
      range_amount: 25,
      daily_amount: 12
    }
  ],
  amazon_expenses: [
    {
      id: 9,
      category: 'Adjustments',
      name: 'Compensated Clawback',
      start: '2021-09-10',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      marketplace: 3,
      product_count: 10
    },
    {
      id: 10,
      category: 'Adjustments',
      name: 'Missing From Inbound Clawback',
      start: '2021-09-10',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      marketplace: 3,
      product_count: 0
    },
    {
      id: 11,
      category: 'Adjustments',
      name: 'Non Subscription Fee Adj',
      start: '2021-09-11',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      marketplace: 3
    },
    {
      id: 12,
      category: 'FBA inventory and inbound services fees',
      name: 'Locked FBA Storage Fee',
      start: '2021-09-10',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      marketplace: 3,
      product_count: 10,
      locked: true
    },
    {
      id: 13,
      category: 'FBA inventory and inbound services fees',
      name: 'FBA Long Term Storage Fee',
      start: '2021-09-10',
      allocation: 'profit_and_loss',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      marketplace: 3,
      product_count: 0
    },
    {
      id: 14,
      category: 'FBA inventory and inbound services fees',
      name: 'FBA Inbound Transportation Fee',
      start: '2021-09-11',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      marketplace: 3
    },
    {
      id: 15,
      coupon_id: 1324,
      start: '2021-09-10',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10122,
      marketplace: 3
    },
    {
      id: 16,
      coupon_id: 1324,
      start: '2021-09-01',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10
    },
    {
      id: 115,
      coupon_id: 1324,
      start: '2021-09-10',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10122,
      marketplace: 3
    },
    {
      id: 116,
      coupon_id: 1324,
      start: '2021-09-01',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10
    },
    {
      id: 117,
      coupon_id: 1324,
      start: '2021-09-10',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10122,
      marketplace: 3
    },
    {
      id: 118,
      coupon_id: 1324,
      start: '2021-09-01',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10
    },
    {
      id: 119,
      coupon_id: 1324,
      start: '2021-09-10',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10122,
      marketplace: 3
    },
    {
      id: 120,
      coupon_id: 1324,
      start: '2021-09-01',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10
    },
    {
      id: 121,
      coupon_id: 1324,
      start: '2021-09-10',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10122,
      marketplace: 3
    },
    {
      id: 122,
      coupon_id: 1324,
      start: '2021-09-01',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10
    },
    {
      id: 17,
      coupon_id: 1222,
      start: '2021-09-01',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10
    },
    {
      id: 18,
      coupon_id: 1222,
      start: '2021-09-02',
      allocation: 'all',
      total_amount: 99,
      range_amount: 99,
      daily_amount: 99,
      events: 10
    }
  ]
};

mock.onGet(new RegExp(`${API.expenses.root}.+/`)).reply(async config => {
  console.log('config', config);
  const { url } = config;
  const [, expenseId] = url.match(/expenses\/(\d+)\//);
  const expenseDetails = [...expensesList.custom_expenses, ...expensesList.amazon_expenses].find(
    item => item.id === +expenseId
  );
  await new Promise(resolve => setTimeout(() => resolve(), 200));
  console.log('expenseDetails', expenseDetails);
  return [200, expenseDetails];
});

mock.onGet(API.expenses.root).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  return [200, expensesList];
});

mock.onDelete(new RegExp(`${API.expenses.root}.+/`)).reply(async config => {
  console.log('config', config);
  const { url } = config;
  const [, expenseId] = url.match(/expenses\/(\d+)\//);
  expensesList.custom_expenses = expensesList.custom_expenses.filter(
    item => item.id !== +expenseId
  );
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  return [200];
});

mock.onPost(API.expenses.root).reply(async config => {
  console.log('config', config);
  const data = JSON.parse(config.data);
  expensesList.push({ ...data, id: getTempId() });
  await new Promise(resolve => setTimeout(() => resolve(), 5000));
  return [200];
});

mock.onPut(new RegExp(`${API.expenses.root}.+/`)).reply(async config => {
  const data = JSON.parse(config.data);
  console.log('config', config);
  console.log('onPut data', data);
  await new Promise(resolve => setTimeout(() => resolve(), 5000));
  return [200];
});
