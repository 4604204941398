import API from '@sm/api';
import mock from '../mock';

const notesList = [
  {
    datetime: '2019-06-04 02:50:47',
    marketplace: 3,
    product: {
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 3,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC',
      sku: 'EBC02-CW'
    },
    description: 'Stopped the main campaign. Keeping theautomatic which spends peanuts every day'
  },
  {
    datetime: '2019-07-10 02:31:47',
    marketplace: 3,
    product: null,
    description:
      'Added back end search terms (250 bytes). Previously there were no search terms. Bacon ipsum dolor' +
      ' amet pancetta ball tip ground beef, boudin ribeye pork loin'
  },
  {
    datetime: '2019-01-10 23:12:47',
    marketplace: 9,
    product: null,
    description: 'Stopped the main campaign. Keeping theautomatic which spends peanuts every day'
  },
  {
    datetime: '2019-06-02 12:50:47',
    marketplace: 6,
    product: {
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 3,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC',
      sku: 'EBC02-CW'
    },
    description: 'Stopped the main campaign. Keeping theautomatic which spends peanuts every day'
  },
  {
    datetime: '2019-06-04 02:50:47',
    marketplace: 3,
    product: {
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    },
    description: 'Stopped the main campaign. Keeping theautomatic which spends peanuts every day'
  },
  {
    datetime: '2019-07-10 02:31:47',
    marketplace: 3,
    product: null,
    description:
      'Added back end search terms (250 bytes). Previously there were no search terms. Bacon ipsum dolor' +
      ' amet pancetta ball tip ground beef, boudin ribeye pork loin '
  },
  {
    datetime: '2019-01-10 23:12:47',
    marketplace: 3,
    product: null,
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
  },
  {
    datetime: '2019-06-02 12:50:47',
    marketplace: 4,
    product: { name: 'Activene', productsCount: 10, type: 'brand' },
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley'
  }
];

mock.onGet(API.notes).reply(() => {
  return [200, notesList];
});
