import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Dialog, Typography, withStyles } from '@material-ui/core';
import { GradButton } from '@components/buttons';
import Colors from 'styles/colors';
import { ConfirmDialog, VideoButton, LoadingSpinner } from '@components';
import { setSelectedProducts } from 'seller/store/actions';
import {
  createBookmarkAction,
  deleteBookmarkAction,
  updateBookmarkAction
} from 'seller/store/saga/bookmarks';
import SmallTextCard from '@components/inputs/SmallTextCard';
import { YellowMuiTextField, OutlinedWrapper } from '@components/inputs';
import { productSearchSelector } from 'seller/store/reducers';
import { HEADER_HEIGHT } from '@sm/layout';
import { getBookmarkItems } from './utils';

const DeleteButton = withStyles({
  root: {
    borderColor: Colors.red.default
  }
})(Button);
const BOOKMARK_NAME_CHARACTER_LIMIT = 75;
const NEW_SKU = 'NEW_SKU';
const buttonsClassName = 'mt-2 normal-case text-xs leading-none py-0 h-8';

const BookmarkDialog = ({ selectedBookmark, selectionDiff, open, onClose }) => {
  const dispatch = useDispatch();
  const { selectedProducts } = useSelector(productSearchSelector);
  const [name, setName] = useState('');
  const [bookmarkItems, setBookmarksItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const headerText = selectedBookmark
    ? 'Update bookmark'
    : `Bookmark selection (${selectedProducts?.length})`;

  useEffect(() => {
    if (open && !loading) {
      const newBookmarkItem = getBookmarkItems(selectionDiff.newProducts).map(productName => ({
        value: productName,
        type: NEW_SKU
      }));
      const stillExistBookmarkItems = getBookmarkItems(selectionDiff.stillExistProducts).map(
        productName => ({ value: productName })
      );
      setBookmarksItems([...newBookmarkItem, ...stillExistBookmarkItems]);
    }
  }, [
    loading,
    open,
    selectionDiff.deletedProducts,
    selectionDiff.newProducts,
    selectionDiff.stillExistProducts
  ]);

  useEffect(() => {
    if (selectedBookmark && open && !loading) setName(selectedBookmark.name);
  }, [loading, open, selectedBookmark]);

  useEffect(() => {
    if (open) setLoading(false);
  }, [open]);

  const onDelete = () => {
    if (!loading) {
      setLoading(true);
      dispatch(deleteBookmarkAction({ id: selectedBookmark.id, onFinally: () => onClose() }));
    }
  };

  const onSave = () => {
    if (!loading) {
      setLoading(true);
      if (!selectedBookmark) {
        dispatch(createBookmarkAction({ name, onFinally: () => onClose() }));
      } else {
        dispatch(
          updateBookmarkAction({ id: selectedBookmark.id, name, onFinally: () => onClose() })
        );
      }
    }
  };

  const onProductDelete = productName =>
    dispatch(
      setSelectedProducts(
        selectedProducts.filter(
          product =>
            !(
              product.sku === productName ||
              product.asin === productName ||
              product.name === productName
            )
        )
      )
    );

  const existSkuColors = { text: Colors.violet.dark, background: Colors.violet.lighter };
  const newSkuColors = { text: Colors.white.default, background: Colors.violet.dark };
  const saveButtonActive =
    (selectedBookmark && selectionDiff.changed && name) ||
    (!selectedBookmark && name) ||
    (selectedBookmark?.name !== name && name);
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      PaperProps={{
        className: 'pt-3 px-4 pb-4 max-w-530px overflow-auto max-h-3/4',
        style: { marginTop: HEADER_HEIGHT }
      }}
    >
      <div className="flex items-center justify-between">
        <div>
          <Typography className="font-medium text-gray-darkest">{headerText}</Typography>
          <div className="underline-sm bg-grad-2" />
        </div>
        <VideoButton
          firstLine="Bookmarks"
          src="https://www.youtube.com/embed/OaZG-thbVLw"
          className="ml-4 flex-shrink-0"
        />
      </div>
      <div className="mt-4 pb-2 relative">
        <LoadingSpinner loading={loading} />
        <YellowMuiTextField
          label="Bookmark name"
          className=""
          fullWidth
          name="name"
          onChange={event => setName(event.target.value.slice(0, BOOKMARK_NAME_CHARACTER_LIMIT))}
          value={name}
        />
        <OutlinedWrapper title="Selection(s)" className="mt-6 w-full">
          <div
            className="flex flex-wrap flex-grow justify-start my-2 content-start items-start relative overflow-auto"
            style={{ minHeight: '6.5rem', maxHeight: '15rem' }}
          >
            {bookmarkItems.map(sku => {
              return (
                <SmallTextCard
                  key={sku.value}
                  title={sku.value}
                  onClose={() => onProductDelete(sku.value)}
                  className="mr-2 my-1"
                  colors={sku.type === NEW_SKU && selectedBookmark ? newSkuColors : existSkuColors}
                />
              );
            })}
          </div>
        </OutlinedWrapper>
      </div>
      <div className="flex justify-end mt-12">
        {selectedBookmark && (
          <DeleteButton
            variant="outlined"
            className={classNames('text-red mr-auto', buttonsClassName)}
            onClick={() => setDeleteConfirm(true)}
          >
            Delete
          </DeleteButton>
        )}
        <Button
          variant="outlined"
          className={classNames('text-gray-dark mr-4', buttonsClassName)}
          onClick={onClose}
        >
          Cancel
        </Button>
        <GradButton
          grad="bg-grad-8"
          className={buttonsClassName}
          active={!!saveButtonActive}
          onClick={onSave}
        >
          {selectedBookmark ? 'Update' : 'Confirm'}
        </GradButton>
      </div>
      <Dialog open={deleteConfirm}>
        <ConfirmDialog
          onConfirm={() => {
            setDeleteConfirm(false);
            onDelete();
          }}
          onCancel={() => setDeleteConfirm(false)}
          message="Are you sure you want to delete the bookmark?"
          confirmText="Delete"
        />
      </Dialog>
    </Dialog>
  );
};

BookmarkDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedBookmark: PropTypes.instanceOf(Object),
  selectionDiff: PropTypes.instanceOf(Object).isRequired
};

BookmarkDialog.defaultProps = {
  selectedBookmark: null
};

export default BookmarkDialog;
