import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Paper } from '@material-ui/core';

import { AutoSizer, MultiGrid } from 'react-virtualized';
import classNames from 'classnames';
import Colors from 'styles/colors';

import { HEADER_HEIGHT, SELECTOR_VIEW_HEIGHT } from '@sm/layout';
import { getFormattedValue } from '@utils/format';
import { DownloadIcon } from '@components/icons';
import ProductCard from '../products/product-card/ProductCard';
import DataTableInput from './DataTableInput';
import LoadingSpinner from '../common/LoadingSpinner';
import { getCustomProperties } from '../products/products-search-results/ProductsList';
import SortIcon from './ic_sortdown.svg';
import FlatButton from '../buttons/FlatButton';
import {
  getFilteredColumnsConfig,
  getColumnsConfigWithMaxValue,
  getSettings,
  getSortedColumnsConfig
} from './utils';
import {
  defaultDataCellComponent,
  defaultHeaderColumnClassName,
  defaultRowRootComponent,
  cellDefaultClassName,
  getHeaderButtonContent
} from './views';
import AdjustPopover from './AdjustPopover';

const STICKY_ROW = 1;
const minQueryLength = 3;

const defaultRootSettings = {
  heightReduce: '32px',
  headerClassName: 'flex flex-row flex-1 pb-1 justify-between px-6 relative h-12 items-end',
  headerHeight: '3rem',
  filterClassName: 'max-w-4/10',
  keyword: '',
  onKeywordChange: () => {}
};

const DataTable = props => {
  const {
    isLoading,
    title,
    columnsConfig: _columnsConfig,
    fixedColumnRowComponent,
    rootDataCellComponent,
    getHeaderColumnClass,
    useProductCard,
    currency,
    filter,
    searchPlaceholder,
    hideSearch,
    csvProps,
    defaultSortedColumnIndex,
    defaultSortedColumnOrder,
    setSortConfig,
    headerWidget,
    headerEndWidget,
    productCardSettings,
    className,
    rootSettings: _rootSettings,
    extraCellProps,
    getRowHeight,
    selectionResetDeps,
    defaultSelectedParents,
    updateSelectedParents,
    disableResponsiveColumns,
    ui,
    userPreferences,
    setFilteredTableData,
    setTableConfig,
    fixedLastRow,
    defaultCustomCellView,
    showOnlyUniqueChildrenByRecordId
  } = props;
  const { data } = props;
  let gridRef;
  const rootSettings = { ...defaultRootSettings, ..._rootSettings };
  const [selectedParents, setSelected] = useState(defaultSelectedParents);
  const [sort, _setSort] = useState({
    index: defaultSortedColumnIndex,
    order: defaultSortedColumnOrder
  });
  const setSort = value => {
    _setSort(value);
    setSortConfig(value);
  };

  const [currentScrollLeft, setScrollLeft] = useState(0);
  const [keyword, setKeywordState] = useState(rootSettings.keyword);
  const setKeyword = val => {
    setKeywordState(val);
    rootSettings.onKeywordChange(val);
  };
  const [tableWidth, setTableWidth] = useState(0);
  const { viewportHeight } = ui;
  const { tablesConfig } = userPreferences;
  const columnsConfig = getSortedColumnsConfig(tablesConfig[title], _columnsConfig);
  const settings = getSettings({
    columnsConfig: getColumnsConfigWithMaxValue(getFilteredColumnsConfig(columnsConfig), data),
    tableWidth,
    disableResponsiveColumns
  });
  const { columns } = settings;
  const columnsCount = Object.keys(columns).length;

  let displayedData =
    filter && keyword.trim().length >= minQueryLength ? filter(data, keyword.trim()) : data;

  displayedData = displayedData ? displayedData.slice() : [];

  useEffect(() => {
    setFilteredTableData(displayedData);
  }, [keyword, displayedData, setFilteredTableData]);

  // Sorting Column.
  let safeSortIndex = sort.index;
  if (columns.length - 1 < sort.index) {
    safeSortIndex = 1;
    setSort({ index: safeSortIndex, order: sort.order });
  }
  const columnInfo = columns[safeSortIndex];
  const sortField =
    columnInfo.sort && columnInfo.sort.field ? columnInfo.sort.field : columnInfo.dataKey;
  let result = displayedData
    .slice(0, fixedLastRow ? -1 : undefined)
    .sort(columnInfo.sort.func(sortField));
  if (sort.order) result = result.reverse();

  if (!rootSettings.disableSorting) {
    displayedData = fixedLastRow ? result.concat(displayedData.slice(-1)) : result;
  }

  const processedSelectedIds = new Set();

  const getSelectedChildren = parentData => {
    const { children } = parentData;
    if (!children) return [];
    let sortedChildren = children.sort(columnInfo.sort.func(sortField));
    if (sort.order) sortedChildren = sortedChildren.reverse();
    const childrenResult = [];
    sortedChildren.forEach(child => {
      if (!processedSelectedIds.has(child.recordId)) {
        childrenResult.push(child);
        if (showOnlyUniqueChildrenByRecordId) processedSelectedIds.add(child.recordId);
      }
      if (showOnlyUniqueChildrenByRecordId && !selectedParents.includes(child.recordId)) return;
      childrenResult.push(...getSelectedChildren(child));
    });
    return childrenResult;
  };
  selectedParents.forEach(selectedParent => {
    const index = displayedData.findIndex(item => item.recordId === selectedParent);
    if (index === -1) {
      console.error('not found selected product');
      return;
    }
    displayedData.splice(index + 1, 0, ...getSelectedChildren(displayedData[index]));
  });

  const rowClicked = (index, product) => {
    const productId = product.recordId;
    const selectedIndex = selectedParents.indexOf(productId);
    let newSelectedParents;
    if (selectedIndex === -1) {
      newSelectedParents = [...selectedParents, productId];
    } else {
      newSelectedParents = [...selectedParents];
      newSelectedParents.splice(selectedIndex, 1);
    }
    setSelected(newSelectedParents);
    updateSelectedParents(newSelectedParents);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSelected(defaultSelectedParents), selectionResetDeps);

  const onSortClicked = columnIndex => {
    if (rootSettings.disableSorting) return;
    const newSort = { index: columnIndex, order: sort.index === columnIndex && !sort.order };
    setSort(newSort);
  };

  const headerRenderer = (columnIndex, key, style) => {
    const isSorted = columnIndex === sort.index && !rootSettings.disableSorting;
    const rootClass = getHeaderColumnClass(columnIndex, columnsCount, isSorted, columns);
    const colData = columns[columnIndex];

    let columnName;
    if (currency && colData.currency) {
      columnName = `${colData.label} (${currency})`;
    } else if (colData.percentage) {
      columnName = `${colData.label} (%)`;
    } else {
      columnName = colData.label;
    }

    let sortIcon = null;
    if (isSorted) {
      const imgStyle = { fill: '#f9ad19' };
      if (sort.order) {
        imgStyle.transform = 'rotate(180deg)';
      }
      sortIcon = <img src={SortIcon} className="w-4 h-4 ml-2" style={imgStyle} alt="sort" />;
    }

    const { tooltip, infoIconTooltip } = colData;
    return (
      <FlatButton
        key={key}
        className={rootClass}
        style={style}
        onClick={() => onSortClicked(columnIndex)}
      >
        {getHeaderButtonContent(columnName, tooltip, infoIconTooltip)}
        {sortIcon}
      </FlatButton>
    );
  };

  const fixedColumnRenderer = (key, rowIndex, style) => {
    const value = displayedData[rowIndex];
    const child = value.type === 'child';
    const newStyle = {
      ...style,
      ...{ backgroundColor: child ? Colors.white.gray : Colors.white.default }
    };

    const productCardClassName = classNames(
      'items-center w-full h-full justify-between sm:pr-2 sm:mr-4',
      settings.productCardStyle
    );

    const customProperties = getCustomProperties(value);

    const isSelected = selectedParents.indexOf(value.recordId) !== -1;

    const content = (
      <ProductCard
        {...value}
        key={value.id}
        className={productCardClassName}
        imageClassName="w-11-5"
        firstRowClassName="text-sm"
        marketplace={value.marketplace}
        productCount={value.children ? value.children.length : 0}
        customProperties={customProperties}
        selected={value.is_parent && selectedParents.indexOf(value.recordId) !== -1}
        showArrow
        enableTooltips
        {...productCardSettings}
      />
    );

    const rowClassName = 'border-b border-gray-lighter-05 z-100';
    const rowStyle = { ...newStyle, width: settings.productCardWidth };
    return fixedColumnRowComponent(
      value,
      key,
      rowStyle,
      rowClassName,
      content,
      rowIndex,
      rowClicked,
      columns,
      settings,
      isSelected,
      extraCellProps
    );
  };

  const dataCellRenderer = (columnIndex, key, rowIndex, style) => {
    const colData = columns[columnIndex];
    const dataRow = displayedData[rowIndex];

    const newStyle = {
      ...style,
      ...{
        backgroundColor: dataRow.type === 'child' ? Colors.white.gray : Colors.white.default,
        paddingRight: '1.375rem'
      }
    };

    const cellClassName = colData.cellClassName || cellDefaultClassName;

    const customView = colData.customView || defaultCustomCellView;
    if (customView) {
      const view = customView(
        displayedData,
        columnIndex,
        key,
        rowIndex,
        newStyle,
        rowClicked,
        selectedParents,
        extraCellProps,
        setKeyword,
        columns,
        currency
      );
      return rootDataCellComponent(
        key,
        rowIndex,
        newStyle,
        cellClassName,
        view,
        displayedData,
        columnIndex,
        colData
      );
    }

    const dataCell = dataRow[colData.dataKey];
    let formattedValue;

    if (!dataCell) {
      formattedValue = <div className="text-gray-dark text-sm">none</div>;
    } else if (colData.formatted) {
      formattedValue = getFormattedValue(dataCell, !colData.fixedDecimalScale);
    } else {
      formattedValue = <div className="text-sm">{dataCell}</div>;
    }

    return rootDataCellComponent(
      key,
      rowIndex,
      newStyle,
      cellClassName,
      formattedValue,
      displayedData,
      columnIndex,
      colData
    );
  };

  const cellRendererRoot = ({ columnIndex, key, rowIndex, style }) => {
    if (rowIndex === 0) {
      return headerRenderer(columnIndex, key, style);
    }

    if (useProductCard && columnIndex === 0) {
      return fixedColumnRenderer(key, rowIndex - STICKY_ROW, style);
    }
    return dataCellRenderer(columnIndex, key, rowIndex - STICKY_ROW, style);
  };

  const getColumnWidthHelper = ({ index }) => {
    return columns[index].width;
  };

  const styledBottomLeftGrid = {
    boxShadow: '8px 4px 8px -8px rgba(0,0,0,0.4)',
    '&::WebkitScrollbar': {
      '-webkit-appearance': 'none'
    },
    zIndex: 10
  };
  const styledTopLeftGridWithShadow = {
    borderColor: Colors.gray.lighter,
    borderBottomWidth: '1px',
    boxShadow: '8px 20px 8px -8px rgba(0,0,0,0.4)',
    zIndex: 10
  };

  const styledTopGrid = {
    borderColor: Colors.gray.lighter,
    borderBottomWidth: '1px',
    zIndex: 10
  };

  useEffect(() => {
    gridRef.recomputeGridSize();
  }, [displayedData, gridRef]);

  return (
    <Paper
      style={{
        height: `${viewportHeight} - ${HEADER_HEIGHT} - ${SELECTOR_VIEW_HEIGHT} - ${rootSettings.heightReduce})`
      }}
      className={classNames(className, 'overflow-hidden')}
    >
      <LoadingSpinner
        className="absolute inset-0 z-10 pointer-events-none flex items-center justify-center h-12 w-full"
        override
        loading={isLoading}
      />
      <div className={rootSettings.headerClassName}>
        <div className="flex flex-row font-medium text-lg items-center text-gray-darkest pr-2 flex-shrink-0">
          {title && <div className="mr-2">{title}</div>}
          {csvProps && (
            <IconButton
              onClick={() => {
                csvProps.onClick(
                  csvProps.data,
                  csvProps.fileName,
                  csvProps.columns,
                  csvProps.dateRange,
                  csvProps.marketplaceID,
                  () => {}
                );
              }}
              className="p-2 mr-1"
            >
              <DownloadIcon fill={Colors.yellow.default} className="h-4 w-4" />
            </IconButton>
          )}
          {!rootSettings.disableAdjustColumns && (
            <AdjustPopover
              columnsConfig={columnsConfig}
              tableId={title}
              setTableConfig={setTableConfig}
            />
          )}
        </div>
        {headerWidget}
        {!hideSearch && (
          <div
            className={classNames(
              'ml-auto flex flex-shrink-1 justify-end h-6',
              rootSettings.filterClassName
            )}
          >
            <DataTableInput
              onChange={newKeyword => setKeyword(newKeyword)}
              onFocus={() => {}}
              keyword={keyword}
              placeholder={searchPlaceholder || 'Search products'}
              ui={ui}
            />
          </div>
        )}
        {headerEndWidget}
      </div>
      <div
        className="flex flex-auto"
        style={{
          height: `calc(${viewportHeight} - ${HEADER_HEIGHT} - ${SELECTOR_VIEW_HEIGHT} - ${rootSettings.headerHeight} - ${rootSettings.heightReduce})`
        }}
      >
        <AutoSizer>
          {({ height, width }) => {
            if (width !== tableWidth) setTableWidth(width);
            return (
              <MultiGrid
                ref={ref => {
                  gridRef = ref;
                }}
                cellRenderer={cellRendererRoot}
                columnCount={columns.length}
                fixedColumnCount={1}
                fixedRowCount={STICKY_ROW}
                height={height}
                rowHeight={({ index }) => {
                  if (getRowHeight) {
                    return getRowHeight(displayedData, index, settings);
                  }
                  return index === 0 ? settings.headerRowHeight : settings.rowHeight;
                }}
                rowCount={displayedData.length + STICKY_ROW}
                enableFixedColumnScroll
                hideBottomLeftGridScrollbar
                hideTopRightGridScrollbar
                width={width}
                columnWidth={getColumnWidthHelper}
                styleBottomLeftGrid={currentScrollLeft ? styledBottomLeftGrid : {}}
                styleTopLeftGrid={currentScrollLeft ? styledTopLeftGridWithShadow : styledTopGrid}
                styleTopRightGrid={styledTopGrid}
                onScroll={({ scrollLeft }) => {
                  if (!scrollLeft !== !currentScrollLeft) {
                    setScrollLeft(scrollLeft);
                  }
                }}
                overscanRowCount={8}
                overscanColumnCount={0}
                classNameBottomLeftGrid="invisible-scroll-container" // Workaround to hide scrollbar https://github.com/bvaughn/react-virtualized/issues/1184
              />
            );
          }}
        </AutoSizer>
      </div>
    </Paper>
  );
};

DataTable.propTypes = {
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  rootDataCellComponent: PropTypes.func,
  fixedColumnRowComponent: PropTypes.func,
  useProductCard: PropTypes.bool,
  currency: PropTypes.string,
  filter: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  hideSearch: PropTypes.bool,
  fixedLastRow: PropTypes.bool,
  csvProps: PropTypes.instanceOf(Object),
  defaultSortedColumnIndex: PropTypes.number,
  defaultSortedColumnOrder: PropTypes.bool,
  setSortConfig: PropTypes.func,
  headerWidget: PropTypes.node,
  headerEndWidget: PropTypes.node,
  productCardSettings: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  rootSettings: PropTypes.instanceOf(Object),
  extraCellProps: PropTypes.instanceOf(Object),
  getRowHeight: PropTypes.func,
  disableResponsiveColumns: PropTypes.bool,
  getHeaderColumnClass: PropTypes.func,
  selectionResetDeps: PropTypes.instanceOf(Array),
  defaultSelectedParents: PropTypes.instanceOf(Array),
  updateSelectedParents: PropTypes.func,
  defaultCustomCellView: PropTypes.func,
  showOnlyUniqueChildrenByRecordId: PropTypes.bool
};

DataTable.defaultProps = {
  data: [],
  rootDataCellComponent: defaultDataCellComponent,
  fixedColumnRowComponent: defaultRowRootComponent,
  getHeaderColumnClass: defaultHeaderColumnClassName,
  currency: null,
  useProductCard: true,
  fixedLastRow: false,
  filter: null,
  searchPlaceholder: '',
  hideSearch: false,
  csvProps: null,
  defaultSortedColumnIndex: 1,
  defaultSortedColumnOrder: false,
  setSortConfig: () => {},
  headerWidget: null,
  headerEndWidget: null,
  rootSettings: {
    heightReduce: '32px',
    headerClassName: 'flex flex-row flex-1 pb-1 justify-between px-6 relative h-12 items-end',
    headerHeight: '3rem',
    filterClassName: 'max-w-4/10',
    disableAdjustColumns: false,
    disableSorting: false
  },
  productCardSettings: {
    childrenTitle: 'variations'
  },
  className: 'w-full relative',
  extraCellProps: {},
  getRowHeight: null,
  disableResponsiveColumns: false,
  selectionResetDeps: [],
  defaultSelectedParents: [],
  updateSelectedParents: () => {},
  defaultCustomCellView: null,
  showOnlyUniqueChildrenByRecordId: false
};

export default DataTable;
